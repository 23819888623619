import { AxiosMethod } from "../utils/interceptors";
const url = "faqs";

export function HTTPGetFAQs(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetFAQById(param: {
  id_faq: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}/detail/${param.id_faq}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
