import { AxiosMethod } from "../utils/interceptors";
const url = "vouchers";

export function HTTPGetVouchers(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetVoucherById(param: { id_voucher: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}/detail/${param.id_voucher}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
