import { Box, Button, Divider, Icon, Stack, Typography } from "@mui/material";
import { TreeView } from "@mui/x-tree-view/TreeView";
import * as React from "react";
import { isMobile } from "react-device-detect";
import { Images } from "../assets/images";
import { CENTER, Colors } from "../utils/colors";
import { styled, useTheme } from "@mui/material/styles";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { useNavigate } from "react-router";
import {
  HTTPGetFooterAddress,
  HTTPGetFooterReseller,
  HTTPGetFooterSchedule,
} from "../apis/footer";
import { Icons } from "../assets/icons";
import moment from "moment";

const FooterItems = [
  {
    nodeId: "1",
    title: "Tentang Kami",
    children: [
      { label: "Tentang Kami", link: "/privacy/about" },
      { label: "Kebijakan Privasi", link: "/privacy/policy" },
      { label: "Blog & Artikel", link: "/article/list" },
      { label: "FAQ", link: "/faq" },
      { label: "Sertifikat", link: "/certificate" },
    ],
  },
  {
    nodeId: "2",
    title: "Sosial Media",
    children: [
      {
        label: "Instagram",
        link: "https://instagram.com/angeloskin.official",
        shouldOpenNewTab: true,
      },
      {
        label: "Shopee",
        link: "https://shopee.co.id/shopbyangel",
        shouldOpenNewTab: true,
      },
      {
        label: "TikTok",
        link: "https://www.tiktok.com/@angeloskinofficial?_t=8nuJIqlXtz8&_r=1",
        shouldOpenNewTab: true,
      },
      { label: "Tokopedia", link: "#" },
    ],
  },
  {
    nodeId: "3",
    title: "Akun Saya",
    children: [
      { label: "Masuk", link: "/auth/login" },
      { label: "Daftar", link: "/auth/register" },
      { label: "Lihat Keranjang", link: "/cart" },
      { label: "Daftar Favorit", link: "/favorite" },
      { label: "Bantuan", link: "/" },
    ],
  },
];

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: "#000",
  [`& .${treeItemClasses.content}`]: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    [`& .${treeItemClasses.label}`]: {
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
  },
})) as unknown as typeof TreeItem;

const StyledTreeItem = React.forwardRef(function StyledTreeItem(
  props: any,
  ref: React.Ref<HTMLLIElement>
) {
  const theme = useTheme();
  const {
    bgColor,
    color,
    labelInfo,
    labelText,
    colorForDarkMode,
    bgColorForDarkMode,
    ...other
  } = props;

  const styleProps: any = {
    "--tree-view-color":
      theme.palette.mode !== "dark" ? color : colorForDarkMode,
    "--tree-view-bg-color":
      theme.palette.mode !== "dark" ? bgColor : bgColorForDarkMode,
  };
  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingY: 1.5,
            paddingX: 0.5,
            pr: 0,
          }}
        >
          <Typography variant={"body1"} sx={{ fontWeight: "600", flexGrow: 1 }}>
            {labelText}
          </Typography>
        </Box>
      }
      style={styleProps}
      {...other}
      ref={ref}
    />
  );
});

const Footer = () => {
  const navigate = useNavigate();
  const [init, setInit] = React.useState(false);
  const [LocalIdentity, setLocalIdentity] = React.useState<any>(null);

  const GetAddress = async () => {
    const result = await HTTPGetFooterAddress();
    const data = result.data.data;
    const address = `${data.address_detail}. ${data.address}, ${data.subdistrict.name}, ${data.city.name}, ${data.province.name} ${data.postal_code}`;
    const identity = JSON.parse(localStorage.getItem("identity") as string);
    const newValue = { ...identity, address: address, latestUpdate: moment() };
    localStorage.setItem("identity", JSON.stringify(newValue));
    setLocalIdentity(identity);
  };

  const GetSchedule = async () => {
    const result = await HTTPGetFooterSchedule();
    const data = result.data.data;
    const time = `${data.start_day.id} - ${data.end_day.id}, ${data.start_time} - ${data.end_time} ${data.timezone}`;
    const identity = JSON.parse(localStorage.getItem("identity") as string);
    const newValue = { ...identity, schedule: time, latestUpdate: moment() };
    localStorage.setItem("identity", JSON.stringify(newValue));
    setLocalIdentity(identity);
  };

  const GetReseller = async () => {
    const result = await HTTPGetFooterReseller();
    const data = result.data.data;
    const identity = JSON.parse(localStorage.getItem("identity") as string);
    const newValue = {
      ...identity,
      reseller: JSON.stringify(data),
      latestUpdate: moment(),
    };
    localStorage.setItem("identity", JSON.stringify(newValue));
    setLocalIdentity(identity);
  };

  React.useEffect(() => {
    async function Initial() {
      const CompanyIdentity = localStorage.getItem("identity") as string;
      if (CompanyIdentity === null || CompanyIdentity === undefined) {
        const initial = {
          address: "",
          schedule: "",
          reseller: "",
          latestUpdate: "",
        };
        localStorage.setItem("identity", JSON.stringify(initial));
        await GetAddress();
        await GetSchedule();
        await GetReseller();
      } else {
        setLocalIdentity(JSON.parse(CompanyIdentity));
        if (
          moment(JSON.parse(CompanyIdentity).latest_update).isSame(
            moment().subtract(1, "hour")
          )
        ) {
          await GetAddress();
          await GetSchedule();
          await GetReseller();
        }
      }
    }

    Initial().then().catch();
  }, [init]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#fff",
        padding: "3% 0",
        ...CENTER,
        boxShadow: "0px 0px 20px 8px rgba(0,0,0,0.1)",
      }}
    >
      {isMobile ? (
        <div style={{ width: "100%" }}>
          <TreeView
            defaultCollapseIcon={
              <Icon sx={{ color: "#909090" }} fontSize={"small"}>
                arrow_drop_down
              </Icon>
            }
            defaultExpandIcon={
              <Icon sx={{ color: "#909090" }} fontSize={"small"}>
                arrow_right
              </Icon>
            }
            sx={{ flexGrow: 1, width: "100%", overflowY: "auto" }}
          >
            <Stack direction={"column"} divider={<Divider />} width={"100%"}>
              {FooterItems.map((item) => (
                <StyledTreeItem
                  nodeId={item.nodeId}
                  labelText={item.title}
                  key={item.nodeId}
                >
                  <Stack
                    direction={"column"}
                    paddingY={2}
                    sx={{ color: "#999" }}
                  >
                    {item.children.map((value, index) => (
                      <a
                        href={value.link}
                        key={index}
                        color={"inherit"}
                        target={value.shouldOpenNewTab ? "_blank" : undefined}
                        rel={
                          value.shouldOpenNewTab
                            ? "noopener noreferrer"
                            : undefined
                        }
                      >
                        <Typography
                          variant={"subtitle2"}
                          textAlign={"left"}
                          width={"100%"}
                        >
                          {value.label}
                        </Typography>
                      </a>
                    ))}
                  </Stack>
                </StyledTreeItem>
              ))}
            </Stack>
          </TreeView>
          <Stack
            direction={"column"}
            gap={1}
            sx={{ width: "100%" }}
            alignItems={"center"}
            marginTop={3}
          >
            <img
              onClick={() => navigate("/")}
              src={Images.logo}
              style={{ width: "50%", height: "auto", objectFit: "contain" }}
              alt=""
            />
            <small style={{ color: "#000" }}>
              {"©️2023, Angeloskin.\nAll Right Reserved."}
            </small>
          </Stack>
        </div>
      ) : (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ width: "90%" }}
          gap={3}
        >
          <Stack direction={"column"} gap={1} sx={{ width: "20%" }}>
            <img
              src={Images.logo}
              style={{ width: "80%", height: "auto", objectFit: "contain" }}
              alt=""
            />
            <small style={{ color: "#000" }}>
              {"©️2023, Angeloskin.\nAll Right Reserved."}
            </small>
          </Stack>
          {FooterItems.map((item) => (
            <Stack
              direction={"column"}
              gap={1}
              sx={{ width: "20%" }}
              key={item.nodeId}
            >
              <h3 style={{ color: "#000", margin: 0, marginBottom: 15 }}>
                {item.title}
              </h3>
              {item.children.map((value, index) => (
                <a
                  href={value.link}
                  key={index}
                  color={"inherit"}
                  target={value.shouldOpenNewTab ? "_blank" : undefined}
                  rel={
                    value.shouldOpenNewTab ? "noopener noreferrer" : undefined
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant={"subtitle2"}
                    key={index}
                    sx={{
                      fontWeight: 500,
                      color: "#000",
                      cursor: "pointer",
                      "&:hover": {
                        fontWeight: 600,
                        color: Colors.primary,
                      },
                    }}
                  >
                    {value.label}
                  </Typography>
                </a>
              ))}
            </Stack>
          ))}
          {LocalIdentity === null || LocalIdentity === undefined ? null : (
            <Stack direction={"column"} gap={1} sx={{ width: "20%" }}>
              <h3 style={{ color: "#000", margin: 0, marginBottom: 15 }}>
                Alamat
              </h3>
              {LocalIdentity.address.length === 0 ? null : (
                <Stack direction={"row"} gap={1}>
                  <Icon fontSize={"small"} sx={{ color: "#000" }}>
                    pin_drop
                  </Icon>
                  <Typography variant={"subtitle2"} style={{ color: "#000" }}>
                    {LocalIdentity.address}
                  </Typography>
                </Stack>
              )}
              {LocalIdentity.schedule.length === 0 ? null : (
                <Stack direction={"row"} gap={1}>
                  <Icon fontSize={"small"} sx={{ color: "#000" }}>
                    access_time
                  </Icon>
                  <Typography
                    variant={"subtitle2"}
                    style={{ color: "#000" }}
                  >{`Jam Operasional: ${LocalIdentity.schedule}`}</Typography>
                </Stack>
              )}
              {LocalIdentity.reseller.length === 0 ? null : (
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  onClick={() =>
                    window.open(
                      JSON.parse(LocalIdentity.reseller).whatsapp_link,
                      "_blank"
                    )
                  }
                  sx={{ backgroundColor: Colors.primary }}
                  startIcon={
                    <img
                      src={Icons.whatsappLight}
                      style={{
                        width: "3vh",
                        height: "3vh",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  }
                >
                  <Typography
                    variant="caption"
                    color="#fff"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "clip",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {JSON.parse(LocalIdentity.reseller).button_name}
                  </Typography>
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      )}
    </div>
  );
};

export default Footer;
