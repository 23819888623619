import { Button, Divider, Icon, IconButton, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import Footer from '../../components/footer'
import { Header } from '../../components/header'
import NavigationBar from '../../components/navigationBar'
import { ProfileNavbar, ProfileSideBar } from '../../components/profile/sidebar'
import { CENTER, Colors } from '../../utils/colors'

const ProfileUpdatePasswordScreen = () => {
    const [showOldPass, setShowOldPass] = React.useState(false)
    const [showNewPass, setShowNewPass] = React.useState(false)
    const [showConfirmPass, setShowConfirmPass] = React.useState(false)

    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <Header title="Profil Saya" />
            {
                isMobile ?
                    <div style={{ width: '100%' }}>
                        <ProfileNavbar activeMenu={6} />
                    </div>
                    : null
            }
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={'row'} sx={{ width: '90%', paddingY: 5 }} justifyContent={'space-between'}>
                    {
                        isMobile ? null :
                            <div style={{ width: '20%' }}>
                                <ProfileSideBar activeMenu={6} />
                            </div>
                    }
                    <div
                        style={{
                            width: isMobile ? '100%' : '78%',
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            border: isMobile ? undefined : '1px solid #999',
                            boxShadow: isMobile ? undefined : '0px 0px 10px 8px rgba(0,0,0,0.1)'
                        }}
                    >
                        <Stack direction={'column'} gap={3} padding={isMobile ? 0 : 3}>
                            <div>
                                <Typography variant="h5" fontWeight={'600'} gutterBottom>Ubah Kata Sandi</Typography>
                                <Typography variant="h6">Kelola informasi kata sandi Anda</Typography>
                            </div>
                            <Divider />
                            <Stack direction={isMobile ? 'column' : "row"} alignItems={'center'} gap={2} width={'100%'}>
                                <Typography variant="body1" color={'#999'} width={isMobile ? '100%' : '20%'}>Password Lama:</Typography>
                                <TextField
                                    placeholder="Password Lama..."
                                    size="small"
                                    sx={{ bgcolor: "white", width: isMobile ? '100%' : "50%" }}
                                    type={showOldPass ? 'text' : 'password'}
                                    InputProps={{
                                        sx: { borderRadius: 1 },
                                        endAdornment: (
                                            <IconButton onClick={() => setShowOldPass(!showOldPass)}>
                                                <Icon sx={{ fontSize: 25, color: '#ababab' }}>{showOldPass ? 'visibility_off' : 'visibility'}</Icon>
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Stack>
                            <Stack direction={isMobile ? 'column' : "row"} alignItems={'center'} gap={2} width={'100%'}>
                                <Typography variant="body1" color={'#999'} width={isMobile ? '100%' : '20%'}>Password Baru:</Typography>
                                <TextField
                                    placeholder="Password Baru..."
                                    size="small"
                                    sx={{ bgcolor: "white", width: isMobile ? '100%' : "50%" }}
                                    type={showNewPass ? 'text' : 'password'}
                                    InputProps={{
                                        sx: { borderRadius: 1 },
                                        endAdornment: (
                                            <IconButton onClick={() => setShowNewPass(!showNewPass)}>
                                                <Icon sx={{ fontSize: 25, color: '#ababab' }}>{showNewPass ? 'visibility_off' : 'visibility'}</Icon>
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Stack>
                            <Stack direction={isMobile ? 'column' : "row"} gap={2} width={'100%'}>
                                <Typography variant="body1" color={'#999'} width={isMobile ? '100%' : '20%'}>Konfirmasi Password Baru:</Typography>
                                <Stack direction={'column'} gap={3} width={isMobile ? '100%' : '50%'} alignItems={'flex-start'}>
                                    <TextField
                                        placeholder="Konfirmasi Password..."
                                        size="small"
                                        sx={{ bgcolor: "white", width: "100%" }}
                                        type={showConfirmPass ? 'text' : 'password'}
                                        InputProps={{
                                            sx: { borderRadius: 1 },
                                            endAdornment: (
                                                <IconButton onClick={() => setShowConfirmPass(!showConfirmPass)}>
                                                    <Icon sx={{ fontSize: 25, color: '#ababab' }}>{showConfirmPass ? 'visibility_off' : 'visibility'}</Icon>
                                                </IconButton>
                                            )
                                        }}
                                    />
                                    <Button color={'secondary'} variant={'contained'} sx={{ backgroundColor: Colors.primary }}>
                                        <Stack direction={"row"} gap={1} padding={1}>
                                            <Icon fontSize='small' sx={{ color: '#fff' }}>save</Icon>
                                            <Typography variant="body2" color={"#fff"}>Simpan</Typography>
                                        </Stack>
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </div>
                </Stack>
            </div>
            <Footer />
        </div>
    )
}

export default ProfileUpdatePasswordScreen;