import { AxiosMethod } from "../utils/interceptors";
const url = "carts";

export function HTTPGetCarts(param: { token: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/get`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetCartByID(param: {
  id: string;
  token: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/show/${param.id}`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPCreateCart(param: {
  token: string;
  product_id: string;
  product_variant_id: string;
  product_variant_sub_id: string;
  quantity: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}`, {
        token: param.token,
        product_id: param.product_id,
        product_variant_id: param.product_variant_id,
        product_variant_sub_id: param.product_variant_sub_id,
        quantity: param.quantity,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateCart(param: {
  token: string;
  cart_id: string;
  quantity: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().put(`${url}/${param.cart_id}`, {
        token: param.token,
        quantity: param.quantity,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteCart(param: {
  token: string;
  cart_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(
        `${url}/delete/${param.cart_id}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
