import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const ProfileSlice = createSlice({
  name: "Profile_Data",
  initialState: {
    data: {
      id: "",
      name: "",
      email: "",
      phone_number: "",
      username: "",
      dob: "",
      gender: "",
      profile_picture: "",
      social_links: [],
    } as any,
  },
  reducers: {
    setProfileData(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
    },
  },
});

export const { setProfileData } = ProfileSlice.actions;
export const ProfileData = ProfileSlice.reducer;
