import React from 'react'
import { Box, Button, CircularProgress, Divider, Grid, Icon, IconButton, Pagination, Stack, Tab, Tabs, Toolbar, Typography } from '@mui/material'
import Footer from '../../components/footer'
import NavigationBar from '../../components/navigationBar'
import { CENTER, Colors } from '../../utils/colors'
import DetailProductCard from '../../components/products/detail/productCard'
import { ProductSwiper } from '../../components/products/detail/productSwiper'
import { ProductComment } from '../../components/products/detail/productComment'
import { isMobile } from 'react-device-detect'
import { RelevantProducts } from '../../components/products/detail/relevantProducts'
import { useNavigate, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { RootState } from '../../stores/rootReducer'
import { toast } from 'react-toastify'
import { HTTPGetProductBySlug, HTTPGetProducts } from '../../apis/products'
import { ThreeDots } from 'react-loader-spinner'
import { Images } from '../../assets/images'
import { FormatCurrency } from '../../utils/utils'
import { HTTPCreateCart } from '../../apis/cart'
import secureLocalStorage from 'react-secure-storage'
import { HTTPDetailWishList } from '../../apis/wishlist'

const cardStyle = { borderRadius: 10, boxShadow: '7px 5px 25px -6px rgba(0,0,0,0.10)', backgroundColor: '#fff' }
const ProductDetailScreen = () => {
    const navigate = useNavigate()
    const { slug } = useParams()
    const token = secureLocalStorage.getItem('token') as string
    const ProfileRedux = useSelector((state: RootState) => state.profile_data.data)

    const [init, setInit] = React.useState(false)
    const [CartLoading, setCartLoading] = React.useState(false)
    const [BuyLoading, setBuyLoading] = React.useState(false)
    const [isLoading, setLoading] = React.useState(true)
    const [tabActive, setTabActive] = React.useState(0)
    const [DetailData, setDetailData] = React.useState<any>({})
    const [SelectedVariant, setSelectedVariant] = React.useState<any>({})
    const [SelectedSubVariant, setSelectedSubVariant] = React.useState<any>({})
    const [CurrentQuantity, setCurrentQuantity] = React.useState(0)
    const [Quantity, setQuantity] = React.useState(1)
    const [Products, setProducts] = React.useState([])
    const [WishlistId, setWishlistId] = React.useState<any>(null)
    const [Galleries, setGalleries] = React.useState<any>([])

    const GetWishlistStatus = async (product_id: string) => {
        const isLogin = localStorage.getItem('isLogin')
        if (isLogin !== null || isLogin !== undefined || isLogin !== 'false') {
            try {
                const result = await HTTPDetailWishList({ token, product_id })
                setWishlistId(result.data.data.id)
            } catch (error) {
                console.log(error)
                setWishlistId(null)
            }
        }
    }

    const GetProducts = async () => {
        try {
            const result = await HTTPGetProducts({ limit: 10 })
            setProducts(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabActive(newValue);
    };

    const GetProductDetail = async () => {
        setLoading(true)
        try {
            const result = await HTTPGetProductBySlug({ slug: slug as string })
            const productItem = result.data.data
            setDetailData(productItem)
            await SetGalleries(productItem)
            if (productItem.variants.length > 0) {
                setSelectedVariant(productItem.variants[0])
                setCurrentQuantity(productItem.variant_prices.find((item: any) => (item.variant.id === productItem.variants[0].id)).quantity)
            } else {
                setCurrentQuantity(productItem.quantity)
            }
            if (productItem.sub_variants.length > 0) {
                setSelectedSubVariant(productItem.sub_variants[0])
            }
            await GetWishlistStatus(productItem.id)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
            toast.error('Terjadi Kesalahan Pada Server!')
        }
    }

    const SetGalleries = async (data: any) => {
        try {
            let galleries = data.galleries

            if (data.variants.length > 0) {
                data.variants.map((item: any) => {
                    galleries.push({
                        id: item.id,
                        image_path: item.image,
                        type: 'pic'
                    })
                })
            }

            setGalleries(galleries)
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        function Initial() {
            GetProductDetail()
            GetProducts()
        }
        Initial()
    }, [init])

    const onCart = async () => {
        const isLogin = localStorage.getItem('isLogin')
        if (isLogin === null || isLogin === undefined || isLogin === 'false') {
            toast.info('Silahkan Login terlebih dahulu!')
            navigate('/auth/login')
        } else if (
            !ProfileRedux.name ||
            ProfileRedux.name.length < 1 ||
            !ProfileRedux.email ||
            ProfileRedux.email.length < 1 ||
            !ProfileRedux.phone_number ||
            ProfileRedux.phone_number.length < 1 ||
            !ProfileRedux.username ||
            ProfileRedux.username.length < 1 ||
            !ProfileRedux.dob ||
            ProfileRedux.dob.length < 1
        ) {
            toast.error('Pemesanan dibatalkan! Lengkapi terlebih dahulu informasi akun')
            navigate('/profile/account')
        } else {
            setCartLoading(true)
            try {
                await HTTPCreateCart({
                    token,
                    product_id: DetailData.id,
                    product_variant_id: SelectedVariant.id === undefined ? "" : SelectedVariant.id,
                    product_variant_sub_id: SelectedSubVariant.id === undefined ? "" : SelectedSubVariant.id,
                    quantity: Quantity
                })
                setCartLoading(false)
                toast.success('Produk Telah di Tambahkan Ke Keranjang!')
            } catch (error) {
                setCartLoading(false)
                toast.error('Tidak Dapat Menambahkan Keranjang!')
                console.log(error)
            }
        }
    }

    const onBuy = async () => {
        const isLogin = localStorage.getItem('isLogin')
        if (isLogin === null || isLogin === undefined || isLogin === 'false') {
            toast.info('Silahkan Login terlebih dahulu!')
            navigate('/auth/login')
        } else if (ProfileRedux.name.length === 0 ||
            ProfileRedux.email.length === 0 ||
            ProfileRedux.phone_number.length === 0 ||
            ProfileRedux.username.length === 0 ||
            ProfileRedux.dob.length === 0
        ) {
            toast.error('Pemesanan dibatalkan! Lengkapi terlebih dahulu informasi akun')
            navigate('/profile/account')
        } else {
            setBuyLoading(true)
            try {
                await HTTPCreateCart({
                    token,
                    product_id: DetailData.id,
                    product_variant_id: SelectedVariant.id === undefined ? "" : SelectedVariant.id,
                    product_variant_sub_id: SelectedSubVariant.id === undefined ? "" : SelectedSubVariant.id,
                    quantity: Quantity
                })
                setBuyLoading(false)
                navigate('/cart')
            } catch (error) {
                setBuyLoading(false)
                toast.error('Tidak Dapat Menambahkan Keranjang!')
                console.log(error)
            }
        }
    }

    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            {
                isMobile ?
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            zIndex: 2
                        }}
                        onClick={() => navigate(-1)}
                    >
                        <Icon sx={{ color: '#fff' }} fontSize={'medium'}>arrow_back</Icon>
                    </IconButton>
                    :
                    <>
                        <NavigationBar />
                        <Toolbar />
                    </>
            }
            {
                isLoading ?
                    <div style={{ width: '100%', overflow: isMobile ? 'auto' : 'none', backgroundColor: '#fff', padding: '10% 0' }}>
                        <Stack direction={'column'} gap={0} alignItems={'center'} justifyContent={'center'}>
                            <img src={Images.logo} style={{ width: '15vw', height: 'auto', objectFit: 'contain' }} alt="" />
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color={Colors.primary}
                                ariaLabel="three-dots-loading"
                                visible={true}
                            />
                        </Stack>
                    </div>
                    :
                    <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                        <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '90%' }} gap={2}>
                            <Stack direction={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'center' : 'space-between'} width={'100%'}>
                                <div style={{ width: '100%', ...cardStyle }}>
                                    <Stack direction={'row'} justifyContent={isMobile ? 'center' : 'flex-start'} gap={2} padding={isMobile ? 0 : 2}>
                                        <div style={{ width: isMobile ? '100%' : '28%' }}>
                                            {
                                                DetailData.galleries.length === 0 ?
                                                    <img src={Images.noImageProduct} style={{ width: '100%', aspectRatio: 1, objectFit: 'cover' }} alt="" />
                                                    :
                                                    <ProductSwiper
                                                        product_id={DetailData.id}
                                                        data={Galleries}
                                                        wishlistId={WishlistId}
                                                        getData={() => GetWishlistStatus(DetailData.id)}
                                                    />
                                            }
                                        </div>
                                        {
                                            isMobile ? null :
                                                <div style={{ width: '60%' }}>
                                                    <Stack direction={'column'} gap={2} padding={2} width={'100%'}>
                                                        <Typography variant={"h5"} fontWeight={'600'} textTransform={'capitalize'}>{DetailData.name_id}</Typography>
                                                        <Stack direction="row" gap={1} alignItems={"center"}>
                                                            <Icon fontSize={'small'} sx={{ color: Colors.warning }}>star</Icon>
                                                            <Typography variant={"body1"} color={'#999'}>{DetailData.rating}</Typography>
                                                            <Typography variant={"body1"} color={'#999'}>|</Typography>
                                                            <Typography variant={"body1"} color={Colors.primary}>{'Belum Terjual'}</Typography>
                                                        </Stack>
                                                        {
                                                            DetailData.variant_prices.length > 0 ?
                                                                <>
                                                                    {
                                                                        DetailData.sub_variants.length > 0 ?
                                                                            <Typography variant={"h4"}>Rp {FormatCurrency(DetailData.variant_prices.find((item: any) => (
                                                                                item.variant.id === SelectedVariant.id && item.variant_sub.id === SelectedSubVariant.id
                                                                            )).price * Quantity)}
                                                                            </Typography>
                                                                            :
                                                                            <Typography variant={"h4"}>Rp {FormatCurrency(DetailData.variant_prices.find((item: any) => (
                                                                                item.variant.id === SelectedVariant.id
                                                                            )).price * Quantity)}
                                                                            </Typography>
                                                                    }
                                                                </>
                                                                :
                                                                <Typography variant={"h4"}>Rp {FormatCurrency(DetailData.price * Quantity)}</Typography>
                                                        }
                                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                            <Typography variant={"body1"} fontWeight={'600'} color={"#ababab"} sx={{ width: '30%' }}>Stok</Typography>
                                                            <Typography variant={"body1"} sx={{ width: '68%' }} color={"#999"}>{CurrentQuantity}</Typography>
                                                        </Stack>
                                                        {
                                                            DetailData.variants.length > 0 ?
                                                                <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                                                                    <Typography variant={"body1"} fontWeight={'600'} color={"#ababab"} sx={{ width: '30%' }}>Varian</Typography>
                                                                    <Stack direction={'row'} alignItems={'center'} gap={0} flexWrap={'wrap'} width={'68%'}>
                                                                        {
                                                                            DetailData.variants.map((item: any, index: number) => (
                                                                                <Button
                                                                                    variant={SelectedVariant.id === item.id ? "outlined" : "text"}
                                                                                    color={"secondary"}
                                                                                    key={index}
                                                                                    onClick={() => { setCurrentQuantity(DetailData.variant_prices.find((value: any) => (value.variant.id === item.id)).quantity); setSelectedVariant(item) }}
                                                                                >
                                                                                    <Typography variant={"button"} color={SelectedVariant.id === item.id ? Colors.primary : "#999"}>{item.name_id}</Typography>
                                                                                </Button>
                                                                            ))
                                                                        }
                                                                    </Stack>
                                                                </Stack>
                                                                : null
                                                        }
                                                        {
                                                            DetailData.sub_variants.length === 0 ? null :
                                                                <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                                                                    <Typography variant={"body1"} fontWeight={'600'} color={"#ababab"} sx={{ width: '30%' }}>Jenis Varian</Typography>
                                                                    <Stack direction={'row'} alignItems={'center'} gap={0} flexWrap={'wrap'} width={'68%'}>
                                                                        {
                                                                            DetailData.sub_variants.map((item: any, index: number) => (
                                                                                <Button
                                                                                    variant={SelectedSubVariant.id === item.id ? "outlined" : "text"}
                                                                                    color={"secondary"}
                                                                                    key={index}
                                                                                    onClick={() => { setCurrentQuantity(DetailData.variant_prices.find((value: any) => (value.variant_sub.id === item.id)).quantity); setSelectedSubVariant(item) }}
                                                                                >
                                                                                    <Typography variant={"button"} color={SelectedSubVariant.id === item.id ? Colors.primary : "#999"}>{item.name_id}</Typography>
                                                                                </Button>
                                                                            ))
                                                                        }
                                                                    </Stack>
                                                                </Stack>
                                                        }
                                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                            <Typography variant={"body1"} fontWeight={'600'} color={"#ababab"} sx={{ width: '30%' }}>Kuantitas</Typography>
                                                            <div style={{ width: '68%' }}>
                                                                <Stack
                                                                    direction={"row"}
                                                                    alignItems={'center'}
                                                                    justifyContent={'space-around'}
                                                                    sx={{
                                                                        border: '1px solid #999',
                                                                        borderRadius: 1,
                                                                        width: '20%',
                                                                        padding: '5px 0'
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        fontSize={'medium'}
                                                                        sx={{ color: '#999', cursor: 'pointer' }}
                                                                        onClick={() => setQuantity(Quantity - 1 < 1 ? 1 : Quantity - 1)}
                                                                    >remove</Icon>
                                                                    <Typography variant="body1">{Quantity}</Typography>
                                                                    <Icon
                                                                        fontSize={'medium'}
                                                                        sx={{ color: '#999', cursor: 'pointer' }}
                                                                        onClick={() => setQuantity(Quantity + 1 > CurrentQuantity ? Quantity : Quantity + 1)}
                                                                    >add</Icon>
                                                                </Stack>
                                                            </div>
                                                        </Stack>
                                                        <div></div>
                                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ color: Colors.primary, width: '60%' }}>
                                                            <Button
                                                                disabled={CurrentQuantity === 0}
                                                                color={'inherit'}
                                                                variant={'outlined'}
                                                                sx={{ width: "58%" }}
                                                                onClick={onCart}
                                                            >
                                                                <Stack direction={"row"} gap={1} alignItems={'center'} padding={1}>
                                                                    {
                                                                        CartLoading ?
                                                                            <CircularProgress color={'secondary'} size={20} />
                                                                            :
                                                                            <Icon fontSize='small' sx={{ color: 'inherit' }}>add_shopping_cart</Icon>
                                                                    }
                                                                    <Typography variant={isMobile ? 'body1' : "caption"} color={"inherit"}>{isMobile ? 'Keranjang' : 'Masukkan Keranjang'}</Typography>
                                                                </Stack>
                                                            </Button>
                                                            <Button
                                                                disabled={CurrentQuantity === 0}
                                                                color={'secondary'}
                                                                variant={'contained'}
                                                                sx={{ width: '40%', backgroundColor: Colors.primary }}
                                                                onClick={onBuy}
                                                            >
                                                                <Stack direction={"row"} gap={1} alignItems={'center'} padding={1}>
                                                                    {
                                                                        BuyLoading ?
                                                                            <CircularProgress color={'secondary'} size={20} />
                                                                            :
                                                                            <Icon fontSize='small' sx={{ color: 'inherit' }}>local_mall</Icon>
                                                                    }
                                                                    <Typography textAlign={"left"} variant={isMobile ? 'body1' : "caption"} color={"inherit"}>{isMobile ? 'Beli' : 'Beli Sekarang'}</Typography>
                                                                </Stack>
                                                            </Button>
                                                        </Stack>
                                                    </Stack>
                                                </div>
                                        }
                                    </Stack>
                                </div >
                            </Stack >
                            <Stack direction={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'center' : 'space-between'} width={'100%'} alignItems={'flex-start'}>
                                <Stack direction={'column'} gap={2} width={isMobile ? '100%' : '77%'}>
                                    <div style={{ width: '100%', ...cardStyle }}>
                                        <Box sx={{ color: Colors.primary }}>
                                            <Tabs
                                                value={tabActive}
                                                onChange={handleChange}
                                                textColor={'inherit'}
                                                indicatorColor={'secondary'}
                                                variant={isMobile ? 'fullWidth' : 'standard'}
                                            >
                                                <Tab label="Deskripsi Produk" sx={{ fontSize: isMobile ? undefined : '1em', fontWeight: '600' }} />
                                                <Tab label="Penilaian Produk" sx={{ fontSize: isMobile ? undefined : '1em', fontWeight: '600' }} />
                                            </Tabs>
                                        </Box>
                                        {
                                            tabActive === 0 ?
                                                <Box sx={{ padding: 2 }}>
                                                    <Typography paragraph sx={{ whiteSpace: 'pre-line' }}>
                                                        {DetailData.description_id}
                                                    </Typography>
                                                </Box>
                                                :
                                                <Box sx={{ padding: 2 }}>
                                                    <ProductComment />
                                                    <Toolbar />
                                                    <div style={{ width: '100%', ...CENTER }}>
                                                        <Pagination count={5} />
                                                    </div>
                                                </Box>
                                        }
                                    </div>
                                    {!isMobile ? null : 
                                        <div style={{ width: '60%' }}>
                                            <Stack direction={'column'} gap={2} padding={2} width={'100%'}>
                                                {
                                                    DetailData.variant_prices.length > 0 ?
                                                        <>
                                                            {
                                                                DetailData.sub_variants.length > 0 ?
                                                                    <Typography variant={"h4"}>Rp {FormatCurrency(DetailData.variant_prices.find((item: any) => (
                                                                        item.variant.id === SelectedVariant.id && item.variant_sub.id === SelectedSubVariant.id
                                                                    )).price * Quantity)}
                                                                    </Typography>
                                                                    :
                                                                    <Typography variant={"h4"}>Rp {FormatCurrency(DetailData.variant_prices.find((item: any) => (
                                                                        item.variant.id === SelectedVariant.id
                                                                    )).price * Quantity)}
                                                                    </Typography>
                                                            }
                                                        </>
                                                        :
                                                        <Typography variant={"h4"}>Rp {FormatCurrency(DetailData.price * Quantity)}</Typography>
                                                        }
                                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                            <Typography variant={"body1"} fontWeight={'600'} color={"#ababab"} sx={{ width: '30%' }}>Stok</Typography>
                                                            <Typography variant={"body1"} sx={{ width: '68%' }} color={"#999"}>{CurrentQuantity}</Typography>
                                                        </Stack>
                                                        {
                                                            DetailData.variants.length > 0 ?
                                                                <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                                                                    <Typography variant={"body1"} fontWeight={'600'} color={"#ababab"} sx={{ width: '30%' }}>Varian</Typography>
                                                                    <Stack direction={'row'} alignItems={'center'} gap={0} flexWrap={'wrap'} width={'68%'}>
                                                                        {
                                                                            DetailData.variants.map((item: any, index: number) => (
                                                                                <Button
                                                                                    variant={SelectedVariant.id === item.id ? "outlined" : "text"}
                                                                                    color={"secondary"}
                                                                                    key={index}
                                                                                    onClick={() => { setCurrentQuantity(DetailData.variant_prices.find((value: any) => (value.variant.id === item.id)).quantity); setSelectedVariant(item) }}
                                                                                >
                                                                                    <Typography variant={"button"} color={SelectedVariant.id === item.id ? Colors.primary : "#999"}>{item.name_id}</Typography>
                                                                                </Button>
                                                                            ))
                                                                        }
                                                                    </Stack>
                                                                </Stack>
                                                                : null
                                                        }
                                                {
                                                    DetailData.sub_variants.length === 0 ? null :
                                                        <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                                                            <Typography variant={"body1"} fontWeight={'600'} color={"#ababab"} sx={{ width: '30%' }}>Jenis Varian</Typography>
                                                            <Stack direction={'row'} alignItems={'center'} gap={0} flexWrap={'wrap'} width={'68%'}>
                                                                {
                                                                    DetailData.sub_variants.map((item: any, index: number) => (
                                                                        <Button
                                                                            variant={SelectedSubVariant.id === item.id ? "outlined" : "text"}
                                                                            color={"secondary"}
                                                                            key={index}
                                                                            onClick={() => { setCurrentQuantity(DetailData.variant_prices.find((value: any) => (value.variant_sub.id === item.id)).quantity); setSelectedSubVariant(item) }}
                                                                        >
                                                                            <Typography variant={"button"} color={SelectedSubVariant.id === item.id ? Colors.primary : "#999"}>{item.name_id}</Typography>
                                                                        </Button>
                                                                    ))
                                                                }
                                                            </Stack>
                                                        </Stack>
                                                }
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                    <Typography variant={"body1"} fontWeight={'600'} color={"#ababab"} sx={{ width: '50%' }}>Kuantitas</Typography>
                                                    <div style={{ width: '68%' }}>
                                                        <Stack
                                                            direction={"row"}
                                                            alignItems={'center'}
                                                            justifyContent={'space-around'}
                                                            sx={{
                                                                border: '1px solid #999',
                                                                borderRadius: 1,
                                                                width: '50%',
                                                                padding: '5px 0'
                                                            }}
                                                        >
                                                            <Icon
                                                                fontSize={'medium'}
                                                                sx={{ color: '#999', cursor: 'pointer' }}
                                                                onClick={() => setQuantity(Quantity - 1 < 1 ? 1 : Quantity - 1)}
                                                            >remove</Icon>
                                                            <Typography variant="body1">{Quantity}</Typography>
                                                            <Icon
                                                                fontSize={'medium'}
                                                                sx={{ color: '#999', cursor: 'pointer' }}
                                                                onClick={() => setQuantity(Quantity + 1 > CurrentQuantity ? Quantity : Quantity + 1)}
                                                            >add</Icon>
                                                        </Stack>
                                                    </div>
                                                </Stack>
                                                <div></div>
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ color: Colors.primary, width: '100%' }}>
                                                    <Button
                                                        disabled={CurrentQuantity === 0}
                                                        color={'inherit'}
                                                        variant={'outlined'}
                                                        sx={{ width: "58%" }}
                                                        onClick={onCart}
                                                    >
                                                        <Stack direction={"row"} gap={1} alignItems={'center'} padding={1}>
                                                            {
                                                                CartLoading ?
                                                                    <CircularProgress color={'secondary'} size={20} />
                                                                    :
                                                                    <Icon fontSize='small' sx={{ color: 'inherit' }}>add_shopping_cart</Icon>
                                                            }
                                                            <Typography variant={isMobile ? 'body1' : "caption"} color={"inherit"}>{isMobile ? 'Keranjang' : 'Masukkan Keranjang'}</Typography>
                                                        </Stack>
                                                    </Button>
                                                    <Button
                                                        disabled={CurrentQuantity === 0}
                                                        color={'secondary'}
                                                        variant={'contained'}
                                                        sx={{ width: '40%', backgroundColor: Colors.primary }}
                                                        onClick={onBuy}
                                                    >
                                                        <Stack direction={"row"} gap={1} alignItems={'center'} padding={1}>
                                                            {
                                                                BuyLoading ?
                                                                    <CircularProgress color={'secondary'} size={20} />
                                                                    :
                                                                    <Icon fontSize='small' sx={{ color: 'inherit' }}>local_mall</Icon>
                                                            }
                                                            <Typography textAlign={"left"} variant={isMobile ? 'body1' : "caption"} color={"inherit"}>{isMobile ? 'Beli' : 'Beli Sekarang'}</Typography>
                                                        </Stack>
                                                    </Button>
                                                </Stack>
                                            </Stack>
                                        </div>
                                    }
                                    <div style={{ width: '100%', ...cardStyle }}>
                                        <RelevantProducts data={Products} />
                                    </div>
                                </Stack>
                                <div style={{ width: isMobile ? '100%' : '22%', ...cardStyle }}>
                                    <Stack direction={'column'} gap={2} padding={2} divider={<Divider />}>
                                        <Typography variant="h6" fontWeight={'600'}>Produk Lainnya</Typography>
                                        <Grid container sx={{ width: '100%' }}>
                                            {
                                                Products.map((item: any, index: number) => (
                                                    <Grid item md={12} xs={6} key={index}>
                                                        <DetailProductCard item={item} />
                                                    </Grid>
                                                )).slice(0, 4)
                                            }
                                        </Grid>
                                    </Stack>
                                </div>
                            </Stack>
                        </Stack >
                    </div >
            }
            <Footer />
        </div >
    )
}

export default ProductDetailScreen;