import axios from "axios";
import { AxiosMethod } from "../utils/interceptors";
const url = "customer/auth";

export function HTTPLogin(param: {
  email: string;
  password: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/login`, {
        email: param.email.toLowerCase(),
        password: param.password,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPRefreshToken(param: { token: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/refresh_token`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPRegister(param: { email: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/register`, {
        email: param.email.toLowerCase(),
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPProfile(param: { token: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/me`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPVerifyToken(param: {
  user_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`customer/request_token`, {
        params: {
          user_id: param.user_id,
        }, 
        headers: {
          arenvy_secret: 'arenvy2097'
        }
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPVerifyOTP(param: {
  token: string;
  otp: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/verify_otp`, {
        token: param.token,
        otp: param.otp,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPCreatePassword(param: {
  token: string;
  password: string;
  confirmed_password: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/create_password`, {
        token: param.token,
        password: param.password,
        confirmed_password: param.confirmed_password,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPForgotPassword(param: { email: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/forgot_password`, {
        email: param.email,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
