import { AxiosMethod } from "../utils/interceptors";
const url = "sertifications";

export function HTTPGetCertificates(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetCertificateById(param: {
  id_certificate: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}/detail/${param.id_certificate}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
