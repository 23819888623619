import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RouterInit } from "../utils/routerInit";
import Page from "./routerTitle";

import HomeScreen from "../pages/home";
import LoginScreen from "../pages/auth/login";
import RegisterScreen from "../pages/auth/register";
import GoogleLoginScreen from "../pages/auth/googleLogin";
import ProductListScreen from "../pages/products/productList";
import ProductDetailScreen from "../pages/products/productDetail";
import CartScreen from "../pages/cart/cart";
import PaymentScreen from "../pages/cart/payment";
import PaymentSuccessScreen from "../pages/cart/paymentSuccess";
import ProfileAccountScreen from "../pages/profile/account";
import ProfileAddressScreen from "../pages/profile/address";
import ProfileOrderHistoryScreen from "../pages/profile/orderHistory";
import ProfilePointScreen from "../pages/profile/point";
import ProfileUpdatePasswordScreen from "../pages/profile/updatePassword";
import ProfileCards from "../pages/profile/cards";
import FavoriteScreen from "../pages/favorites/favorites";
import ArticleListScreen from "../pages/article/articleList";
import ArticleDetailScreen from "../pages/article/articleDetail";
import PrivacyAboutScreen from "../pages/privacy/about";
import PrivacyPolicyScreen from "../pages/privacy/policy";
import CertificateScreen from "../pages/certificate/certificate";
import ForgotPasswordScreen from "../pages/auth/forgotPassword";
import NotificationScreen from "../pages/notifications/notifications";
import Page404 from "../pages/additional/page404";
import ProtectedRoutes from "./protectedRoutes";
import BannerScreen from "../pages/banner/banner";
import FAQScreen from "../pages/FAQ/faq";

const PageRoutes = () => {
    return (
        <Router>
            <RouterInit />
            <Routes>
                <Route path="*" element={<Page title="404 | Angeloskin"><Page404 /></Page>} />
                <Route path="/" element={<Page title="Angeloskin"><HomeScreen /></Page>} />
                <Route path="/error" element={<Page title="404 | Angeloskin"><Page404 /></Page>} />
                <Route path="/auth/:type" element={<Page title="Auth | Angeloskin"><LoginScreen /></Page>} />
                <Route path="/register" element={<Page title="Daftar | Angeloskin"><RegisterScreen /></Page>} />
                <Route path="/google-auth" element={<Page title="Akun Google | Angeloskin"><GoogleLoginScreen /></Page>} />
                <Route path="/forgot-password" element={<Page title="Lupa Kata Sandi | Angeloskin"><ForgotPasswordScreen /></Page>} />
                <Route path="/product/list?" element={<Page title="Daftar Produk | Angeloskin"><ProductListScreen /></Page>} />
                <Route path="/product/detail/:slug" element={<Page title="Detail Produk | Angeloskin"><ProductDetailScreen /></Page>} />
                <Route path="/article/list" element={<Page title="Artikel | Angeloskin"><ArticleListScreen /></Page>} />
                <Route path="/article/detail/:slug" element={<Page title="Angeloskin"><ArticleDetailScreen /></Page>} />
                <Route path="/privacy/about" element={<Page title="Tentang Kami | Angeloskin"><PrivacyAboutScreen /></Page>} />
                <Route path="/privacy/policy" element={<Page title="Kebijakan Privasi | Angeloskin"><PrivacyPolicyScreen /></Page>} />
                <Route path="/certificate" element={<Page title="Sertifikat | Angeloskin"><CertificateScreen /></Page>} />
                <Route path="/slider/:slug" element={<Page title="Promosi | Angeloskin"><BannerScreen /></Page>} />
                <Route path="/faq" element={<Page title="FAQ | Angeloskin"><FAQScreen /></Page>} />
                <Route element={<ProtectedRoutes />}>
                    <Route path="/cart" element={<Page title="Keranjang | Angeloskin"><CartScreen /></Page>} />
                    {/* <Route path="/cart/payment" element={<Page title="Pembayaran | Angeloskin"><PaymentScreen /></Page>} />
                    <Route path="/cart/payment/success" element={<Page title="Pembayaran Berhasil | Angeloskin"><PaymentSuccessScreen /></Page>} /> */}
                    <Route path="/profile/account" element={<Page title="Akun | Angeloskin"><ProfileAccountScreen /></Page>} />
                    <Route path="/profile/address" element={<Page title="Daftar Alamat | Angeloskin"><ProfileAddressScreen /></Page>} />
                    <Route path="/profile/order-history" element={<Page title="Riwayat Pesanan | Angeloskin"><ProfileOrderHistoryScreen /></Page>} />
                    {/* <Route path="/profile/point" element={<Page title="Poin Saya | Angeloskin"><ProfilePointScreen /></Page>} /> */}
                    <Route path="/profile/update-password" element={<Page title="Ubah Kata Sandi | Angeloskin"><ProfileUpdatePasswordScreen /></Page>} />
                    {/* <Route path="/profile/cards" element={<Page title="Daftar Kartu | Angeloskin"><ProfileCards /></Page>} /> */}
                    <Route path="/favorite" element={<Page title="Produk Favorit | Angeloskin"><FavoriteScreen /></Page>} />
                    <Route path="/notification" element={<Page title="Notifikasi | Angeloskin"><NotificationScreen /></Page>} />
                </Route>
            </Routes>
        </Router>
    );
};

export default PageRoutes;