import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, Icon, IconButton, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDropzone } from 'react-dropzone';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { CENTER, Colors } from '../../utils/colors';
import getCroppedImg from '../../utils/cropTools';
import { ImageCropper } from './imageCropper';
import { HTTPUpdateProfilePicture } from '../../apis/profile'
import { HTTPUploadFile } from '../../apis/uploadFile';
import axios from 'axios';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setProfileData } from '../../stores/data/pofile';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ImageDrozoneModal = (param: { isOpen: boolean, onClose: any }) => {
    const dispatch = useDispatch()
    const TOKEN = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)
    // const [ImageCropResult, setImageCropResult] = React.useState<any>({ area: null, rotation: 0 })

    const CloseModal = () => {
        param.onClose()
    }

    const onImageUpdate = async (file: any) => {
        setLoading(true)
        try {
            const Forms: any = new FormData()
            Forms.append('file', file)
            Forms.append('type', 'profile_pic')
            const imageUrl = await HTTPUploadFile({ form: Forms })

            const result = await HTTPUpdateProfilePicture({
                token: TOKEN,
                profile_picture: imageUrl.data.data
            })
            dispatch(setProfileData({ data: result.data.data }))

            toast.success('Foto Profil telah berhasil diperbarui!')
            setLoading(false)
            CloseModal()
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi kesalahan saat mengubah foto!')
            console.log(error)
        }
    }

    const SetImage = async (file: any) => {
        if (file[0].size > 3145728) {
            toast.error('Ukuran file harus dibawah 3MB')
        } else {
            await onImageUpdate(file[0])
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": [],
        },
        onDrop: SetImage,
        multiple: false,
    });

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={CloseModal}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '95vw' : '50vw' } }}
        >
            <DialogContent>
                <Stack direction={"column"} gap={3} divider={<Divider />} position={'relative'}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant={"h6"} fontWeight={'600'} color={"#000"}>Ubah Foto Profil</Typography>
                        <IconButton onClick={CloseModal}><Icon fontSize={'medium'} sx={{ color: Colors.disable }}>close</Icon></IconButton>
                    </Stack>
                    <div
                        {...getRootProps({ className: "dropzone" })}
                        style={{
                            ...CENTER,
                            border: `2px dashed ${Colors.primary}`,
                            padding: "30px 0",
                            borderRadius: 10,
                            cursor: "pointer",
                            width: '100%',
                            position: 'relative'
                        }}
                    >
                        <input {...getInputProps()} />
                        {
                            isLoading ?
                                <div style={{ padding: '30px 0' }}>
                                    <CircularProgress color="secondary" size={80} />
                                </div>
                                :
                                <Stack spacing={2} direction={"column"} alignItems={"center"}>
                                    <Icon style={{ color: Colors.primary }} fontSize={'large'}>cloud_upload</Icon>
                                    <h2
                                        style={{
                                            color: Colors.primary,
                                            fontStyle: "italic",
                                            textAlign: "center",
                                            whiteSpace: "pre-wrap",
                                            fontWeight: 400
                                        }}
                                    >
                                        Letakan gambar disini
                                    </h2>
                                    <span style={{ color: '#999' }}>atau</span>
                                    <div style={{ padding: '5px 20px', ...CENTER, border: `1px solid ${Colors.primary}`, borderRadius: 10 }}>
                                        <span style={{ color: Colors.primary }}>Upload Gambar</span>
                                    </div>
                                </Stack>
                        }
                    </div>
                    {/* {Image.length === 0 ? ( */}
                    {/* ) : (
                         <> */}
                    {/* <div
                                style={{
                                    position: "relative",
                                    width: '100%',
                                }}
                            >
                                <img
                                    src={Image}
                                    style={{
                                        borderRadius: "10px",
                                        width: "100%",
                                        objectFit: "cover",
                                        height: "100%",
                                        aspectRatio: 16 / 9,
                                    }}
                                    alt="..."
                                />
                                <IconButton
                                    onClick={() => setImage('')}
                                    sx={{
                                        position: "absolute",
                                        top: 5,
                                        right: 5,
                                        padding: 1,
                                        backgroundColor: "rgba(0,0,0,0.5)",
                                    }}
                                >
                                    <Icon style={{ color: "#fff" }}>delete</Icon>
                                </IconButton>
                            </div> */}
                    {/* <ImageCropper
                                image={Image}
                                result={(area: any, rotation: any) => setImageCropResult({ area, rotation })}
                            /> */}
                    {/* </>
                    )} */}
                </Stack>
            </DialogContent >
        </Dialog >
    )
}