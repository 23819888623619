import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { CENTER, Colors } from '../../utils/colors';
import { HTTPVerifyToken } from '../../apis/auth'
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { Icons } from '../../assets/icons';
import { ThreeDots } from 'react-loader-spinner';
import { Stack } from '@mui/material';

const GoogleLoginScreen = () => {
    const navigate = useNavigate()
    const [init, setInit] = React.useState(false)
    const [searchParams, setSearchParams] = useSearchParams();

    const AccessToken = async () => {
        try {
            const result = await HTTPVerifyToken({ user_id: searchParams.get('user_id') as string })
            secureLocalStorage.setItem('token', result.data.data.token)
            secureLocalStorage.setItem('refresh_token', result.data.data.refresh_token)
            localStorage.setItem('isLogin', 'true')
            setTimeout(() => {
                navigate('/')
            }, 2000)
        } catch (error) {
            toast.error('Terjadi Kesalahan!')
            console.log(error)
            setTimeout(() => {
                navigate(-1)
            }, 1000)
        }
    }

    React.useEffect(() => {
        async function APIList() {
            await AccessToken()
        } 
        APIList().then().catch()
    }, [init])

    return (
        <div style={{ ...CENTER, height: '100vh', width: '100%', overflow: isMobile ? 'auto' : 'none', backgroundColor: '#fff' }}>
            <Stack direction={'column'} gap={0} alignItems={'center'} justifyContent={'center'}>
                <img src={Icons.google} style={{ width: '5vh', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color={Colors.primary}
                    ariaLabel="three-dots-loading"
                    visible={true}
                />
            </Stack>
        </div>
    )
}

export default GoogleLoginScreen;