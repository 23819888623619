import { Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react'
import { toast } from 'react-toastify';
import { HTTPGetCertificates } from '../../apis/certificate';
import CertificateSwiper from '../../components/certificate/certificateSwiper';
import Footer from '../../components/footer';
import { Header } from '../../components/header';
import NavigationBar from '../../components/navigationBar';
import { CENTER } from '../../utils/colors';

const CertificateScreen = () => {
    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(true)
    const [CertificateList, setCertificateList] = React.useState([])

    const GetCertificates = async () => {
        setLoading(true)
        try {
            const result = await HTTPGetCertificates()
            setCertificateList(result.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan Server!')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            await GetCertificates()
        }

        Initial().then().catch()
    }, [init])
    
    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <Header title="Sertifikat Kami" />
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={'column'} width={'80%'} padding={5}>
                    {
                        isLoading ?
                            <Skeleton animation={"wave"} variant="rounded" width={'100%'} height={'100%'} sx={{ aspectRatio: 16 / 9 }} />
                            :
                            <CertificateSwiper data={CertificateList} />
                    }
                </Stack>
            </div>
            <Footer />
        </div>
    )
}

export default CertificateScreen;