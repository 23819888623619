import { AxiosMethod } from "../utils/interceptors";
const url = "customer/addresses";
const location = "locations";

export function HTTPGetAddress(param: { token: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/get`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetAddressById(param: {
  token: string;
  id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/show/${param.id}`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPCreateAddress(param: {
  token: string;
  province_id: string;
  city_id: string;
  district_id: string;
  address: string;
  phone: string;
  label: string;
  is_main: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}`, {
        token: param.token,
        province_id: param.province_id,
        city_id: param.city_id,
        subdistrict_id: param.district_id,
        address: param.address,
        phone: param.phone,
        label: param.label,
        is_main: param.is_main,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateAddress(param: {
  token: string;
  address_id: string;
  province_id: string;
  city_id: string;
  address: string;
  phone: string;
  label: string;
  is_main: number;
  district_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().put(`${url}/${param.address_id}`, {
        token: param.token,
        province_id: param.province_id,
        city_id: param.city_id,
        address: param.address,
        phone: param.phone,
        label: param.label,
        is_main: param.is_main,
        subdistrict_id: param.district_id,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteAddress(param: { address_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().delete(`${url}/${param.address_id}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetProvincies(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${location}/provinces`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetCities(param: { province_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(
        `${location}/cities/${param.province_id}`
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetDistricts(param: { city_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(
        `${location}/subdistricts/${param.city_id}`
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
