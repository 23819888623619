import * as React from 'react';
import './styles.css'
import { CENTER, Colors } from '../../utils/colors';
import { Icon, Stack, Step, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, Stepper } from '@mui/material'
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { OTPSection, PasswordSection, SuccessSection } from '../../components/login/registerSection';
import { isMobile } from 'react-device-detect';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: '#949494',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: '#949494',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: Colors.success,
    }),
    ...(ownerState.completed && {
        backgroundColor: Colors.success,
    }),
}));

function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <Icon fontSize='medium' sx={{ color: '#fff' }}>email</Icon>,
        2: <Icon fontSize='medium' sx={{ color: '#fff' }}>lock</Icon>,
        3: <Icon fontSize='medium' sx={{ color: '#fff' }}>check</Icon>,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {
                completed ?
                    <Icon fontSize='medium' sx={{ color: '#fff' }}>check</Icon>
                    :
                    icons[String(props.icon)]
            }
        </ColorlibStepIconRoot>
    );
}

const steps = ['OTP Email', 'Ubah Password', 'Selesai'];

const ForgotPasswordScreen = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [triggerAnim, setTriggerAnim] = React.useState('0')

    return (
        <div style={{ ...CENTER, height: '100vh', width: '100%', overflow: isMobile ? 'auto' : 'none', backgroundColor: '#fff' }}>
            <Stack
                direction={'column'}
                height={"100%"}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={isMobile ? '90%' : '100%'}
            >
                <img src={require('../../assets/images/logo.png')} style={{ height: '8%', width: 'auto', objectFit: 'contain', paddingTop: '1%' }} alt="" />
                <Stack direction={'column'} width={isMobile ? '100%' : '50%'} alignItems={'center'} justifyContent={'center'} spacing={3}>
                    <Stepper sx={{ width: '100%' }} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div
                        style={{
                            width: '100%',
                            borderRadius: 20,
                            backgroundColor: '#fff',
                            boxShadow: '0px 0px 20px 8px rgba(0,0,0,0.1)',
                            transition: 'all .3s',
                            position: 'relative',
                            overflow: 'hidden'
                        }}
                    >
                        {
                            activeStep === 0 ?
                                <div className={`${triggerAnim === '1' ? 'slide-left-out' : ''}`}>
                                    <OTPSection onStep={() => {
                                        setTriggerAnim('1')
                                        setTimeout(() => (
                                            setActiveStep(1)
                                        ), 300)
                                    }} />
                                </div>
                                : activeStep === 1 ?
                                    <div className={`${triggerAnim === '1' ? 'slide-right-in' : 'slide-left-out'}`}>
                                        <PasswordSection onStep={() => {
                                            setTriggerAnim('2')
                                            setTimeout(() => (
                                                setActiveStep(2)
                                            ), 500)
                                        }} />
                                    </div>
                                    :
                                    <div className={`${triggerAnim === '2' ? 'slide-right-in' : ''}`}>
                                        <SuccessSection />
                                    </div>
                        }
                    </div>
                </Stack>
                <span style={{ color: '#000', paddingBottom: '1%' }}>{'@ ' + moment().format('YYYY') + ', Angelo Skin.'}</span>
            </Stack>
        </div>
    )
}

export default ForgotPasswordScreen;