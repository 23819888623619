import { combineReducers } from "@reduxjs/toolkit";
import { RegisterTempData } from "./data/login";
import { ProfileData } from "./data/pofile";
import { SliderTempData } from "./data/slider";
import { ProductDetailData } from "./data/product";
import { CartData } from "./data/cart";

const rootReducer = combineReducers({
  register_data: RegisterTempData,
  profile_data: ProfileData,
  slider_data: SliderTempData,
  product_detail: ProductDetailData,
  cart_data: CartData,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
