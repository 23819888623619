import { Button, CircularProgress, Divider, FormControlLabel, Icon, Radio, RadioGroup, Skeleton, Stack, TextField, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import secureLocalStorage from 'react-secure-storage'
import { toast } from 'react-toastify'
import { HTTPGetCouriersCost } from '../../apis/couriers'
import { HTTPCalculatePrice, HTTPCreateOrder } from '../../apis/transaction'
import { PaymentModal } from '../../components/cart/paymentSection'
import { VoucherModal } from '../../components/cart/voucherSection'
import { RootState } from '../../stores/rootReducer'
import { Colors } from '../../utils/colors'
import { FormatCurrency } from '../../utils/utils'
import { AddressModal } from './addressSection'
import { ShippingModal } from './shippingSection'

export const CartCheckout = (param: { navigate: any, subtotal: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [onAddressModal, setAddressModal] = React.useState(false)
    const [onShippingModal, setShippingModal] = React.useState(false)
    const [onPaymentModal, setPaymentModal] = React.useState(false)
    const [onVoucherModal, setVoucherModal] = React.useState(false)

    const [SubmitLoading, setSubmitLoading] = React.useState(false)
    const [Address, setAddress] = React.useState<any>({ id: '' })
    const [Courier, setCourier] = React.useState<any>({ code: '', name: '' })
    const [ShippingCost, setShippingCost] = React.useState([])
    const [SelectedCourier, setSelectedCourier] = React.useState<any>('')
    const [CostLoading, setCostLoading] = React.useState(false)
    const [CostValue, setCostValue] = React.useState<any>({ service: '' })
    const [Payment, setPayment] = React.useState<any>({ code: '', name: '' })
    const [Voucher, setVoucher] = React.useState('')
    const [VoucherValid, setVoucherValid] = React.useState(false)
    const [VoucherLoading, setVoucherLoading] = React.useState(false)
    const [PriceUpdate, setPriceUpdate] = React.useState('')
    const [CurrentPrice, setCurrentPrice] = React.useState({
        subtotal: 0,
        subtotal_text: "Rp0",
        total: 0,
        total_text: "Rp0",
        service_fee: 0,
        service_fee_text: "Rp0",
        tax: 0,
        tax_text: "Rp0",
        vat: 0,
        vat_text: "Rp0",
        discount: 0,
        discount_text: "Rp0",
        delivery_fee: 0,
        delivery_fee_text: "Rp0",
        payment_fee: 0,
        payment_fee_text: "Rp0",
        points: 0,
        points_text: "0",
    })

    React.useEffect(() => {
        function InitCurrentPrice() {
            setCurrentPrice(param.subtotal)
        }
        InitCurrentPrice()
    }, [param.subtotal])

    const CalculatePrice = async () => {
        try {
            const calculate = await HTTPCalculatePrice({
                token,
                shipping_info: {
                    address_id: Address.id,
                    courier_code: Courier.code,
                    shipping_package: CostValue
                },
                payment_method: Payment.code,
                voucher: Voucher,
            })
            setCurrentPrice(calculate.data.data)
            setVoucherValid(true)
        } catch (error: any) {
            setVoucherValid(false)
            setVoucher('')
            // toast.error(error.data.message)
        }
    }

    const GetCurrentPrice = async (value: any, type: string) => {
        if (type === 'courier') {
            setCourier(value)
            try {
                setCostLoading(true)
                const result = await HTTPGetCouriersCost({ token, address_id: Address.id, courier_code: value.code })
                setShippingCost(result.data.data)
                setCostValue(result.data.data[0])
                setSelectedCourier(result.data.data[0].service)
                setCostLoading(false)
                console.log(Courier)
                window.setTimeout(() => {
                    setPriceUpdate(moment().format('x'))
                }, 500);
            } catch (error) {
                setCostLoading(false)
            }
        } else if (type === 'payment') {
            setPayment(value)
            window.setTimeout(() => {
                setPriceUpdate(moment().format('x'))
            }, 500);
        } else if (type === 'voucher') {
            setVoucherLoading(true)
            setVoucher(value)
            window.setTimeout(() => {
                setPriceUpdate(moment().format('x'))
            }, 500);
            setVoucherLoading(false)
        } else {
            return
        }
    }

    const onCourier = async (event: any) => {
        setSelectedCourier(event.target.value)
        const newValue = ShippingCost.find((item: any) => item.service === event.target.value)
        setCostValue(newValue)
        window.setTimeout(() => {
            setPriceUpdate(moment().format('x'))
        }, 500);
    }

    const onSubmit = async () => {
        setSubmitLoading(true)
        try {
            const result = await HTTPCreateOrder({
                token,
                shipping_info: {
                    address_id: Address.id,
                    courier_code: Courier.code,
                    shipping_package: CostValue
                },
                payment_method: Payment.code,
                voucher: VoucherValid ? Voucher.toLowerCase() : ''
            })
            setSubmitLoading(false)
            window.open(result.data.data, "_self")
        } catch (error) {
            setSubmitLoading(false)
            console.log(error)
            toast.error('Terjadi Kesalahan Ketika Melakukan Checkout!')
        }
    }

    React.useEffect(() => {
        async function Updater() {
            await CalculatePrice()
        }
        Updater().then().catch()
    }, [PriceUpdate])

    return (
        <>
            <Stack direction={'column'} gap={3} padding={2} divider={<Divider />}>
                <Typography variant="body1" fontWeight={'600'}>Total Keranjang</Typography>
                <Stack direction={"row"} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant="body1">Subtotal</Typography>
                    <Typography variant="body1">{CurrentPrice.subtotal_text}</Typography>
                </Stack>
                <Stack direction={'column'} gap={2}>
                    <Typography variant="body1">Alamat</Typography>
                    <Typography variant="body2" fontWeight={'600'}>Alamat Rumah -</Typography>
                    <Stack
                        direction={"row"}
                        gap={1}
                        alignItems={'center'}
                        justifyContent={"space-between"}
                        sx={{ padding: 1, borderRadius: 1, border: '1px solid #999', cursor: 'pointer', backgroundColor: 'white' }}
                        onClick={() => setAddressModal(true)}
                    >
                        <Typography variant="body2" fontWeight={'400'} sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{Address.id === '' ? 'Pilih Alamat' : Address.address + ', ' + Address.subdistrict.name + ', ' + Address.city.type + ' ' + Address.city.name + ', ' + Address.province.name + ', ' + Address.postal_code}</Typography>
                        <Icon fontSize={'small'} sx={{ color: '#999' }}>chevron_right</Icon>
                    </Stack>
                </Stack>
                <Stack direction={'column'} gap={2}>
                    <Typography variant="body1">Pengiriman</Typography>
                    <Stack
                        onClick={() => Address.id !== '' ? setShippingModal(true) : {}}
                        direction={"row"}
                        gap={1}
                        alignItems={'center'}
                        justifyContent={"space-between"}
                        sx={{ padding: 1, borderRadius: 1, border: '1px solid #999', cursor: 'pointer', backgroundColor: Address.id === '' ? '#ddd' : 'white' }}
                    >
                        <Typography variant="body2" fontWeight={'400'} sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: Address.id === '' ? '#999' : '#000'
                        }}>{Courier.name === '' ? 'Pilih Pengiriman' : Courier.name}</Typography>
                        <Icon fontSize={'small'} sx={{ color: '#999' }}>chevron_right</Icon>
                    </Stack>
                    {
                        CostLoading ?
                            <Stack direction={'column'} gap={1}>
                                <Skeleton animation={"wave"} variant="rounded" width={'100%'} height={'30px'} />
                                <Skeleton animation={"wave"} variant="rounded" width={'100%'} height={'30px'} />
                                <Skeleton animation={"wave"} variant="rounded" width={'100%'} height={'30px'} />
                                <Skeleton animation={"wave"} variant="rounded" width={'100%'} height={'30px'} />
                            </Stack>
                            :
                            <>
                                {
                                    ShippingCost.length === 0 && Courier.name === '' ? null :
                                        ShippingCost.length === 0 ?
                                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                <Icon fontSize={'small'} color={'error'}>error</Icon>
                                                <Typography color={'error'} variant={'body2'}>Pengiriman ke alamatmu tidak tersedia untuk jasa pengiriman ini.</Typography>
                                            </Stack>
                                            :
                                            <RadioGroup value={SelectedCourier} onChange={onCourier}>
                                                {
                                                    ShippingCost.map((item: any, index: number) => (
                                                        <FormControlLabel key={index} value={item.service} control={<Radio />} label={item.service} />
                                                    ))
                                                }
                                            </RadioGroup>
                                }
                            </>
                    }
                    {
                        ShippingCost.length === 0 ? null :
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                <Typography variant="body2">Layanan Reguler</Typography>
                                <Typography variant="body2" fontWeight={'600'}>Rp{CostValue.service !== '' ? FormatCurrency(CostValue.cost[0].value) : '-'}</Typography>
                            </Stack>
                    }
                    {
                        ShippingCost.length === 0 ? null :
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                <Typography variant="body2">Estimasi Pengiriman</Typography>
                                <Typography variant="body2" fontWeight={'600'}>{CostValue.service !== '' ? CostValue.cost[0].etd : '-'} hari</Typography>
                            </Stack>
                    }
                </Stack>
                <Stack direction={'column'} gap={2}>
                    <Typography variant="body1">Metode Pembayaran</Typography>
                    <Typography variant="body2" fontWeight={'600'}>Metode Pembayaran -</Typography>
                    <Stack
                        direction={"row"}
                        gap={1}
                        alignItems={'center'}
                        justifyContent={"space-between"}
                        sx={{ padding: 1, borderRadius: 1, border: '1px solid #999', cursor: 'pointer', backgroundColor: Courier.code.length === 0 ? '#ddd' : 'white' }}
                        onClick={() => Courier.code.length === 0 ? {} : setPaymentModal(true)}
                    >
                        <Typography variant="body2" fontWeight={'400'} color={Courier.code.length === 0 ? '#999' : '#000'}>{Payment.name === '' ? 'Pilih Pembayaran' : Payment.name}</Typography>
                        <Icon fontSize={'small'} sx={{ color: '#999' }}>chevron_right</Icon>
                    </Stack>
                </Stack>
                <Stack direction={'column'} gap={2}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant="body1">Kode Kupon</Typography>
                        <Typography style={{ cursor: 'pointer' }} onClick={() => setVoucherModal(true)} variant="body2" color={Colors.primary}>Lihat Semua</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                        <TextField
                            type="text"
                            placeholder="Masukkan Kode Kupon..."
                            size="small"
                            sx={{ bgcolor: "white", width: "70%" }}
                            InputProps={{ sx: { borderRadius: 1 } }}
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                            value={Voucher}
                            onChange={(text) => setVoucher(text.target.value)}
                        />
                        <Button
                            onClick={() => GetCurrentPrice(Voucher, 'voucher')}
                            sx={{ width: "28%", backgroundColor: Colors.primary }}
                            color={"secondary"}
                            variant="contained"
                        >{VoucherLoading ? <CircularProgress color={'inherit'} size={20} /> : 'Submit'}</Button>
                    </Stack>
                </Stack>
                <Stack direction={'column'} gap={1}>
                    <Stack direction={"row"} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="body2" color={'#000'}>SubTotal Produk</Typography>
                        <Typography variant="body1" color={'#000'}>{CurrentPrice.subtotal_text}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="body2" color={'#000'}>Biaya Ongkir</Typography>
                        <Typography variant="body1" color={'#000'}>{CurrentPrice.delivery_fee_text}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="body2" color={'#000'}>Biaya Layanan</Typography>
                        <Typography variant="body1" color={'#000'}>{CurrentPrice.service_fee_text}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="body2" color={'#000'}>Biaya Pembayaran</Typography>
                        <Typography variant="body1" color={'#000'}>{CurrentPrice.payment_fee_text}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="body2" color={'#000'}>PPN</Typography>
                        <Typography variant="body1" color={'#000'}>{CurrentPrice.vat_text}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="body2" color={'#000'}>Pajak</Typography>
                        <Typography variant="body1" color={'#000'}>{CurrentPrice.tax_text}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="body2" color={'#000'}>Diskon Voucher</Typography>
                        <Typography variant="body1" color={'#000'}>-{CurrentPrice.discount_text}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="h6" color={Colors.primary}><b>Total</b></Typography>
                        <Typography variant="h6" color={Colors.primary}><b>{CurrentPrice.total_text}</b></Typography>
                    </Stack>
                    <br />
                    <Button
                        onClick={onSubmit}
                        sx={{ width: "100%", backgroundColor: Colors.primary }}
                        color={"secondary"}
                        variant="contained"
                        disabled={SubmitLoading || Address.id.length === 0 || CostValue.service.length === 0 || Payment.name.length === 0}
                    >
                        <Stack direction={"row"} alignItems={'center'} gap={1}>
                            {
                                SubmitLoading ?
                                    <CircularProgress color={'secondary'} size={20} />
                                    :
                                    <Icon fontSize={'small'} sx={{ color: '#fff' }}>verified_user_outlined</Icon>
                            }
                            <Typography variant="button" color={'#fff'}>Proses Pembayaran</Typography>
                        </Stack>
                    </Button>
                </Stack>
            </Stack>
            <AddressModal
                isOpen={onAddressModal}
                onClose={() => setAddressModal(false)}
                result={(value: any) => setAddress(value)}
            />
            <ShippingModal
                isOpen={onShippingModal}
                onClose={() => setShippingModal(false)}
                result={(value: any) => GetCurrentPrice(value, 'courier')}
            />
            <PaymentModal
                isOpen={onPaymentModal}
                onClose={() => setPaymentModal(false)}
                result={(value: any) => GetCurrentPrice(value, 'payment')}
            />
            <VoucherModal
                isOpen={onVoucherModal}
                onClose={() => setVoucherModal(false)}
                result={(value: any) => GetCurrentPrice(value, 'voucher')}
            />
        </>
    )
}