import { Button, Icon, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import secureLocalStorage from 'react-secure-storage'
import { toast } from 'react-toastify'
import { HTTPDeleteWishList } from '../../apis/wishlist'
import { Images } from '../../assets/images'
import { setProductDetail } from '../../stores/data/product'
import { CENTER, Colors, Truncate } from '../../utils/colors'

export const FavoriteTable = (param: { data: any[], getData: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onDetail = (id: any, slug: string) => {
        dispatch(setProductDetail({ data: { id } }))
        navigate(`/product/detail/${slug}`)
    }

    const onDelete = async (id: any) => {
        try {
            await HTTPDeleteWishList({ id, token })
            param.getData()
        } catch (error) {
            console.log(error)
            toast.error('Terjadi Kesalahan!')
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" sx={{ fontWeight: '700' }}>Nama Produk</TableCell>
                        <TableCell align="center" sx={{ fontWeight: '700' }}>Harga Satuan</TableCell>
                        <TableCell align="center" sx={{ fontWeight: '700' }}>Detail Produk</TableCell>
                        <TableCell align="center" sx={{ fontWeight: '700' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {param.data.map((item: any, index: number) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell sx={{ maxWidth: '25vw' }}>
                                <Stack direction={"row"} gap={2}>
                                    <img
                                        width={'60px'}
                                        height={'60px'}
                                        src={item.product.galleries.length === 0 ? Images.noImageProduct : item.product.galleries[0].image_path}
                                        alt=""
                                        style={{ width: '20%', aspectRatio: 1, objectFit: 'cover' }}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = Images.noImageProduct;
                                        }}
                                    />
                                    <Typography variant="body2">{item.product.name_id}</Typography>
                                </Stack>
                            </TableCell>
                            <TableCell align="center">{item.product.display_price}</TableCell>
                            <TableCell align="center">
                                <div style={{ width: '100%', ...CENTER }}>
                                    <div
                                        onClick={() => onDetail(item.product.id, item.product.slug)}
                                        style={{
                                            width: '10vw',
                                            ...CENTER,
                                            padding: '8px 15px',
                                            backgroundColor: Colors.primary,
                                            borderRadius: 50,
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Typography variant={'button'} color={'#fff'}>Detail Produk</Typography>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell align="center">
                                <IconButton onClick={() => onDelete(item.id)}>
                                    <Icon fontSize='medium' color='disabled'>close</Icon>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export const FavoriteList = (param: { data: any[], getData: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onDetail = (id: any, slug: string) => {
        dispatch(setProductDetail({ data: { id } }))
        navigate(`/product/detail/${slug}`)
    }

    const onDelete = async (id: any) => {
        try {
            await HTTPDeleteWishList({ id, token })
            param.getData()
        } catch (error) {
            console.log(error)
            toast.error('Terjadi Kesalahan!')
        }
    }

    return (
        <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: 10, border: '1px solid #999' }}>
            {
                param.data.map((item: any, index: number) => (
                    <Stack direction={"row"} alignItems={'flex-start'} gap={2} padding={2} key={index}>
                        <img
                            src={item.product.galleries.length === 0 ? Images.noImageProduct : item.product.galleries[0].image_path}
                            alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = Images.noImageProduct;
                            }}
                            width={'60px'}
                            height={'60px'}
                        />
                        <Stack direction={'column'} gap={1}>
                            <Typography sx={{ ...Truncate, WebkitLineClamp: 3 }} variant="body2">{item.product.name_id}</Typography>
                            <Typography variant={'body1'} fontWeight={'600'} color={'#000'}>{item.product.display_price}</Typography>
                            <Stack direction={'row'} gap={1} justifyContent={'flex-end'} alignItems={'center'} height={'35px'}>
                                <Button onClick={() => onDetail(item.product.id, item.product.slug)} variant={"contained"} color={"secondary"} sx={{ backgroundColor: Colors.primary, height: '100%' }}>
                                    <Typography variant="caption" color="#fff">Detail</Typography>
                                </Button>
                                <div onClick={() => onDelete(item.id)} style={{ height: '100%', ...CENTER, aspectRatio: 1, backgroundColor: Colors.error, borderRadius: 5, cursor: 'pointer' }}>
                                    <Icon fontSize={'small'} sx={{ color: '#fff' }}>delete</Icon>
                                </div>
                            </Stack>
                        </Stack>
                    </Stack>
                ))
            }
        </div>
    )
}