import { Icon, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import secureLocalStorage from 'react-secure-storage'
import { HTTPDeleteCart, HTTPUpdateCart } from '../../apis/cart'
import { Images } from '../../assets/images'
import { CENTER, Truncate } from '../../utils/colors'
import { FormatCurrency } from '../../utils/utils'

export const CartTable = (param: { data: any, quantity: any, getData: any }) => {
    const token = secureLocalStorage.getItem('token') as string

    const NewPrice = async (id: string, qty: number) => {
        try {
            await HTTPUpdateCart({
                token,
                cart_id: id,
                quantity: qty
            })
            param.quantity()
        } catch (error) {
            console.log(error)
        }
    }

    const onDelete = async (id: string) => {
        try {
            await HTTPDeleteCart({
                token,
                cart_id: id,
            })
            param.getData()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" sx={{ fontWeight: '700', whiteSpace: 'nowrap' }}>Nama Produk</TableCell>
                        <TableCell align="center" sx={{ fontWeight: '700', whiteSpace: 'nowrap' }}>Harga Satuan</TableCell>
                        <TableCell align="center" sx={{ fontWeight: '700', whiteSpace: 'nowrap' }}>Jumlah Barang</TableCell>
                        <TableCell align="center" sx={{ fontWeight: '700', whiteSpace: 'nowrap' }}>Total Harga</TableCell>
                        <TableCell align="center" sx={{ fontWeight: '700', whiteSpace: 'nowrap' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {param.data.map((item: any, index: number) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell sx={{ maxWidth: isMobile ? '100%' : '25vw' }}>
                                <Stack direction={"row"} gap={2} alignItems={'center'}>
                                    {
                                        item.product.galleries.length === 0 ?
                                            <img src={Images.noImageProduct} width={50} height={50} alt="" />
                                            :
                                            <img src={item.product.galleries[0].image_path} width={50} height={50} alt="" />
                                    }
                                    <Typography variant="body2" sx={{ ...Truncate, WebkitLineClamp: 3 }}>{item.product.name_id}</Typography>
                                </Stack>
                            </TableCell>
                            <TableCell align="center">Rp{FormatCurrency(item.price)}</TableCell>
                            <TableCell align="center">
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Stack
                                        direction={"row"}
                                        alignItems={'center'}
                                        justifyContent={'space-around'}
                                        sx={{
                                            border: '1px solid #999',
                                            borderRadius: 1,
                                            width: isMobile ? '80%' : '50%'
                                        }}
                                    >
                                        <Icon fontSize={'medium'} onClick={() => NewPrice(item.id, item.quantity - 1 < 1 ? 1 : item.quantity - 1)} sx={{ color: '#999', cursor: 'pointer' }}>remove</Icon>
                                        <Typography variant="body1">{item.quantity}</Typography>
                                        <Icon fontSize={'medium'} onClick={() => NewPrice(item.id, item.quantity + 1)} sx={{ color: '#999', cursor: 'pointer' }}>add</Icon>
                                    </Stack>
                                </div>
                            </TableCell>
                            <TableCell align="center">Rp{FormatCurrency(item.price * item.quantity)}</TableCell>
                            <TableCell align="center">
                                <IconButton onClick={() => onDelete(item.id)}>
                                    <Icon fontSize='small' color='disabled'>close</Icon>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}