import { Button, Dialog, DialogContent, Divider, Icon, IconButton, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { CENTER, Colors } from '../../utils/colors';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Images } from '../../assets/images';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { HTTPGetVouchers } from '../../apis/voucher';
import moment from 'moment';
import { LoaderList } from '../utils/skeletons'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SKList = [
    'Pengiriman Reguler atau Hemat dengan min.belanja RP0 dan potongan 10RB.',
    'Pengiriman Reguler, Hemat, atau Instant - 2 Jam dengan min.belanja 30RB dan potongan 20RB untuk pengiriman dalam Pulau Jawa.',
    'Pengiriman Reguler, Hemat, atau Instant - 2 Jam dengan min.belanja 30RB dan potongan 30RB untuk pengiriman luar Pulau Jawa.',
    'Pengiriman Kargo dengan min.belanja 80RB dan potongan 20RB.',
    'Pengiriman Reguler atau Hemat dengan min.belanja 80RB dan potongan 30RB untuk pengiriman dalam Pulau Jawa.',
    'Pengiriman Reguler atau Hemat dengan min.belanja 80RB dan potongan 40RB untuk pengiriman luar Pulau Jawa.',
    'Pengiriman Reguler atau Hemat dengan min.belanja 150RB dan potongan 40RB untuk pengiriman dalam Pulau Jawa.',
    'Pengiriman Instant - 2 Jam dengan min.belanja 1JT dan potongan 50RB.',
    'Pengiriman Kargo dengan min.belanja 1JT dan potongan 100RB.',
    'Pengiriman Kargo dengan min.belanja 3JT dan potongan 250RB.',
]

export const VoucherModal = (param: { isOpen: boolean, onClose: any, result: any }) => {
    const [expanded, setExpanded] = React.useState<string[]>([]);
    const [isLoading, setLoading] = React.useState(true)
    const [VoucherData, setVoucherData] = React.useState<any>({})

    const GetVouchers = async () => {
        setLoading(true)
        try {
            const result = await HTTPGetVouchers()
            setVoucherData(result.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan Server!')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            if (param.isOpen === true) {
                await GetVouchers()
            }
        }

        Initial().then().catch()
    }, [param.isOpen])

    const onTerms = (nodeId: string) => {
        if (expanded.length === 0 || expanded[0] !== nodeId) {
            setExpanded([nodeId])
        } else {
            setExpanded([])
        }
    }

    const CopyCode = (code: string) => {
        navigator.clipboard.writeText(code.toUpperCase())
        toast.info('Kode Voucher telah disalin!')
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            scroll='body'
            onClose={param.onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '90vw' : '50vw' } }}
        >
            <DialogContent>
                <Stack direction={"column"} gap={3} width={'100%'}>
                    <Stack direction={'row'} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                        <Typography variant="h6" fontWeight={'600'}>Pilih Voucher Belanja</Typography>
                        <IconButton onClick={param.onClose}>
                            <Icon fontSize={"medium"} sx={{ color: Colors.disable }}>close</Icon>
                        </IconButton>
                    </Stack>
                    <Typography variant="body1" sx={{ width: '100%', textAlign: isMobile ? 'left' : 'right' }}>{VoucherData.length} Total Voucher Untuk Anda</Typography>
                    <Divider />
                    {
                        isLoading ?
                            <LoaderList />
                            :
                            <Stack direction={"column"} divider={<Divider />} gap={1}>
                                {
                                    VoucherData.map((item: any, index: number) => (
                                        <Stack direction={"column"} gap={2} key={index}>
                                            <Stack direction={isMobile ? 'column' : 'row'} alignItems={'center'} justifyContent={isMobile ? 'flex-start' : "space-between"} gap={isMobile ? 2 : 0}>
                                                <Stack direction={'row'} alignItems={'center'} gap={isMobile ? 1 : 2}>
                                                    <div style={{ width: isMobile ? '30%' : '15%', aspectRatio: 1, backgroundColor: '#f2f2f2', ...CENTER }}>
                                                        <img src={Images.logo} alt="" style={{ width: '90%', height: 'auto', objectFit: 'contain' }} />
                                                    </div>
                                                    <Stack onClick={() => { param.result(item.code); param.onClose() }} direction={'column'} gap={1} sx={{ '&:hover': { color: Colors.primary }, color: '#000', cursor: 'pointer' }}>
                                                        <Typography variant={'body1'} sx={{ color: 'inherit' }}>{item.name}</Typography>
                                                        <Typography variant={'body2'} fontWeight={'300'} sx={{ color: '#000' }}>S/D {moment(item.end_period).format('DD-MM-YYYY')}</Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={isMobile ? 'row' : 'column'} alignItems={isMobile ? 'center' : "flex-end"} gap={isMobile ? 1 : 2} justifyContent={isMobile ? 'space-between' : 'flex-end'} width={isMobile ? '100%' : undefined}>
                                                    <Stack direction={isMobile ? 'row-reverse' : 'row'} alignItems={'center'} gap={1}>
                                                        <Typography variant={'body1'} fontWeight={"600"} color={Colors.primary} textTransform={'uppercase'}>{item.code}</Typography>
                                                        <Icon onClick={() => CopyCode(item.code)} fontSize={'medium'} sx={{ color: Colors.primary, cursor: 'pointer' }}>content_copy</Icon>
                                                    </Stack>
                                                    <Stack direction={'row'} alignItems={'center'} gap={isMobile ? 0 : 1} onClick={() => onTerms(item)} sx={{ cursor: 'pointer' }}>
                                                        <Typography variant={'body1'} color={Colors.warning}>{'S&K'}</Typography>
                                                        <Icon fontSize={'medium'} sx={{ color: Colors.warning }}>expand_less</Icon>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                            <TreeView expanded={expanded}>
                                                <TreeItem nodeId={item}>
                                                    <Stack direction={'column'} gap={2}>
                                                        <Stack direction={'column'} gap={1}>
                                                            <Typography variant={'body1'} fontWeight={'600'}>Masa Berlaku</Typography>
                                                            <Typography variant={'body1'}>{moment(item.start_period).format('DD MMM YYYY HH:mm')} - {moment(item.end_period).format('DD MMM YYYY HH:mm')}</Typography>
                                                        </Stack>
                                                        <Stack direction={'column'} gap={1}>
                                                            <Typography variant={'body1'} fontWeight={'600'}>Promosi</Typography>
                                                            <Stack direction={'column'}>
                                                                <Typography variant={'body1'}>Voucher Gratis Ongkir ini berlaku sesuai ketentuan berikut:</Typography>
                                                                {
                                                                    SKList.map((item, index) => (
                                                                        <Stack direction={'row'} key={index} gap={1}>
                                                                            <Typography variant={'body1'}>{(index + 1) + '.'}</Typography>
                                                                            <Typography variant={'body1'}>{item}</Typography>
                                                                        </Stack>
                                                                    ))
                                                                }
                                                            </Stack>
                                                        </Stack>
                                                        {/* <Stack direction={'column'} gap={1}>
                                                            <Typography variant={'body1'} fontWeight={'600'}>Produk</Typography>
                                                            <Typography variant={'body1'}>Produk Kategori Fashion dan Kecantikan di Toko Gratis Ongkir XTRA.</Typography>
                                                        </Stack> */}
                                                        <Stack direction={'column'} gap={1}>
                                                            <Typography variant={'body1'} fontWeight={'600'}>Metode Pembayaran</Typography>
                                                            <Typography variant={'body1'}>Semua Metode Pembayaran</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </TreeItem>
                                            </TreeView>
                                        </Stack>
                                    ))
                                }
                            </Stack>
                    }
                </Stack>
                <div style={{ width: '100%', ...CENTER, marginTop: 20 }}>
                    <Button onClick={param.onClose} variant="contained" color="secondary" sx={{ backgroundColor: Colors.primary }}>
                        <Typography variant="body1" color="#fff">Selesai</Typography>
                    </Button>
                </div>
            </DialogContent>
        </Dialog >
    )
}