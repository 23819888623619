import * as React from 'react';
import { Button, ButtonProps, CircularProgress, Divider, Icon, IconButton, Stack, TextField, Typography } from "@mui/material"
import { Icons } from "../../assets/icons"
import { CENTER, Colors } from "../../utils/colors"
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { HTTPForgotPassword, HTTPLogin, HTTPRegister } from '../../apis/auth';
import { useDispatch } from 'react-redux';
import { setRegisterTemp } from '../../stores/data/login';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import $ from 'jquery';
import { isMobile } from 'react-device-detect';

const SubmitButton = styled(Button)<ButtonProps>(() => ({
    color: '#fff',
    backgroundColor: Colors.primary,
    '&:hover': {
        backgroundColor: '#6d437a',
    },
}));

export const LoginSection = ({ setSection }: any) => {
    const [isShowPassword, setShowPassword] = React.useState(false)
    const navigate = useNavigate()
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [isLoginError, setLoginError] = React.useState(false)
    const [LoginErrorText, setLoginErrorText] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)

    const onSignin = async () => {
        setLoginError(false)
        if (email.length === 0 || password.length === 0) {
            setLoginError(true)
            setLoginErrorText('Harap masukkan email & password pada kolom diatas!')
        } else {
            setLoading(true)
            try {
                const result = await HTTPLogin({ email, password })
                secureLocalStorage.setItem('token', result.data.data.token)
                secureLocalStorage.setItem('refresh_token', result.data.data.refresh_token)
                localStorage.setItem('isLogin', 'true')
                navigate('/')
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setLoginError(true)
                setLoginErrorText('Terjadi Kesalahan! Periksa kembali form di atas! Dan pastikan bahwa pengguna sudah terdaftar!')
                console.log(error)
            }
        }
    }

    const onGoogleSignin = async () => {
        try {
            const link: string = String(process.env.REACT_APP_API_URL)
            window.location.href = `${process.env.REACT_APP_BE_URL}/customer/social_login/google`
        } catch (error) {
            console.log(error)
            toast.error('Terjadi Kesalahan ketika Login!')
        }
    }

    return (
        <Stack direction={'column'} gap={4} style={{ padding: '10%' }}>
            <div style={{ width: '100%', textAlign: isMobile ? undefined : 'center' }}>
                {
                    isMobile ?
                        <Button onClick={() => navigate('/')} variant={"text"} color={"secondary"} sx={{ borderColor: Colors.disable, marginBottom: 3 }}>
                            <Stack direction={'row'} alignItems={"center"} gap={1}>
                                <Icon fontSize={'small'} sx={{ color: Colors.disable }}>arrow_back</Icon>
                                <Typography variant={"body1"} fontWeight={'600'} color={Colors.disable}>Kembali</Typography>
                            </Stack>
                        </Button>
                        : null
                }
                <h2 style={{ color: '#000', margin: 0, textAlign: 'center' }}>Masuk Sekarang</h2>
                <span style={{ color: '#000', textAlign: 'center' }}>Belum punya akun Angelo Skin?
                    <span
                        onClick={() => setSection('register')}
                        style={{
                            color: Colors.primary,
                            fontWeight: '600',
                            marginLeft: 5,
                            cursor: 'pointer'
                        }}>Daftar</span>
                </span>
            </div>
            <Stack direction={"column"} gap={2}>
                <TextField
                    type="text"
                    placeholder="Email"
                    size="small"
                    value={email}
                    onChange={(text) => setEmail(text.target.value)}
                    sx={{ bgcolor: "white", width: "100%" }}
                    InputProps={{ sx: { borderRadius: 1 } }}
                />
                <TextField
                    placeholder="Password"
                    size="small"
                    value={password}
                    onChange={(text) => setPassword(text.target.value)}
                    sx={{ bgcolor: "white", width: "100%" }}
                    type={isShowPassword ? 'text' : 'password'}
                    onKeyDown={(e) => { if (e.keyCode === 13) onSignin() }}
                    InputProps={{
                        sx: { borderRadius: 1 },
                        endAdornment: (
                            <IconButton onClick={() => setShowPassword(!isShowPassword)}>
                                <Icon sx={{ fontSize: 25, color: '#ababab' }}>{isShowPassword ? 'visibility_off' : 'visibility'}</Icon>
                            </IconButton>
                        )
                    }}
                />
                {
                    isLoginError ?
                        <Typography variant={"caption"} color={Colors.error}>{LoginErrorText}</Typography>
                        :
                        null
                }
            </Stack>
            <Stack direction={'column'}>
                <SubmitButton onClick={onSignin} variant='contained' disabled={isLoading}>
                    {
                        isLoading ?
                            <CircularProgress color="secondary" size={20} />
                            :
                            'SELANJUTNYA'
                    }
                </SubmitButton>
                <Typography
                    variant={"subtitle2"}
                    color={Colors.primary}
                    sx={{ marginTop: 2, cursor: 'pointer' }}
                    onClick={() => setSection('forgot')}
                >Lupa Password?</Typography>
            </Stack>
            <Divider>
                <span style={{ color: '#949494', width: '20%', textAlign: 'center' }}>ATAU</span>
            </Divider>
            <Button onClick={onGoogleSignin} color={'primary'} sx={{ borderColor: '#999' }} variant={'outlined'}>
                <Stack direction={"row"} gap={1}>
                    <img src={Icons.google} style={{ width: 20, height: 20, objectFit: 'contain' }} alt="" />
                    <span style={{ color: '#000' }}>Google</span>
                </Stack>
            </Button>
        </Stack>
    )
}

export const RegisterSection = ({ setSection }: any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [email, setEmail] = React.useState('')
    const [isEmailError, setEmailError] = React.useState(false)
    const [EmailErrorText, setEmailErrorText] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)

    const onSignup = async () => {
        setEmailError(false)
        if (email.length === 0) {
            setEmailError(true)
            setEmailErrorText('Harap masukkan email pada kolom diatas!')
        } else if (!email.toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
            setEmailError(true)
            setEmailErrorText('Format Email tidak sesuai!')
        } else {
            setLoading(true)
            try {
                const result = await HTTPRegister({ email: email.toLowerCase() })
                dispatch(setRegisterTemp({ data: { email: email.toLowerCase(), token: result.data.data.token, type: 'register' } }))
                navigate('/register')
                toast.success('Kode OTP telah berhasil terkirim ke Email yang terdaftar!')
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setEmailError(true)
                setEmailErrorText('Terjadi kesalahan ketika mandaftarkan email!')
                toast.info("Pastikan email belum terdaftar pada akun ini!")
                console.log(error)
            }
        }
    }

    const onGoogleSignin = async () => {
        try {
            window.location.href = `${process.env.REACT_APP_BE_URL}/customer/social_login/google`
        } catch (error) {
            console.log(error)
            toast.error('Terjadi Kesalahan ketika Login!')
        }
    }

    return (
        <Stack direction={'column'} gap={4} style={{ padding: '10%' }}>
            <div style={{ width: '100%', textAlign: isMobile ? undefined : 'center' }}>
                {
                    isMobile ?
                        <Button onClick={() => navigate('/')} variant={"text"} color={"secondary"} sx={{ borderColor: Colors.disable, marginBottom: 3 }}>
                            <Stack direction={'row'} alignItems={"center"} gap={1}>
                                <Icon fontSize={'small'} sx={{ color: Colors.disable }}>arrow_back</Icon>
                                <Typography variant={"body1"} fontWeight={'600'} color={Colors.disable}>Kembali</Typography>
                            </Stack>
                        </Button>
                        : null
                }
                <h2 style={{ color: '#000', margin: 0, textAlign: 'center' }}>Daftar Sekarang</h2>
                <span style={{ color: '#000', textAlign: 'center' }}>Sudah punya akun Angelo Skin?
                    <span
                        onClick={() => setSection('login')}
                        style={{
                            color: Colors.primary,
                            fontWeight: '600',
                            marginLeft: 5,
                            cursor: 'pointer'
                        }}>Masuk</span>
                </span>
            </div>
            <Stack direction={"column"} gap={1}>
                <TextField
                    type="text"
                    placeholder="Email"
                    size="small"
                    sx={{ bgcolor: "white", width: "100%" }}
                    InputProps={{ sx: { borderRadius: 1 } }}
                    onKeyDown={(e) => { if (e.keyCode === 13) onSignup() }}
                    value={email}
                    onChange={(text) => setEmail(text.target.value)}
                />
                {
                    isEmailError ?
                        <Typography variant={"caption"} color={Colors.error}>{EmailErrorText}</Typography>
                        :
                        null
                }
            </Stack>
            <SubmitButton disabled={isLoading} onClick={onSignup} variant='contained'>
                {
                    isLoading ?
                        <CircularProgress color="secondary" size={20} />
                        :
                        'SELANJUTNYA'
                }
            </SubmitButton>
            <Divider>
                <span style={{ color: '#949494', width: '20%', textAlign: 'center' }}>ATAU</span>
            </Divider>
            <Button onClick={onGoogleSignin} color={'primary'} sx={{ borderColor: '#999' }} variant={'outlined'}>
                <Stack direction={"row"} gap={1}>
                    <img src={Icons.google} style={{ width: 20, height: 20, objectFit: 'contain' }} alt="" />
                    <span style={{ color: '#000' }}>Google</span>
                </Stack>
            </Button>
            <small style={{ color: '#000', textAlign: 'center' }}>Dengan mendaftar, anda setuju dengan
                <span style={{ color: Colors.primary, marginLeft: 5 }}>Syarat, Ketentuan dan Kebijakan dari AngeloSkin</span>
                {' & '}
                <span style={{ color: Colors.primary }}>Kebijakan Privasi</span>
            </small>
        </Stack>
    )
}

export const ForgotPasswordSection = ({ setSection }: any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [email, setEmail] = React.useState('')
    const [isEmailError, setEmailError] = React.useState(false)
    const [EmailErrorText, setEmailErrorText] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)

    const onForgotPassword = async () => {
        setEmailError(false)
        if (email.length === 0) {
            setEmailError(true)
            setEmailErrorText('Harap masukkan email pada kolom diatas!')
        } else {
            setLoading(true)
            try {
                const result = await HTTPForgotPassword({ email })
                dispatch(setRegisterTemp({ data: { email: email, token: result.data.data.token.token, type: 'forgot-password' } }))
                navigate('/forgot-password')
                toast.success('Kode OTP telah berhasil terkirim ke Email yang terdaftar!')
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setEmailError(true)
                setEmailErrorText('Terjadi kesalahan ketika mandaftarkan email!')
                toast.info("Pastikan email belum terdaftar pada akun ini!")
                console.log(error)
            }
        }
    }

    const onGoogleSignin = async () => {
        try {
            const link: string = String(process.env.REACT_APP_API_URL)
            window.location.href = `${process.env.REACT_APP_BE_URL}/customer/social_login/google`
        } catch (error) {
            console.log(error)
            toast.error('Terjadi Kesalahan ketika Login!')
        }
    }

    return (
        <Stack direction={'column'} gap={3} style={{ padding: '10%' }}>
            <Stack direction={'row'} gap={2} alignItems="center" sx={{ cursor: 'pointer' }} onClick={() => setSection('login')}>
                <Icon fontSize={'medium'} sx={{ color: Colors.disable }}>arrow_back</Icon>
                <Typography variant={"body1"} color={Colors.disable}>KEMBALI</Typography>
            </Stack>
            <h2 style={{ color: '#000', margin: 0, textAlign: 'center' }}>Lupa Password</h2>
            <Stack direction={"column"} gap={1}>
                <TextField
                    type="text"
                    placeholder="Email"
                    size="small"
                    sx={{ bgcolor: "white", width: "100%" }}
                    InputProps={{ sx: { borderRadius: 1 } }}
                    onKeyDown={(e) => { if (e.keyCode === 13) onForgotPassword() }}
                    value={email}
                    onChange={(text) => setEmail(text.target.value)}
                />
                {
                    isEmailError ?
                        <Typography variant={"caption"} color={Colors.error}>{EmailErrorText}</Typography>
                        :
                        null
                }
            </Stack>
            <SubmitButton disabled={isLoading} onClick={onForgotPassword} variant='contained'>
                {
                    isLoading ?
                        <CircularProgress color="secondary" size={20} />
                        :
                        'SELANJUTNYA'
                }
            </SubmitButton>
            <Divider>
                <span style={{ color: '#949494', width: '20%', textAlign: 'center' }}>ATAU</span>
            </Divider>
            <Button onClick={onGoogleSignin} color={'primary'} sx={{ borderColor: '#999' }} variant={'outlined'}>
                <Stack direction={"row"} gap={1}>
                    <img src={Icons.google} style={{ width: 20, height: 20, objectFit: 'contain' }} alt="" />
                    <span style={{ color: '#000' }}>Google</span>
                </Stack>
            </Button>
            <small style={{ color: '#000', textAlign: 'center' }}>Dengan mendaftar, anda setuju dengan
                <span style={{ color: Colors.primary, marginLeft: 5 }}>Syarat, Ketentuan dan Kebijakan dari AngeloSkin</span>
                {' & '}
                <span style={{ color: Colors.primary }}>Kebijakan Privasi</span>
            </small>
        </Stack>
    )
}