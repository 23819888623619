import { Box, Button, Divider, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { ThreeDots } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { HTTPGetFAQs } from '../../apis/faq'
import { Images } from '../../assets/images'
import Footer from '../../components/footer'
import { Header } from '../../components/header'
import NavigationBar from '../../components/navigationBar'
import { CENTER, Colors } from '../../utils/colors'

const SideData = [
    { id: '1', label: 'Brand apa saya yang kami jual?' },
    { id: '2', label: 'Bagaimana cara mereturn barang kepada kami?' },
    { id: '3', label: 'Bagaimana cara melakukan klaim diskon?' },
]

const FAQScreen = () => {
    const [activeMenu, setActiveMenu] = React.useState('')
    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(true)
    const [FAQList, setFAQList] = React.useState([])
    const [SelectedQuestion, setSelectedQuestion] = React.useState('')
    const [SelectedAnswer, setSelectedAnswer] = React.useState('')

    const setItem = (value: any) => {
        setActiveMenu(value.id)
        setSelectedQuestion(value.question_id)
        setSelectedAnswer(value.answer_id)
    }

    const GetFAQs = async () => {
        setLoading(true)
        try {
            const result = await HTTPGetFAQs()
            setFAQList(result.data.data)
            setItem(result.data.data[0])
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan Server!')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            await GetFAQs()
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <Header title="Frequently Ask Questions" />
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                {
                    isLoading ?
                        <div style={{ width: '100%', overflow: isMobile ? 'auto' : 'none', backgroundColor: '#fff', padding: '10% 0' }}>
                            <Stack direction={'column'} gap={0} alignItems={'center'} justifyContent={'center'}>
                                <img src={Images.logo} style={{ width: '15vw', height: 'auto', objectFit: 'contain' }} alt="" />
                                <ThreeDots
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color={Colors.primary}
                                    ariaLabel="three-dots-loading"
                                    visible={true}
                                />
                            </Stack>
                        </div>
                        :
                        <Stack direction={isMobile ? 'column' : 'row'} sx={{ width: '90%', paddingY: 5 }} justifyContent={'space-between'} gap={3}>
                            <div style={{ width: '20%' }}>
                                <Stack direction={'column'} gap={2} divider={<Divider />} width={'100%'}>
                                    {
                                        FAQList.map((item: any) => (
                                            <Box key={item.id} style={{ color: activeMenu === item.id ? Colors.primary : '#000', width: '100%' }}>
                                                <Button
                                                    color={"inherit"}
                                                    variant={'text'}
                                                    onClick={() => setItem(item)}
                                                    sx={{ width: '100%', textAlign: 'left' }}
                                                >
                                                    <Stack alignItems={'center'} gap={2} direction={'row'} width={'100%'}>
                                                        <Icon fontSize={'medium'}
                                                            sx={{
                                                                color: 'inherit',
                                                                display: activeMenu === item.id ? 'block' : 'none',
                                                                transition: 'all .3s'
                                                            }}>arrow_forward</Icon>
                                                        <Typography
                                                            variant={'h6'}
                                                            textAlign={'left'}
                                                            color={'inherit'}
                                                            width={'100%'}
                                                            fontWeight={'600'}
                                                            sx={{ transition: 'all .3s', textTransform: 'capitalize' }}
                                                        >{item.question_id}</Typography>
                                                    </Stack>
                                                </Button>
                                            </Box>
                                        ))
                                    }
                                </Stack>
                            </div>
                            <div style={{ width: isMobile ? '100%' : '78%' }}>
                                <Stack direction={"column"} gap={2}>
                                    <Typography variant={'h6'} fontWeight={'600'}>{SelectedQuestion}</Typography>
                                    <div dangerouslySetInnerHTML={{ __html: SelectedAnswer }} style={{ textAlign: 'justify' }}></div>
                                </Stack>
                            </div>
                        </Stack>
                }
            </div>
            <Footer />
        </div>
    )
}

export default FAQScreen;