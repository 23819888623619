import { Button, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import secureLocalStorage from 'react-secure-storage'
import { toast } from 'react-toastify'
import { HTTPDeleteAddress, HTTPGetAddress } from '../../apis/address'
import Footer from '../../components/footer'
import { Header } from '../../components/header'
import NavigationBar from '../../components/navigationBar'
import { AddAddressModal } from '../../components/profile/addressSection'
import { DeleteModal } from '../../components/profile/deleteSection'
import { ProfileNavbar, ProfileSideBar } from '../../components/profile/sidebar'
import { AddressSkeleton } from '../../components/skeleton/profile'
import { CENTER, Colors, Truncate } from '../../utils/colors'

const ProfileAddressScreen = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [isAddress, setAddress] = React.useState(false)
    const [AddressData, setAddressData] = React.useState([])
    const [isLoading, setLoading] = React.useState(false)
    const [init, setInit] = React.useState(false)
    const [TempData, setTempData] = React.useState<any>({})
    const [type, setType] = React.useState('add')
    const [isDelete, setDelete] = React.useState(false)

    const GetAddress = async () => {
        setLoading(true)
        try {
            const result = await HTTPGetAddress({ token })
            setAddressData(result.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan')
            console.log(error)
        }
    }

    React.useEffect(() => {
        async function APIList() {
            try {
                await GetAddress()
            } catch (error) {
                console.log(error)
            }
        }
        APIList().catch().then()
    }, [init])

    const onSetData = (data: any, action: any) => {
        setTempData(data)
        if (action === 'update') {
            setType('update')
            setAddress(true)
        } else {
            setDelete(true)
        }
    }

    const onDelete = async () => {
        setDelete(false)
        try {
            await HTTPDeleteAddress({ address_id: TempData.id })
            toast.success('Berhasil menghapus alamat!')
            await GetAddress()
        } catch (error) {
            console.log(error)
            toast.error('Terjadi Kesalahan saat menghapus alamat!')
        }
    }

    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <Header title="Profil Saya" />
            {
                isMobile ?
                    <div style={{ width: '100%' }}>
                        <ProfileNavbar activeMenu={2} />
                    </div>
                    : null
            }
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={'row'} sx={{ width: '90%', paddingY: 5 }} justifyContent={'space-between'}>
                    {
                        isMobile ? null :
                            <div style={{ width: '20%' }}>
                                <ProfileSideBar activeMenu={2} />
                            </div>
                    }
                    <div
                        style={{
                            width: isMobile ? '100%' : '78%',
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            border: isMobile ? undefined : '1px solid #999',
                            boxShadow: isMobile ? undefined : '0px 0px 10px 8px rgba(0,0,0,0.1)'
                        }}
                    >
                        <Stack direction={'column'} gap={3} padding={isMobile ? 0 : 3}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="h5" fontWeight={'600'}>Alamat Saya</Typography>
                                <Button onClick={() => { setType('add'); setAddress(true) }} color={'secondary'} variant={'outlined'}>Tambah Alamat</Button>
                            </Stack>
                            <Divider />
                            {
                                isLoading ?
                                    <AddressSkeleton />
                                    :
                                    <>
                                        {
                                            AddressData.length === 0 ?
                                                <Stack direction={'column'} gap={1} alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100%'}>
                                                    <Typography variant={'h6'} fontWeight={'600'} color={Colors.disable} textAlign={'center'}>TIDAK ADA DATA</Typography>
                                                    <Typography variant={'body1'} color={Colors.disable} textAlign={'center'} whiteSpace={'pre-line'}>Silahkan Tambah data alamat {'\n'} terlebih dahulu.</Typography>
                                                </Stack>
                                                :
                                                <Grid container spacing={2}>
                                                    {
                                                        AddressData.map((item: any, index: number) => (
                                                            <Grid item md={4} xs={12} key={index}>
                                                                <Stack
                                                                    direction={"column"}
                                                                    gap={1}
                                                                    sx={{
                                                                        borderRadius: 1,
                                                                        padding: 2,
                                                                        border: '1px solid #999',
                                                                        transition: 'all .3s',
                                                                        '&:hover': {
                                                                            border: `1px solid ${Colors.primary}`,
                                                                            transition: 'all .3s',
                                                                            boxShadow: `0px 0px 10px 3px ${Colors.primary + 50}`
                                                                        }
                                                                    }}
                                                                >
                                                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                                        <Typography variant="h6" fontWeight={'600'}>{item.label}</Typography>
                                                                        {
                                                                            item.is_main === 1 ?
                                                                                <Typography
                                                                                    variant="caption"
                                                                                    sx={{ color: '#fff', backgroundColor: Colors.primary, padding: '2px 5px', borderRadius: 2 }}
                                                                                >Utama</Typography>
                                                                                :
                                                                                null
                                                                        }
                                                                    </Stack>
                                                                    <Typography variant="body1" sx={{ ...Truncate, WebkitLineClamp: 3, width: '100%', }}>
                                                                        {item.address + ', ' + item.subdistrict.name + ', ' + item.city.type + ' ' + item.city.name + ', ' + item.province.name + ', ' + item.postal_code}
                                                                    </Typography>
                                                                    <div>
                                                                        <Typography variant="caption" color={'#999'}>No. Telepon</Typography>
                                                                        <Typography variant="body1">{'+62 ' + item.phone}</Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography variant="caption" color={'#999'}>Email</Typography>
                                                                        <Typography variant="body1">{item.customer.email}</Typography>
                                                                    </div>
                                                                    <Stack direction={'row'} alignItems={"center"} gap={2}>
                                                                        <Button onClick={() => onSetData(item, 'update')} color={'secondary'} variant={'text'}>Ubah Alamat</Button>
                                                                        <Button onClick={() => onSetData(item, 'delete')} color={'error'} variant={'text'}>Hapus Alamat</Button>
                                                                    </Stack>
                                                                </Stack>
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                        }
                                    </>
                            }
                        </Stack>
                    </div>
                </Stack>
            </div >
            <Footer />
            <AddAddressModal
                isOpen={isAddress}
                onClose={() => setAddress(false)}
                getData={GetAddress}
                type={type}
                data={TempData}
            />
            <DeleteModal
                isOpen={isDelete}
                onClose={() => setDelete(false)}
                label={TempData.label}
                onDelete={onDelete}
            />
        </div >
    )
}

export default ProfileAddressScreen;