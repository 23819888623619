export const Colors = {
  primary: "#8e54a0",
  secondary: "#00DFA2",
  success: "#24bb33",
  error: "#FF0060",
  warning: "#f7c604",
  inherit: "#ffe4e3",
  info: "#0068ff",
  disable: "#6A6A6A",
};

export const CENTER = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const Truncate = {
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
};