import { Box, Button, Dialog, DialogContent, Divider, Grid, Icon, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { HTTPGetAddress } from '../../apis/address';
import { Icons } from '../../assets/icons';
import { CENTER, Colors, Truncate } from '../../utils/colors';
import { LoaderCard } from '../utils/skeletons';

const Banks = [
    { id: 1, icon: Icons.bca, label: 'BCA' },
    { id: 2, icon: Icons.mandiri, label: 'Mandiri' },
    { id: 3, icon: Icons.bni, label: 'BNI' },
    { id: 4, icon: Icons.bri, label: 'BRI' },
    { id: 5, icon: Icons.bsi, label: 'Syariah Indonesia' },
    { id: 6, icon: Icons.permata, label: 'Permata' },
]

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const AddressModal = (param: { isOpen: boolean, onClose: any, result: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(true)
    const [AddressData, setAddressData] = React.useState([])
    const [SelectedItem, setSelectedItem] = React.useState<any>({})

    const GetAddress = async () => {
        setLoading(true)
        try {
            const result = await HTTPGetAddress({ token })
            setAddressData(result.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan')
            console.log(error)
        }
    }

    React.useEffect(() => {
        async function APIList() {
            try {
                if (param.isOpen === true) {
                    await GetAddress()
                }
            } catch (error) {
                console.log(error)
            }
        }
        APIList().catch().then()
    }, [param.isOpen])

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '90vw' : '60vw' } }}
        >
            <DialogContent>
                <Stack direction={"column"} gap={3} divider={<Divider />}>
                    <Typography variant={"body1"} fontWeight={'600'}>Alamat Saya</Typography>
                    <Stack direction={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'flex-start' : 'space-between'} gap={isMobile ? 3 : 0}>
                        <Typography variant={"body1"} fontWeight={'600'} width={isMobile ? '100%' : '30%'}>Pilih Kartu:</Typography>
                        {
                            isLoading ?
                                <LoaderCard />
                                :
                                <Grid container spacing={2}>
                                    {
                                        AddressData.map((item: any, index: number) => (
                                            <Grid item md={6} xs={12} key={index}>
                                                <Stack
                                                    direction={"column"}
                                                    gap={1}
                                                    sx={{
                                                        borderRadius: 1,
                                                        padding: 2,
                                                        border: SelectedItem.id === item.id ? `1px solid ${Colors.primary}` : '1px solid #999',
                                                        boxShadow: SelectedItem.id === item.id ? `0px 0px 10px 3px ${Colors.primary + 50}` : '0px',
                                                        transition: 'all .3s',
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            border: `1px solid ${Colors.primary}`,
                                                            transition: 'all .3s',
                                                            boxShadow: `0px 0px 10px 3px ${Colors.primary + 50}`
                                                        }
                                                    }}
                                                    onClick={() => setSelectedItem(item)}
                                                >
                                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                        <Typography variant="body1" fontWeight={'600'}>{item.label}</Typography>
                                                        {
                                                            item.is_main === 1 ?
                                                                <Typography
                                                                    variant="caption"
                                                                    sx={{ color: '#fff', backgroundColor: Colors.primary, padding: '2px 5px', borderRadius: 2 }}
                                                                >Utama</Typography>
                                                                :
                                                                null
                                                        }
                                                    </Stack>
                                                    <Typography variant="body1" sx={{ ...Truncate, WebkitLineClamp: 3, width: '100%', }}>
                                                        {item.address + ', ' + item.subdistrict.name + ', ' + item.city.type + ' ' + item.city.name + ', ' + item.province.name + ', ' + item.postal_code}
                                                    </Typography>
                                                    <div>
                                                        <Typography variant="caption" color={'#999'}>No. Telepon</Typography>
                                                        <Typography variant="body1">{'+62 ' + item.phone}</Typography>
                                                    </div>
                                                    <div>
                                                        <Typography variant="caption" color={'#999'}>Email</Typography>
                                                        <Typography variant="body1">{item.customer.email}</Typography>
                                                    </div>
                                                </Stack>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                        }
                    </Stack>
                    <Box sx={{ width: '100%', ...CENTER, color: Colors.primary }}>
                        <Button
                            onClick={() => {
                                param.result(SelectedItem)
                                param.onClose()
                            }}
                            variant='contained'
                            color={'secondary'}
                            sx={{ backgroundColor: Colors.primary, width: isMobile ? '90%' : '50%' }}
                        >
                            <Typography variant={'body1'} color={"#fff"}>Pilih Alamat</Typography>
                        </Button>
                    </Box>
                </Stack>
            </DialogContent >
        </Dialog >
    )
}