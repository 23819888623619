import {
  Box,
  Button,
  Divider,
  Icon,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Colors, Truncate } from "../../utils/colors";
import { styled, useTheme } from "@mui/material/styles";
import { CategoryTree } from "../products/categoryTree";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { isMobile } from "react-device-detect";
import { LoaderList } from "../utils/skeletons";
import { useNavigate } from "react-router";

const ArticleList = {
  node: "1",
  label: "Kategori Artikel",
  child: [
    { node: "2", label: "Tips & Trick", qty: "10" },
    { node: "3", label: "Make Up Tutorial", qty: "12" },
    { node: "4", label: "Berita", qty: "4" },
    { node: "5", label: "Hair Care Tips", qty: "6" },
    { node: "6", label: "Healthy Tips", qty: "20" },
    { node: "7", label: "Skincare Tips", qty: "10" },
  ],
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: "#000",
  [`& .${treeItemClasses.content}`]: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    [`& .${treeItemClasses.label}`]: {
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
  },
})) as unknown as typeof TreeItem;

const StyledTreeItem = React.forwardRef(function StyledTreeItem(
  props: any,
  ref: React.Ref<HTMLLIElement>
) {
  const theme = useTheme();
  const {
    bgColor,
    color,
    labelInfo,
    labelText,
    colorForDarkMode,
    bgColorForDarkMode,
    ...other
  } = props;

  const styleProps: any = {
    "--tree-view-color":
      theme.palette.mode !== "dark" ? color : colorForDarkMode,
    "--tree-view-bg-color":
      theme.palette.mode !== "dark" ? bgColor : bgColorForDarkMode,
  };
  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingY: 1.5,
            paddingX: 0.5,
            pr: 0,
          }}
        >
          <Typography variant={"body1"} sx={{ fontWeight: "600", flexGrow: 1 }}>
            {labelText}
          </Typography>
        </Box>
      }
      style={styleProps}
      {...other}
      ref={ref}
    />
  );
});

export const ArticleSideBar = (param: {
  data: any[];
  getData: any;
  searchData: any;
}) => {
  const [sortType, setSortType] = React.useState("latest");
  const [Search, setSearch] = React.useState("");

  const SortArticle = (label: string) => {
    setSortType(label);
    param.getData({ sort_by: label });
  };

  const SearchArticle = (text: string) => {
    setSearch(text);
  };

  React.useEffect(() => {
    setTimeout(() => {
      param.searchData({ keywords: Search });
    }, 1000);
  }, [Search]);

  return (
    <Stack direction={"column"} gap={2} divider={<Divider />} width={"100%"}>
      {isMobile ? null : (
        <TextField
          type="text"
          placeholder="Cari Article"
          size="small"
          sx={{ bgcolor: "white", width: "100%" }}
          value={Search}
          onChange={(e) => SearchArticle(e.target.value)}
          InputProps={{
            sx: { borderRadius: 3 },
            startAdornment: (
              <InputAdornment position="start">
                <Icon fontSize="small" sx={{ color: "#999" }}>
                  search
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      )}
      <TreeView
        defaultExpanded={["1"]}
        defaultCollapseIcon={
          <Icon sx={{ color: "#909090" }} fontSize={"small"}>
            {" "}
            arrow_drop_down
          </Icon>
        }
        defaultExpandIcon={
          <Icon sx={{ color: "#909090" }} fontSize={"small"}>
            {" "}
            arrow_right
          </Icon>
        }
        sx={{ flexGrow: 1, width: "100%", overflowY: "auto" }}
      >
        <StyledTreeItem nodeId={"1"} labelText={"Urutan Artikel"}>
          <Stack
            direction={"row"}
            paddingY={2}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ color: "#999" }}
          >
            <Button
              onClick={() => SortArticle("latest")}
              variant={sortType === "latest" ? "contained" : "outlined"}
              color={sortType === "latest" ? "secondary" : "inherit"}
              sx={{
                backgroundColor:
                  sortType === "latest" ? Colors.primary : "#fff",
                width: "49%",
                borderColor: "#999",
              }}
            >
              Terbaru
            </Button>
            <Button
              onClick={() => SortArticle("oldest")}
              variant={sortType === "oldest" ? "contained" : "outlined"}
              color={sortType === "oldest" ? "secondary" : "inherit"}
              sx={{
                backgroundColor:
                  sortType === "oldest" ? Colors.primary : "#fff",
                width: "49%",
                borderColor: "#999",
              }}
            >
              Terlama
            </Button>
          </Stack>
        </StyledTreeItem>
      </TreeView>
      <CategoryTree
        ListData={{
          node: "1",
          label: "Kategori",
          child: param.data,
        }}
        getData={(id: any) => param.getData(id)}
      ></CategoryTree>
    </Stack>
  );
};

export const ArticleListSideBar = (param: {
  data: any[];
  isLoading: boolean;
}) => {
  // const navigate = useNavigate();

  return (
    <Stack direction={"column"} gap={2} divider={<Divider />} width={"100%"}>
      {isMobile ? null : (
        <TextField
          type="text"
          placeholder="Cari Article"
          size="small"
          sx={{ bgcolor: "white", width: "100%" }}
          InputProps={{
            sx: { borderRadius: 3 },
            startAdornment: (
              <InputAdornment position="start">
                <Icon fontSize="small" sx={{ color: "#999" }}>
                  search
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      )}
      <Stack direction={"column"} gap={2}>
        <Typography variant="h6" fontWeight={"600"}>
          Artikel Lainnya
        </Typography>
        {param.isLoading ? (
          <LoaderList />
        ) : (
          <>
            {param.data.map((item, index) => (
              <a
                href={`/article/detail/${item.slug}`}
                style={{ textDecoration: "none" }}
              >
                <Paper
                  key={index}
                  elevation={1}
                  sx={{
                    border: "1px solid #999",
                    borderRadius: 1,
                    width: "100%",
                    transition: "all .3s",
                    "&:hover": {
                      border: `1px solid ${Colors.primary}`,
                      transition: "all .3s",
                      boxShadow: `0px 0px 10px 3px ${Colors.primary + 50}`,
                    },
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    sx={{ padding: 1 }}
                    // onClick={() => {
                    //   navigate(`/article/detail/${item.slug}`);
                    //   window.location.reload();
                    // }}
                  >
                    <img
                      src={item.thumbnail}
                      alt=""
                      style={{
                        width: "30%",
                        aspectRatio: 1,
                        objectFit: "cover",
                      }}
                    />
                    <Stack
                      direction={"column"}
                      gap={1}
                      width={"68%"}
                      paddingX={1}
                    >
                      <Typography variant="caption" color={"#999"}>
                        {item.last_updated}
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={"600"}
                        sx={{ WebkitLineClamp: 3, ...Truncate }}
                      >
                        {item.title_id}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </a>
            ))}
          </>
        )}
      </Stack>
    </Stack>
  );
};
