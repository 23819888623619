import { Button, CircularProgress, Divider, FormControl, FormControlLabel, Icon, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import Footer from '../../components/footer'
import { Header } from '../../components/header'
import NavigationBar from '../../components/navigationBar'
import { ProfileNavbar, ProfileSideBar } from '../../components/profile/sidebar'
import { CENTER, Colors } from '../../utils/colors'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isMobile } from 'react-device-detect'
import { ImageDrozoneModal } from '../../components/profile/imageDropzone'
import { HTTPUpdateProfile, HTTPUpdateProfilePicture } from '../../apis/profile'
import secureLocalStorage from 'react-secure-storage'
import moment from 'moment'
import { toast } from 'react-toastify'
import { RootState } from '../../stores/rootReducer'
import { useDispatch, useSelector } from 'react-redux'
import { Images } from '../../assets/images'
import { setProfileData } from '../../stores/data/pofile'

const ProfileAccountScreen = () => {
    const dispatch = useDispatch()
    const TOKEN = secureLocalStorage.getItem('token')
    const ProfileRedux = useSelector((state: RootState) => state.profile_data.data)

    const [isLoading, setLoading] = React.useState(false)
    const [BirthDate, setBirthDate] = React.useState<any>(ProfileRedux.dob === null ? null : moment(ProfileRedux.dob));
    const [onUpdateImage, setUpdateImage] = React.useState(false)
    const [NameValue, setNameValue] = React.useState(ProfileRedux.name === null ? '' : ProfileRedux.name)
    const [UsernameValue, setUsernameValue] = React.useState(ProfileRedux.username === null ? '' : ProfileRedux.username)
    const [isUsernameEdit, setUsernameEdit] = React.useState(false)
    const [EmailValue, setEmailValue] = React.useState(ProfileRedux.email === null ? '' : ProfileRedux.email)
    const [isEmailEdit, setEmailEdit] = React.useState(false)
    const [PhoneValue, setPhoneValue] = React.useState(ProfileRedux.phone_number === null ? '' : ProfileRedux.phone_number)
    const [isPhoneEdit, setPhoneEdit] = React.useState(false)
    const [GenderValue, setGenderValue] = React.useState(ProfileRedux.gender === null ? '' : ProfileRedux.gender.toString());

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGenderValue((event.target as HTMLInputElement).value);
    };

    const onUpdateProfile = async () => {
        setLoading(true)
        if (UsernameValue.length === 0) {
            toast.error('Username harap di isi!')
        } else if (NameValue.length === 0) {
            toast.error('Nama harap di isi!')
        } else if (EmailValue.length === 0) {
            toast.error('Email harap di isi!')
        } else if (PhoneValue.length === 0) {
            toast.error('Nomor Telepon harap di isi!')
        } else if (GenderValue.length === 0) {
            toast.error('Jenis Kelamin harap di pilih!')
        } else if (BirthDate === null) {
            toast.error('Tanggal Lahir harap di isi!')
        } else {
            try {
                const result = await HTTPUpdateProfile({
                    username: UsernameValue,
                    dob: moment(BirthDate).format('YYYY-MM-DD'),
                    email: EmailValue.toLowerCase(),
                    name: NameValue,
                    phone_number: PhoneValue,
                    token: TOKEN,
                    gender: parseInt(GenderValue)
                })
                toast.success('Berhasil mengubah Profil')
                dispatch(setProfileData({ data: result.data.data }))
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
                toast.error("Terjadi Kesalahan! Pastikan form telah terisi")
            }
        }
    }

    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <Header title="Profil Saya" />
            {
                isMobile ?
                    <div style={{ width: '100%' }}>
                        <ProfileNavbar activeMenu={1} />
                    </div>
                    : null
            }
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={'row'} sx={{ width: '90%', paddingY: 5 }} justifyContent={'space-between'}>
                    {
                        isMobile ? null :
                            <div style={{ width: '20%' }}>
                                <ProfileSideBar activeMenu={1} />
                            </div>
                    }
                    <div
                        style={{
                            width: isMobile ? '100%' : '78%',
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            border: isMobile ? undefined : '1px solid #999',
                            boxShadow: isMobile ? undefined : '0px 0px 10px 8px rgba(0,0,0,0.1)'
                        }}
                    >
                        <Stack direction={'column'} gap={3} padding={isMobile ? 0 : 3}>
                            <div>
                                <Typography variant="h5" fontWeight={'600'} gutterBottom>Profil Saya</Typography>
                                <Typography variant="h6">Kelola informasi pribadi Anda</Typography>
                            </div>
                            <Divider />
                            <Stack direction={isMobile ? "column" : "row"} alignItems='center' justifyContent={'space-between'} width={'100%'}>
                                <Typography variant="subtitle1" color={"#999"} width={isMobile ? '100%' : '25%'}>Foto Profil:</Typography>
                                <div style={{ width: isMobile ? '100%' : '75%' }}>
                                    <Stack direction={isMobile ? 'column' : "row"} gap={2}>
                                        <img
                                            src={
                                                ProfileRedux.profile_picture === undefined ||
                                                    ProfileRedux.profile_picture === null ?
                                                    Images.noImage : ProfileRedux.profile_picture
                                            }
                                            alt="" style={{ borderRadius: 5, width: isMobile ? '40%' : '25%', aspectRatio: 1, objectFit: 'cover' }}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = Images.noImageProduct;
                                            }}
                                        />
                                        <Stack direction={"column"} sx={{ color: '#999' }}>
                                            <Typography variant="subtitle2" color={"inherit"}>Rasio: 1:1</Typography>
                                            <Typography variant="subtitle2" color={"inherit"}>Ukuran gambar: maks 1MB</Typography>
                                            <Typography variant="subtitle2" color={"inherit"}>Format gambar: .JPEG, .PNG</Typography>
                                            <Button onClick={() => setUpdateImage(true)} color={'inherit'} variant={'outlined'} sx={{ marginTop: 3 }}>Pilih Gambar</Button>
                                        </Stack>
                                    </Stack>
                                </div>
                            </Stack>
                            <Stack direction={isMobile ? "column" : "row"} alignItems='center' justifyContent={'space-between'} width={'100%'}>
                                <Typography variant="subtitle1" color={"#999"} width={isMobile ? '100%' : '25%'}>Username:</Typography>
                                <div style={{ width: isMobile ? '100%' : '75%' }}>
                                    {
                                        isUsernameEdit ?
                                            <Stack direction={"row"} gap={2} alignItems={'center'}>
                                                <TextField
                                                    type="text"
                                                    placeholder="Ubah Username"
                                                    size="small"
                                                    sx={{ bgcolor: "white" }}
                                                    InputProps={{ sx: { borderRadius: 1 } }}
                                                    value={UsernameValue}
                                                    onChange={(text) => setUsernameValue(text.target.value)}
                                                />
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                    color={Colors.primary}
                                                    onClick={() => setUsernameEdit(false)}
                                                >Simpan</Typography>
                                            </Stack>
                                            :
                                            <Stack direction={"row"} gap={2} alignItems={'center'}>
                                                {
                                                    UsernameValue.length === 0 ?
                                                        <Typography variant="subtitle1" color={"#000"}>
                                                            <i>Belum Terisi</i>
                                                        </Typography>
                                                        :
                                                        <Typography variant="subtitle1" color={"#000"}>{UsernameValue.replace(/\s/g, '')}</Typography>
                                                }
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                    color={Colors.primary}
                                                    onClick={() => setUsernameEdit(true)}
                                                >Ubah</Typography>
                                            </Stack>
                                    }
                                </div>
                            </Stack>
                            <Stack direction={isMobile ? "column" : "row"} alignItems='center' justifyContent={'space-between'} width={'100%'}>
                                <Typography variant="subtitle1" color={"#999"} width={isMobile ? '100%' : '25%'}>Nama:</Typography>
                                <div style={{ width: isMobile ? '100%' : '75%' }}>
                                    <TextField
                                        type="text"
                                        placeholder="Belum Terisi"
                                        size="small"
                                        sx={{ bgcolor: "white", width: isMobile ? '100%' : "50%" }}
                                        InputProps={{ sx: { borderRadius: 1 } }}
                                        value={NameValue}
                                        onChange={(text) => setNameValue(text.target.value)}
                                    />
                                </div>
                            </Stack>
                            <Stack direction={isMobile ? "column" : "row"} alignItems='center' justifyContent={'space-between'} width={'100%'}>
                                <Typography variant="subtitle1" color={"#999"} width={isMobile ? '100%' : '25%'}>Email:</Typography>
                                <div style={{ width: isMobile ? '100%' : '75%' }}>
                                    {
                                        isEmailEdit ?
                                            <Stack direction={"row"} gap={2} alignItems={'center'}>
                                                <TextField
                                                    type="email"
                                                    placeholder="Ubah Email"
                                                    size="small"
                                                    sx={{ bgcolor: "white" }}
                                                    InputProps={{ sx: { borderRadius: 1 } }}
                                                    value={EmailValue}
                                                    onChange={(text) => setEmailValue(text.target.value)}
                                                />
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                    color={Colors.primary}
                                                    onClick={() => setEmailEdit(false)}
                                                >Simpan</Typography>
                                            </Stack>
                                            :
                                            <Stack direction={"row"} gap={2}>
                                                {
                                                    EmailValue.length === 0 ?
                                                        <Typography variant="subtitle1" color={"#000"}>
                                                            <i>Belum Terisi</i>
                                                        </Typography>
                                                        :
                                                        <Typography variant="subtitle1" color={"#000"}>
                                                            {EmailValue[0] + EmailValue[1] + "*".repeat(EmailValue.length - 12) + '@' + EmailValue.split("@")[1]}
                                                        </Typography>
                                                }
                                                <Typography
                                                    onClick={() => setEmailEdit(true)}
                                                    variant="subtitle1"
                                                    sx={{
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer'
                                                    }}
                                                    color={Colors.primary}
                                                >Ubah</Typography>
                                            </Stack>
                                    }
                                </div>
                            </Stack>
                            <Stack direction={isMobile ? "column" : "row"} alignItems='center' justifyContent={'space-between'} width={'100%'}>
                                <Typography variant="subtitle1" color={"#999"} width={isMobile ? '100%' : '25%'}>No. Telepon:</Typography>
                                <div style={{ width: isMobile ? '100%' : '75%' }}>
                                    {
                                        isPhoneEdit ?
                                            <Stack direction={"row"} gap={2} alignItems={'center'}>
                                                <TextField
                                                    type="number"
                                                    placeholder="Ubah Nomor Telepon"
                                                    size="small"
                                                    sx={{ bgcolor: "white" }}
                                                    InputProps={{ sx: { borderRadius: 1 } }}
                                                    value={PhoneValue}
                                                    onChange={(text) => setPhoneValue(text.target.value)}
                                                />
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                    color={Colors.primary}
                                                    onClick={() => setPhoneEdit(false)}
                                                >Simpan</Typography>
                                            </Stack>
                                            :
                                            <Stack direction={"row"} gap={2}>
                                                {
                                                    PhoneValue.length === 0 ?
                                                        <Typography variant="subtitle1" color={"#000"}>
                                                            <i>Belum terisi</i>
                                                        </Typography>
                                                        :
                                                        <Typography variant="subtitle1" color={"#000"}>
                                                            {PhoneValue[0] + PhoneValue[1] + "*".repeat(PhoneValue.length - 3) + PhoneValue.slice(-1)}
                                                        </Typography>
                                                }
                                                <Typography
                                                    onClick={() => setPhoneEdit(true)}
                                                    variant="subtitle1"
                                                    sx={{
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer'
                                                    }}
                                                    color={Colors.primary}
                                                >Ubah</Typography>
                                            </Stack>
                                    }
                                </div>
                            </Stack>
                            <Stack direction={isMobile ? "column" : "row"} alignItems='center' justifyContent={'space-between'} width={'100%'}>
                                <Typography variant="subtitle1" color={"#999"} width={isMobile ? '100%' : '25%'}>Jenis Kelamin:</Typography>
                                <div style={{ width: isMobile ? '100%' : '75%' }}>
                                    <FormControl>
                                        <RadioGroup
                                            value={GenderValue}
                                            onChange={handleChange}
                                            color={'secondary'}
                                        >
                                            <Stack direction={isMobile ? 'column' : "row"} gap={isMobile ? 0 : 2}>
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio color={'secondary'} />}
                                                    label={<Typography variant="subtitle1">Laki - Laki</Typography>}
                                                />
                                                <FormControlLabel
                                                    value="2"
                                                    control={<Radio color={'secondary'} />}
                                                    label={<Typography variant="subtitle1">Wanita</Typography>}
                                                />
                                            </Stack>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </Stack>
                            <Stack direction={isMobile ? "column" : "row"} justifyContent={'space-between'} width={'100%'}>
                                <Typography variant="subtitle1" color={"#999"} width={isMobile ? '100%' : '25%'}>Tanggal Lahir:</Typography>
                                <div style={{ width: isMobile ? '100%' : '75%' }}>
                                    <Stack direction={"column"} gap={2} alignItems={'flex-start'} sx={{ color: Colors.primary, width: isMobile ? '100%' : '50%' }}>
                                        <DatePicker
                                            value={BirthDate}
                                            onChange={(date) => setBirthDate(date)}
                                            sx={{ bgcolor: "white", borderRadius: 1, width: '100%' }}
                                        />
                                        <Button disabled={isLoading} onClick={onUpdateProfile} color={'secondary'} variant={'contained'} sx={{ backgroundColor: Colors.primary }}>
                                            <Stack direction={"row"} gap={1} padding={1}>
                                                {
                                                    isLoading ?
                                                        <CircularProgress color="secondary" size={20} />
                                                        :
                                                        <Icon fontSize='small' sx={{ color: '#fff' }}>save</Icon>
                                                }
                                                <Typography variant="body2" color={"#fff"}>Simpan</Typography>
                                            </Stack>
                                        </Button>
                                    </Stack>
                                </div>
                            </Stack>
                        </Stack>
                    </div>
                </Stack >
            </div >
            <ImageDrozoneModal isOpen={onUpdateImage} onClose={() => setUpdateImage(false)} />
            <Footer />
        </div >
    )
}

export default ProfileAccountScreen;