import { Button, CircularProgress, Divider, Icon, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography, IconButton } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import secureLocalStorage from 'react-secure-storage'
import { toast } from 'react-toastify'
import { HTTPGetCarts } from '../apis/cart'
import { HTTPDeleteWishList } from '../apis/wishlist'
import { Images } from '../assets/images'
import { CENTER, Colors, Truncate } from '../utils/colors'
import { FormatCurrency } from '../utils/utils'
import { LogoutModal } from './profile/logout'

export const FavoriteMenu = (param: { isOpen: any, onClose: any, isLoading: boolean, data: any[], getData: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const navigate = useNavigate();
    const onDelete = async (id: any) => {
        try {
            await HTTPDeleteWishList({ id, token })
            param.getData()
        } catch (error) {
            console.log(error)
            toast.error('Terjadi Kesalahan!')
        }
    }

    return (
        <Menu
            anchorEl={param.isOpen}
            open={Boolean(param.isOpen)}
            onClose={param.onClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >

            <Stack direction={'column'} divider={<Divider />} width={'25vw'}>
                {
                    param.isLoading ?
                        <div style={{ width: '100%', height: '100%', ...CENTER }}>
                            <CircularProgress color={"secondary"} size={40} />
                        </div>
                        :
                        <>
                            {
                                param.data.length === 0 ?
                                    <Stack direction={'column'} gap={2} width={'100%'} alignItems={'center'} marginY={5}>
                                        <Icon sx={{ fontSize: 50, color: '#999' }}>favorite_border</Icon>
                                        <Typography variant="h6" color="#999" textAlign={'center'}>Tidak ada Favorit</Typography>
                                    </Stack>
                                    :
                                    param.data.map((item: any, index: number) => (
                                        <MenuItem key={index} sx={{ whiteSpace: 'normal', width: '100%' }}>
                                            <Stack direction={'row'} alignItems={'center'} gap={1} width={'100%'}>
                                                <img
                                                    src={item.product.galleries.length === 0 ? Images.noImageProduct : item.product.galleries[0].image_path}
                                                    alt=""
                                                    style={{ width: '20%', aspectRatio: 1, objectFit: 'cover' }}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = Images.noImageProduct;
                                                    }}
                                                />
                                                <Typography variant="body2" color="#000" sx={{ ...Truncate, WebkitLineClamp: 3, width: '65%', fontWeight: '300' }}>{item.product.name_id}</Typography>
                                                <IconButton onClick={() => onDelete(item.id)}>
                                                    <Icon fontSize={"small"} sx={{ color: Colors.disable }}>close</Icon>
                                                </IconButton>
                                            </Stack>
                                        </MenuItem>
                                    ))
                            }
                        </>
                }
                <Button onClick={() => navigate('/favorite')} variant={'text'} color={'secondary'} sx={{ color: '#000', width: '100%' }}>Tampilkan Semua</Button>
            </Stack>
        </Menu>
    )
}

export const CartMenu = (param: { isOpen: any, onClose: any, isLoading: boolean, data: any[] }) => {
    const navigate = useNavigate();

    const TotalPrice = () => {
        let total = 0
        for (let i = 0; i < param.data.length; i++) {
            const item = param.data[i];
            total += item.total_price
        }
        return FormatCurrency(total)
    }

    return (
        <Menu
            anchorEl={param.isOpen}
            open={Boolean(param.isOpen)}
            onClose={param.onClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <Stack direction={'column'} divider={<Divider />} width={'25vw'}>
                {
                    param.isLoading ?
                        <div style={{ width: '100%', height: '100%', ...CENTER }}>
                            <CircularProgress color={"secondary"} size={40} />
                        </div>
                        :
                        <>
                            {
                                param.data.length === 0 ?
                                    <Stack direction={'column'} gap={2} width={'100%'} alignItems={'center'} marginY={5}>
                                        <Icon sx={{ fontSize: 50, color: '#999' }}>local_mall</Icon>
                                        <Typography variant="h6" color="#999" textAlign={'center'}>Tidak ada Produk</Typography>
                                    </Stack>
                                    :
                                    param.data.map((item: any, index: number) => (
                                        <MenuItem key={index} sx={{ whiteSpace: 'normal', width: '100%' }}>
                                            <Stack direction={'row'} alignItems={'center'} gap={1} width={'100%'}>
                                                <img
                                                    src={item.product.galleries.length === 0 ? Images.noImageProduct : item.product.galleries[0].image_path}
                                                    alt=""
                                                    style={{ width: '20%', aspectRatio: 1, objectFit: 'cover' }}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = Images.noImageProduct;
                                                    }}
                                                />
                                                <Stack direction={"column"} gap={1} width={'70%'}>
                                                    <Typography variant="body2" color="#000" sx={{ ...Truncate, WebkitLineClamp: 3, width: '100%', fontWeight: '300' }}>{item.product.name_id}</Typography>
                                                    <Typography variant="body2" sx={{ fontWeight: '300' }} color={Colors.disable}>{item.quantity} x Rp{FormatCurrency(item.price)}</Typography>
                                                </Stack>
                                                <Icon fontSize={"small"} sx={{ color: Colors.disable, width: '10%' }}>close</Icon>
                                            </Stack>
                                        </MenuItem>
                                    )).slice(0, 3)
                            }
                        </>
                }
                <Stack direction={"column"} gap={2} sx={{ padding: 2 }}>
                    <Stack direction={"row"} gap={1} width={'100%'} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant={"body1"} color={'#000'}>TOTAL:</Typography>
                        <Typography variant={"body1"} color={'#000'} fontWeight={"600"}>Rp{TotalPrice()}</Typography>
                    </Stack>
                    <Button onClick={() => navigate('/cart')} variant={'contained'} color={'secondary'} sx={{ backgroundColor: Colors.primary, width: '100%' }}>Lihat Keranjang</Button>
                    {/* <Stack direction={"row"} gap={1} width={'100%'} alignItems={"center"} justifyContent={"space-between"}>
                        <Button onClick={() => navigate('/cart/payment')} variant={'outlined'} color={'secondary'} sx={{ width: '50%' }}>
                            <Stack direction={"row"} alignItems={'center'} gap={2}>
                                <Typography variant={"body1"} color={Colors.primary}>Checkout</Typography>
                                <Icon fontSize={"medium"} sx={{ color: Colors.primary }}>arrow_forward</Icon>
                            </Stack>
                        </Button>
                    </Stack> */}
                </Stack>
            </Stack>
        </Menu>
    )
}

export const NotificationMenu = (param: { isOpen: any, onClose: any }) => {
    const navigate = useNavigate()
    return (
        <Menu
            anchorEl={param.isOpen}
            open={Boolean(param.isOpen)}
            onClose={param.onClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <Stack direction={'column'} divider={<Divider />} width={'25vw'}>
                {
                    [1, 2, 3].map((item) => (
                        <MenuItem key={item} sx={{ whiteSpace: 'normal', width: '100%', backgroundColor: '#e2d3e7' }}>
                            <Stack direction={'row'} alignItems={'center'} gap={1} width={'100%'}>
                                <img
                                    src="https://img.freepik.com/premium-vector/gift-voucher-template-isolated-gray-background-discount-coupon-50-off-promotion-sale_97458-853.jpg"
                                    alt=""
                                    style={{ width: '20%', aspectRatio: 1, objectFit: 'cover' }}
                                />
                                <Stack direction={"column"} gap={0} width={'70%'}>
                                    <Typography variant="body1" color="#000" sx={{ ...Truncate, WebkitLineClamp: 2, width: '100%', fontWeight: '600' }}>11-11: Super Skincare Day!</Typography>
                                    <Typography variant="subtitle2" color={Colors.disable} sx={{ ...Truncate, WebkitLineClamp: 3, width: '100%', }}>Klaim Voucher Super Skincare Day Kamu, hanya untuk hari ini, klaim sekarang juga!</Typography>
                                </Stack>
                                <Icon fontSize={"small"} sx={{ color: Colors.disable, width: '10%' }}>close</Icon>
                            </Stack>
                        </MenuItem>
                    ))
                }
                <Button onClick={() => navigate('/notification')} variant={'text'} color={'secondary'} sx={{ color: '#000', width: '100%' }}>Tampilkan Semua</Button>
            </Stack>
        </Menu>
    )
}

export const ProfileMenu = (param: { isOpen: any, onClose: any }) => {
    const navigate = useNavigate();
    const [onLogout, setLogout] = React.useState(false)

    return (
        <>
            <Menu
                anchorEl={param.isOpen}
                open={Boolean(param.isOpen)}
                onClose={param.onClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => navigate('/profile/account')} sx={{ "&:hover": { color: Colors.primary, transition: "all .3s", }, transition: "all .3s" }}>
                    <ListItemIcon sx={{ color: "inherit" }}><Icon>person</Icon></ListItemIcon>
                    <ListItemText sx={{ color: "inherit" }}>Profil</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => navigate('/profile/order-history')} sx={{ "&:hover": { color: Colors.primary, transition: "all .3s", }, transition: "all .3s" }}>
                    <ListItemIcon sx={{ color: "inherit" }}><Icon>history</Icon></ListItemIcon>
                    <ListItemText sx={{ color: "inherit" }}>Riwayat Pesanan</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => { param.onClose(); setLogout(true) }} sx={{ "&:hover": { color: Colors.primary, transition: "all .3s", }, transition: "all .3s" }}>
                    <ListItemIcon sx={{ color: "inherit" }}><Icon>logout</Icon></ListItemIcon>
                    <ListItemText sx={{ color: "inherit" }}>Keluar</ListItemText>
                </MenuItem>
            </Menu>
            <LogoutModal isOpen={onLogout} onClose={() => setLogout(false)} />
        </>
    )
}

export const LanguageMenu = (param: { isOpen: any, onClose: any }) => {
    return (

        <Menu
            anchorEl={param.isOpen}
            open={Boolean(param.isOpen)}
            onClose={param.onClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem sx={{ "&:hover": { color: Colors.primary, transition: "all .3s", }, transition: "all .3s" }}>
                <ListItemText sx={{ color: "inherit" }}>Bahasa Indonesia</ListItemText>
            </MenuItem>
            <MenuItem sx={{ "&:hover": { color: Colors.primary, transition: "all .3s", }, transition: "all .3s" }}>
                <ListItemText sx={{ color: "inherit" }}>English</ListItemText>
            </MenuItem>
        </Menu>
    )
}