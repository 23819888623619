export const FormatSlug = (text: string) => {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const FormatCurrency = (amount: number) => {
  return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};
