import * as React from 'react';
import { Avatar, Divider, Icon, Rating, Stack, Typography } from "@mui/material"
import { Colors } from "../../../utils/colors"

export const ProductComment = () => {
    return (
        <Stack direction={'column'} gap={2} divider={<Divider />}>
            {
                [1, 2, 3].map((item) => (
                    <Stack direction={'row'} gap={2} key={item}>
                        <Avatar src="https://media-assets-ggwp.s3.ap-southeast-1.amazonaws.com/2023/06/fakta-yor-forger-4.jpg" alt={'Profile'}></Avatar>
                        <Stack direction={"column"} gap={1}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography fontWeight={'600'} variant={"body1"}>Riyandi16</Typography>
                                <Rating
                                    name="read-only"
                                    value={4}
                                    readOnly
                                    icon={<Icon sx={{ color: Colors.warning }} fontSize={'small'}>star</Icon>}
                                    emptyIcon={<Icon sx={{ color: '#999' }} fontSize={'small'}>star</Icon>}
                                />
                            </Stack>
                            <Typography variant={"subtitle2"} color={'#999'}>17 Mei, 2023</Typography>
                            <Typography variant={"subtitle2"}>Labore Lorem laboris mollit laboris aute mollit tempor sint duis excepteur proident cillum sit. Laborum dolor fugiat et anim culpa voluptate duis veniam eiusmod proident dolor et.</Typography>
                            {
                                item === 3 ?
                                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                                        <img src="https://s1.bukalapak.com/img/13744234003/s-463-463/data.jpeg.webp" width={60} height={60} alt="" />
                                        <img src="https://s1.bukalapak.com/img/13744234003/s-463-463/data.jpeg.webp" width={60} height={60} alt="" />
                                        <img src="https://s1.bukalapak.com/img/13744234003/s-463-463/data.jpeg.webp" width={60} height={60} alt="" />
                                    </Stack>
                                    :
                                    null
                            }
                        </Stack>
                    </Stack>
                ))
            }
        </Stack>
    )
}