import { AxiosMethod } from "../utils/interceptors";
const url = "articles";

export function HTTPGetArticles(param: {
  keywords?: string;
  category_id?: string;
  sort_by?: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/search`, {
        keywords: param.keywords,
        category_id: param.category_id,
        sort_by: param.sort_by,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetArticleDetail(param: { slug: any }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}/detail`, {
        params: {
          slug: param.slug,
        },
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetArticleCategories(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`article_categories/list`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
