import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const RegisterTempSlice = createSlice({
  name: "Register_Temp",
  initialState: {
    data: { email: "", token: "", type: "" }
  },
  reducers: {
    setRegisterTemp(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
    },
  },
});

export const { setRegisterTemp } = RegisterTempSlice.actions;
export const RegisterTempData = RegisterTempSlice.reducer;
