export const Images = {
  logo: require("./logo.png"),
  banner: require("./banner.png"),
  bagPurple: require("./bag-purple.png"),
  lockPurple: require("./lock-purple.png"),
  cert1: require("./cert_1.png"),
  cert2: require("./cert_2.png"),
  cert3: require("./cert_3.png"),
  cert4: require("./cert_4.png"),
  noImage: require('./empty-profile.png'),
  noImageProduct: require('./no-image.png'),
  waButton: require('./wa-button.png')
};
