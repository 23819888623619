import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, Divider, FormControl, FormControlLabel, Grid, Icon, IconButton, InputLabel, MenuItem, Select, Slide, Stack, TextField, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useFormik } from 'formik';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { HTTPCreateAddress, HTTPGetCities, HTTPGetDistricts, HTTPGetProvincies, HTTPUpdateAddress } from '../../apis/address';
import { CENTER, Colors } from '../../utils/colors';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const AddAddressModal = (param: {
    isOpen: boolean,
    onClose: any,
    getData: any,
    type: string,
    data: any
}) => {
    const token = secureLocalStorage.getItem('token') as string
    const [province, setProvince] = React.useState('');
    const [Provincies, setProvincies] = React.useState([])
    const [city, setCity] = React.useState('');
    const [Cities, setCities] = React.useState([])
    const [district, setDistrict] = React.useState('')
    const [Districts, setDistricts] = React.useState([])
    const [isLoading, setLoading] = React.useState(false)
    const [isMain, setMain] = React.useState(true);

    const onReset = () => {
        setProvince('')
        setProvincies([])
        setCity('')
        setCities([])
        Formik.resetForm()
    }

    const handleProvince = (item: any) => {
        setProvince(item.target.value)
        setCity('')
        setCities([])
        setDistrict('')
        setDistricts([])
        GetCities(item.target.value)
    }

    const handleCity = (item: any) => {
        setCity(item.target.value)
        setDistrict('')
        setDistricts([])
        GetDistricts(item.target.value)
    }

    const onSaves = async (values: any) => {
        setLoading(true)
        try {
            if (param.type === 'add') {
                await HTTPCreateAddress({
                    address: values.address,
                    phone: values.phone.toString(),
                    province_id: province,
                    city_id: city,
                    token,
                    label: values.label,
                    is_main: isMain === true ? 1 : 0,
                    district_id: district
                })
                toast.success('Berhasil menambahkan alamat!')
            } else {
                await HTTPUpdateAddress({
                    address_id: param.data.id,
                    address: values.address,
                    phone: values.phone.toString(),
                    province_id: province,
                    city_id: city,
                    token,
                    label: values.label,
                    is_main: isMain === true ? 1 : 0,
                    district_id: district
                })
                toast.success('Berhasil mengubah alamat!')
            }
            onReset()
            param.onClose()
            setLoading(false)
            param.getData()
        } catch (error) {
            if (param.type === 'add') {
                toast.error('Terjadi kesalahan saat menambahkan alamat!')
            } else {
                toast.error('Terjadi kesalahan saat mengubah alamat!')
            }
            setLoading(false)
            console.log(error)
        }
    }

    const Formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            label: param.type === 'update' ? param.data.label : "",
            address: param.type === 'update' ? param.data.address : "",
            phone: param.type === 'update' ? param.data.phone : "",
        },
        onSubmit: onSaves,
    });

    const GetProvincies = async () => {
        try {
            const result = await HTTPGetProvincies()
            setProvincies(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const GetCities = async (province_id: any) => {
        try {
            const result = await HTTPGetCities({ province_id })
            setCities(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const GetDistricts = async (city_id: string) => {
        try {
            const result = await HTTPGetDistricts({ city_id })
            setDistricts(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const ProvinceValue = (values: any) => {
        if (values.length === 0) {
            return <span style={{ color: "#a7a5a6" }}>Provinsi</span>;
        } else {
            const result: any = Provincies.filter(
                (value: any) => value.id === province
            );
            return <span style={{ color: "#000" }}>{result[0].name}</span>;
        }
    };

    const CityValue = (values: any) => {
        if (values.length === 0) {
            return <span style={{ color: "#a7a5a6" }}>Kota / Kab</span>;
        } else {
            const result: any = Cities.filter(
                (value: any) => value.id === city
            );
            return <span style={{ color: "#000" }}>{result[0].name}</span>;
        }
    };

    const DistrictValue = (values: any) => {
        if (values.length === 0) {
            return <span style={{ color: "#a7a5a6" }}>Kecamatan</span>;
        } else {
            const result: any = Districts.filter(
                (value: any) => value.id === district
            );
            return <span style={{ color: "#000" }}>{result[0].name}</span>;
        }
    };

    React.useEffect(() => {
        async function APIList() {
            if (param.isOpen === true) {
                await GetProvincies()
                if (param.type === 'update') {
                    setMain(param.data.is_main === 1 ? true : false)
                    setTimeout(async () => {
                        await GetCities(param.data.province.id)
                        await GetDistricts(param.data.city.id)
                        setProvince(param.data.province.id)
                        setCity(param.data.city.id)
                        setDistrict(param.data.subdistrict.id)
                    }, 500)
                }
            }
        }
        APIList().then().catch()
    }, [param.isOpen])

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '100vw' : '50vw' } }}
        >
            <DialogContent>
                <form onSubmit={Formik.handleSubmit}>
                    <Stack direction={"column"} gap={3} divider={<Divider />}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <div>
                                <Typography variant={"h6"} fontWeight={'600'} color={"#000"}>Tambah Alamat</Typography>
                                <Typography variant={"body2"} color={"#000"}>Pastikan alamat yang Anda cantumkan benar.</Typography>
                            </div>
                            <IconButton onClick={param.onClose}><Icon fontSize={"medium"} sx={{ color: Colors.disable }}>close</Icon></IconButton>
                        </Stack>
                        <Stack direction={"column"} gap={2}>
                            <Stack gap={1} direction={isMobile ? 'column' : 'row'} width={'100%'} alignItems={isMobile ? 'flex-start' : 'center'}>
                                <Typography variant={"subtitle2"} color={"#000"} width={isMobile ? '100%' : '30%'}>Label:</Typography>
                                <TextField
                                    type="text"
                                    placeholder="Kantor, Rumah, dll"
                                    size="small"
                                    sx={{ bgcolor: "white", width: isMobile ? '100%' : "70%" }}
                                    InputProps={{ sx: { borderRadius: 1 } }}
                                    name="label"
                                    value={Formik.values.label}
                                    onChange={Formik.handleChange}
                                />
                            </Stack>
                            <Stack gap={1} direction={isMobile ? 'column' : 'row'} width={'100%'} alignItems={isMobile ? 'flex-start' : 'center'}>
                                <Typography variant={"subtitle2"} color={"#000"} width={isMobile ? '100%' : '30%'}>No. Telepon:</Typography>
                                <TextField
                                    type="number"
                                    placeholder="No. Telepon"
                                    size="small"
                                    sx={{ bgcolor: "white", width: isMobile ? '100%' : "70%" }}
                                    InputProps={{ sx: { borderRadius: 1 } }}
                                    name="phone"
                                    value={Formik.values.phone}
                                    onChange={Formik.handleChange}
                                />
                            </Stack>
                            <Stack gap={1} direction={isMobile ? 'column' : 'row'} width={'100%'} alignItems={'flex-start'}>
                                <Typography variant={"subtitle2"} color={"#000"} width={isMobile ? '100%' : '30%'}>Provinsi, Kab/Kota, Kecamatan, Kode Pos</Typography>
                                <Box sx={{ width: isMobile ? '100%' : '70%' }}>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <Select
                                                labelId="province-label"
                                                MenuProps={{ sx: { height: 500 } }}
                                                sx={{ width: '100%' }}
                                                name="province_id"
                                                displayEmpty
                                                size={'small'}
                                                value={province}
                                                onChange={(val) => handleProvince(val)}
                                                renderValue={(selected: any) => ProvinceValue(selected)}
                                            >
                                                {
                                                    Provincies.map((item: any, index: number) => (
                                                        <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Select
                                                labelId="city-label"
                                                MenuProps={{ sx: { height: 500 } }}
                                                sx={{ width: '100%' }}
                                                name="city_id"
                                                value={city}
                                                disabled={province.length === 0}
                                                size={'small'}
                                                displayEmpty
                                                onChange={(val) => handleCity(val)}
                                                renderValue={(selected: any) => CityValue(selected)}
                                            >
                                                {
                                                    Cities.map((item: any, index: number) => (
                                                        <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Select
                                                displayEmpty
                                                size={'small'}
                                                disabled={city.length === 0}
                                                MenuProps={{ style: { maxHeight: 500 } }}
                                                sx={{ bgcolor: "white", width: '100%' }}
                                                inputProps={{ sx: { borderRadius: 1 } }}
                                                name={'district_id'}
                                                value={district}
                                                onChange={(e) => setDistrict(e.target.value)}
                                                renderValue={(selected: any) => DistrictValue(selected)}
                                            >
                                                {
                                                    Districts.map((item: any, index: number) => (
                                                        <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Grid>
                                        {/* <Grid item md={6} xs={12}>
                                        <TextField
                                            type="number"
                                            placeholder="Kode Post"
                                            size="small"
                                            sx={{ bgcolor: "white", width: "100%" }}
                                            InputProps={{ sx: { borderRadius: 1 } }}
                                        />
                                    </Grid> */}
                                    </Grid>
                                </Box>
                            </Stack>
                            <Stack gap={1} direction={isMobile ? 'column' : 'row'} width={'100%'} alignItems={'flex-start'}>
                                <Typography variant={"subtitle2"} color={"#000"} width={isMobile ? '100%' : '30%'}>Alamat Lengkap</Typography>
                                <TextField
                                    type="text"
                                    placeholder="Alamat Lengkap"
                                    size="small"
                                    sx={{ bgcolor: "white", width: isMobile ? '100%' : "70%" }}
                                    InputProps={{ sx: { borderRadius: 1 } }}
                                    multiline
                                    rows={5}
                                    name="address"
                                    value={Formik.values.address}
                                    onChange={Formik.handleChange}
                                />
                            </Stack>
                            {/* <Stack gap={1} direction={isMobile ? 'column' : 'row'} width={'100%'} alignItems={isMobile ? 'flex-start' : 'center'}>
                            <Typography variant={"subtitle2"} color={"#000"} width={isMobile ? '100%' : '30%'}>Detail Lainnya</Typography>
                            <TextField
                                type="text"
                                placeholder="Detail Tambahan"
                                size="small"
                                sx={{ bgcolor: "white", width: isMobile ? '100%' : "70%" }}
                                InputProps={{ sx: { borderRadius: 1 } }}
                            />
                        </Stack> */}
                            <Stack gap={1} direction={isMobile ? 'column' : 'row'} width={'100%'} alignItems={isMobile ? 'flex-start' : 'center'}>
                                <Typography variant={"subtitle2"} color={"#000"} width={isMobile ? '100%' : '30%'}>Atur Alamat</Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isMain}
                                            onChange={(val) => setMain(val.target.checked)}
                                        />}
                                    sx={{ width: isMobile ? '100%' : '70%' }}
                                    label="Atur Sebagai Alamat Utama"
                                />
                            </Stack>
                        </Stack>
                        <Box sx={{ width: '100%', ...CENTER, color: Colors.primary }}>
                            <Button disabled={isLoading} type='submit' color={'secondary'} variant={'contained'} sx={{ backgroundColor: Colors.primary }}>
                                <Stack direction={"row"} gap={1} padding={1}>
                                    {
                                        isLoading ?
                                            <CircularProgress color="secondary" size={20} />
                                            :
                                            <Icon fontSize='small' sx={{ color: '#fff' }}>save</Icon>
                                    }
                                    <Typography variant="body2" color={"#fff"}>Simpan</Typography>
                                </Stack>
                            </Button>
                        </Box>
                    </Stack>
                </form>
            </DialogContent >
        </Dialog >
    )
}