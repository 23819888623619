import { AxiosMethod } from "../utils/interceptors";
const url = "footer";

export function HTTPGetFooterAddress(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}/addresses`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetFooterSchedule(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}/operational_times`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetFooterReseller(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}/resellers`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
