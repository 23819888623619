import * as React from "react";
import {
    AppBar,
    Stack,
    Toolbar,
    Icon,
    TextField,
    InputAdornment,
    Badge,
    IconButton,
    Avatar,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Button,
} from "@mui/material";
import { CENTER, Colors } from "../utils/colors";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { Images } from "../assets/images";
import { CartMenu, FavoriteMenu, LanguageMenu, NotificationMenu, ProfileMenu } from "./navbarMenu";
import secureLocalStorage from "react-secure-storage";
import { HTTPProfile, HTTPRefreshToken } from "../apis/auth";
import { useDispatch, useSelector } from "react-redux";
import { setProfileData } from "../stores/data/pofile";
import { RootState } from "../stores/rootReducer";
import { HTTPGetCarts } from "../apis/cart";
import { HTTPGetWishLists } from "../apis/wishlist";

const NavigationBar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ProfileRedux = useSelector((state: RootState) => state.profile_data.data)
    const token = secureLocalStorage.getItem('token') as string

    const [init, setInit] = React.useState(false)
    const [isLoggedIn, setLoggedIn] = React.useState(true)
    const [Profile, setProfile] = React.useState<any>(null);
    const [Favorite, setFavorite] = React.useState<any>(null);
    const [FavoriteLoading, setFavoriteLoading] = React.useState<any>(true);
    const [FavoriteData, setFavoriteData] = React.useState<any>([]);
    const [Cart, setCart] = React.useState<any>(null);
    const [CartData, setCartData] = React.useState([]);
    const [CartLoading, setCartLoading] = React.useState(true);
    const [Notif, setNotif] = React.useState<any>(null);
    const [Language, setLanguage] = React.useState<any>(null);
    const [Search, setSearch] = React.useState('')

    const ProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => setProfile(event.currentTarget);
    const ProfileClose = () => setProfile(null);

    const FavoriteClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        setFavorite(event.currentTarget)
        setFavoriteLoading(true)
        try {
            const result = await HTTPGetWishLists({ token })
            console.log(result)
            setFavoriteData(result.data.data)
            setFavoriteLoading(false)
        } catch (error) {
            setFavoriteLoading(false)
            console.log(error)
        }
    };
    const FavoriteClose = () => setFavorite(null);

    const CartClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        setCart(event.currentTarget)
        setCartLoading(true)
        try {
            const result = await HTTPGetCarts({ token })
            setCartData(result.data.data)
            setCartLoading(false)
        } catch (error) {
            setCartLoading(false)
            console.log(error)
        }
    };
    const CartClose = () => setCart(null);

    const NotifClick = (event: React.MouseEvent<HTMLButtonElement>) => setNotif(event.currentTarget);
    const NotifClose = () => setNotif(null);

    const LanguageClick = (event: React.MouseEvent<HTMLButtonElement>) => setLanguage(event.currentTarget);
    const LanguageClose = () => setLanguage(null);

    const Auth = async () => {
        const isLogin = localStorage.getItem('isLogin')
        if (isLogin === null || isLogin === undefined) {
            localStorage.setItem('isLogin', 'false')
            setLoggedIn(false)
        } else {
            if (isLogin === 'true') {
                try {
                    const token = secureLocalStorage.getItem("refresh_token") as string
                    const refresh = await HTTPRefreshToken({ token })
                    const result = await HTTPProfile({ token: refresh.data.data.token })
                    dispatch(setProfileData({ data: result.data.data }))
                    secureLocalStorage.setItem('token', refresh.data.data.token)
                    secureLocalStorage.setItem('refresh_token', refresh.data.data.refresh_token)
                    localStorage.setItem('isLogin', 'true')
                } catch (error) {
                    console.log(error)
                    localStorage.setItem('isLogin', 'false')
                    setLoggedIn(false)
                }
            } else {
                setLoggedIn(isLogin === 'true')
            }
        }
    }

    const onLogout = () => {
        localStorage.setItem('isLogin', 'false')
        secureLocalStorage.setItem('token', '')
        secureLocalStorage.setItem('refresh_token', '')
        navigate('/auth/login')
    }

    React.useEffect(() => {
        async function CheckAuth() {
            try {
                Auth()
            } catch (error) {
                console.log(error)
            }
        }
        CheckAuth().catch().then()
    }, [init])

    const onSearch = () => {
        navigate(`/product/list?search=${Search}`)
        window.location.reload()
        window.scrollTo(0, 0)
    }

    return (
        <AppBar
            elevation={3}
            position="sticky"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: '#fff',
                width: '100%',
                height: 80
            }}
        >
            <Toolbar sx={{ ...CENTER, marginBottom: 0, height: '100%', padding: 0 }}>
                {
                    isMobile ?
                        <div style={{ width: '100%', ...CENTER, height: '100%' }}>
                            <Stack
                                width={'100%'}
                                direction={"row"}
                                alignItems={"center"}
                                gap={1}
                                sx={{ padding: '10px 0', height: '100%' }}
                                justifyContent={'space-between'}
                            >

                                <IconButton onClick={() => navigate('/')}>
                                    <Icon sx={{ color: '#000', fontSize: 30 }}>home</Icon>
                                </IconButton>
                                <TextField
                                    type="search"
                                    size="small"
                                    placeholder="Cari Produk..."
                                    sx={{ bgcolor: "white", width: '50%' }}
                                    value={Search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={(e) => { if (e.keyCode === 13) onSearch() }}
                                    InputProps={{
                                        style: { borderRadius: 10 },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <>
                                                {
                                                    Search.length > 0 ?
                                                        <Button
                                                            color={'secondary'}
                                                            size={'small'}
                                                            variant={'contained'}
                                                            sx={{ backgroundColor: Colors.primary }}
                                                            onClick={onSearch}
                                                        >Cari</Button>
                                                        : null
                                                }
                                            </>
                                        )
                                    }}
                                />
                                {
                                    isLoggedIn ?
                                        <IconButton onClick={() => navigate('/notification')}>
                                            <Icon sx={{ color: '#000', fontSize: 30 }}>notifications_none</Icon>
                                        </IconButton>
                                        : null
                                }
                                {
                                    isLoggedIn ?
                                        <IconButton onClick={ProfileClick}>
                                            <Avatar
                                                src={
                                                    ProfileRedux.profile_picture === undefined ||
                                                        ProfileRedux.profile_picture === null ?
                                                        Images.noImage : ProfileRedux.profile_picture
                                                }
                                                alt={'Profile'}
                                                sx={{ height: 40, width: 40 }}
                                            ></Avatar>
                                        </IconButton>
                                        : null
                                }
                                {
                                    isLoggedIn ? null :
                                        <Button
                                            color={"secondary"}
                                            variant={"contained"}
                                            startIcon={<Icon fontSize={'medium'} sx={{ color: '#fff' }}>login</Icon>}
                                            sx={{ backgroundColor: Colors.primary }}
                                            onClick={() => navigate('/auth/login')}
                                        >Login</Button>
                                }
                            </Stack>
                            {
                                isMobile ?
                                    <Menu
                                        anchorEl={Profile}
                                        open={Boolean(Profile)}
                                        onClose={ProfileClose}
                                    >
                                        <MenuItem onClick={() => navigate('/profile/account')} sx={{ "&:hover": { color: Colors.primary, transition: "all .3s", }, transition: "all .3s" }}>
                                            <ListItemIcon sx={{ color: "inherit" }}><Icon>person</Icon></ListItemIcon>
                                            <ListItemText sx={{ color: "inherit" }}>Profil</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={() => navigate('/cart')} sx={{ "&:hover": { color: Colors.primary, transition: "all .3s", }, transition: "all .3s" }}>
                                            <ListItemIcon sx={{ color: "inherit" }}><Icon>shopping_cart</Icon></ListItemIcon>
                                            <ListItemText sx={{ color: "inherit" }}>Keranjang</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={() => navigate('/favorite')} sx={{ "&:hover": { color: Colors.primary, transition: "all .3s", }, transition: "all .3s" }}>
                                            <ListItemIcon sx={{ color: "inherit" }}><Icon>favorite_border</Icon></ListItemIcon>
                                            <ListItemText sx={{ color: "inherit" }}>Favorit</ListItemText>
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem onClick={onLogout} sx={{ color: Colors.error }}>
                                            <ListItemIcon sx={{ color: "inherit" }}><Icon>logout</Icon></ListItemIcon>
                                            <ListItemText sx={{ color: "inherit" }}>Logout</ListItemText>
                                        </MenuItem>
                                    </Menu>
                                    :
                                    null
                            }
                        </div>
                        :
                        <div style={{ width: '100%', ...CENTER, height: '100%' }}>
                            <Stack
                                width={'90%'}
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                sx={{ padding: '10px 0', height: '100%' }}
                            >
                                <img src={Images.logo} style={{ height: '80%', width: 'auto', objectFit: 'contain', cursor: 'pointer' }} alt="logo" onClick={() => navigate('/')} />
                                <TextField
                                    type="search"
                                    size="small"
                                    placeholder="Cari Produk..."
                                    sx={{ bgcolor: "white", width: '50%' }}
                                    value={Search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={(e) => { if (e.keyCode === 13) onSearch() }}
                                    InputProps={{
                                        style: { borderRadius: 10 },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <>
                                                {
                                                    Search.length > 0 ?
                                                        <Button
                                                            color={'secondary'}
                                                            size={'small'}
                                                            variant={'contained'}
                                                            sx={{ backgroundColor: Colors.primary }}
                                                            onClick={onSearch}
                                                        >Cari</Button>
                                                        : null
                                                }
                                            </>
                                        )
                                    }}
                                />
                                {
                                    isLoggedIn ?
                                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                                            <IconButton onClick={LanguageClick}>
                                                <Icon sx={{ color: '#000', fontSize: 30 }}>language</Icon>
                                            </IconButton>
                                            <IconButton onClick={NotifClick}>
                                                <Badge badgeContent={0} color="secondary" max={9}>
                                                    <Icon sx={{ color: '#000', fontSize: 30 }}>notifications_none</Icon>
                                                </Badge>
                                            </IconButton>
                                            <IconButton onClick={FavoriteClick}>
                                                <Badge badgeContent={0} color="secondary" max={9}>
                                                    <Icon sx={{ color: '#000', fontSize: 30 }}>favorite_border</Icon>
                                                </Badge>
                                            </IconButton>
                                            <IconButton onClick={CartClick}>
                                                <Badge badgeContent={0} color="secondary" max={9}>
                                                    <Icon sx={{ color: '#000', fontSize: 30 }}>shopping_cart</Icon>
                                                </Badge>
                                            </IconButton>
                                            <IconButton onClick={ProfileClick}>
                                                <Avatar
                                                    src={
                                                        ProfileRedux.profile_picture === undefined ||
                                                            ProfileRedux.profile_picture === null ?
                                                            Images.noImage : ProfileRedux.profile_picture
                                                    } alt={ProfileRedux.name}
                                                ></Avatar>
                                            </IconButton>

                                        </Stack>
                                        :
                                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                                            <Button
                                                variant={"outlined"}
                                                color="secondary"
                                                sx={{ borderColor: Colors.primary }}
                                                onClick={() => navigate('/auth/login')}
                                            >Masuk</Button>
                                            <Button
                                                variant={"contained"}
                                                color="secondary"
                                                sx={{ backgroundColor: Colors.primary }}
                                                onClick={() => navigate('/auth/register')}
                                            >Daftar</Button>
                                        </Stack>
                                }
                            </Stack>
                        </div>
                }
            </Toolbar>
            <FavoriteMenu
                isOpen={Favorite}
                onClose={FavoriteClose}
                isLoading={FavoriteLoading}
                data={FavoriteData}
                getData={async () => {
                    setFavoriteLoading(true)
                    try {
                        const result = await HTTPGetWishLists({ token })
                        console.log(result)
                        setFavoriteData(result.data.data)
                        setFavoriteLoading(false)
                    } catch (error) {
                        setFavoriteLoading(false)
                        console.log(error)
                    }
                }}
            />
            <CartMenu
                isOpen={Cart}
                onClose={CartClose}
                isLoading={CartLoading}
                data={CartData}
            />
            <NotificationMenu
                isOpen={Notif}
                onClose={NotifClose}
            />
            <LanguageMenu
                isOpen={Language}
                onClose={LanguageClose}
            />
            {
                isMobile ? null :
                    <ProfileMenu
                        isOpen={Profile}
                        onClose={ProfileClose}
                    />
            }
        </AppBar>
    );
};

export default NavigationBar;
