export const Icons = {
    logo: require('./logo.png'),
    google: require('./google_icon.png'),
    facebook: require('./facebook_icon.png'),
    discount: require('./discount_icon.png'),
    medal: require('./medal_icon.png'),
    rewind: require('./rewind_icon.png'),
    bca: require('./bca_icon.png'),
    bni: require('./bni_icon.png'),
    mandiri: require('./mandiri_icon.png'),
    bsi: require('./bsi_icon.png'),
    permata: require('./permata_icon.png'),
    bri: require('./bri_icon.png'),
    mastercard: require('./mastercard_icon.png'),
    visa: require('./visa_icon.png'),
    whatsappDark: require('./whatsapp_dark_icon.png'),
    whatsappLight: require('./whatsapp_light_icon.png'),
    facebookDark: require('./facebook_dark_icon.png'),
    instagramDark: require('./instagram_dark_icon.png'),
    twitterDark: require('./twitter_dark_icon.png'),
    check: require('./check_icon.png'),
}