import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, Icon, IconButton, Slide, Stack, TextField, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { HTTPCancelOrder, HTTPReturnOrder } from '../../apis/orders';
import { Icons } from '../../assets/icons';
import { CENTER, Colors } from '../../utils/colors';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ReturnModal = (param: { isOpen: boolean, onClose: any, item: any, getData: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [Reason, setReason] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)

    const onReturn = async () => {
        setLoading(true)
        try {
            await HTTPReturnOrder({ token, order_id: param.item, return_reason: Reason })
            toast.success('Pesanan telah diajukan untuk pengembalian produk!')
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan!')
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '95vw' : '50vw' } }}
        >
            <DialogContent>
                <Stack direction={"column"} gap={3} divider={<Divider />}>
                    <div>
                        <Typography variant={"h6"} fontWeight={'600'} color={"#000"}>Alasan Pengajuan</Typography>
                        <Typography variant={"body2"} color={"#000"}>Sertakan alasan anda mengapa melakukan Pengajuan Pengembalian Barang.</Typography>
                    </div>
                    <Stack direction={"column"} gap={2}>
                        <Stack direction={isMobile ? 'column' : 'row'} width={'100%'} gap={1} alignItems={'flex-start'}>
                            <Typography variant={"subtitle2"} color={"#000"} width={isMobile ? '100%' : '30%'}>Alasan Pengajuan:</Typography>
                            <TextField
                                type="text"
                                placeholder="Sertakan alasan pengajuan"
                                size="small"
                                value={Reason}
                                onChange={(text) => setReason(text.target.value)}
                                sx={{ bgcolor: "white", width: isMobile ? '100%' : "70%" }}
                                InputProps={{ sx: { borderRadius: 1 } }}
                                multiline
                                rows={5}
                            />
                        </Stack>
                    </Stack>
                    <Box sx={{ width: '100%', ...CENTER, color: Colors.primary }}>
                        <Button color={'secondary'} variant={'contained'} onClick={onReturn} disabled={isLoading} sx={{ backgroundColor: Colors.primary }}>
                            <Stack direction={"row"} gap={1} padding={1}>
                                {isLoading ?
                                    <CircularProgress color="secondary" size={20} />
                                    :
                                    <Icon fontSize='small' sx={{ color: '#fff' }}>save</Icon>
                                }
                                <Typography variant="body2" color={"#fff"}>Simpan</Typography>
                            </Stack>
                        </Button>
                    </Box>
                </Stack>
            </DialogContent >
        </Dialog >
    )
}

export const CancelModal = (param: { isOpen: boolean, onClose: any, item: any, getData: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)

    const onCancel = async () => {
        setLoading(true)
        try {
            await HTTPCancelOrder({ token, order_id: param.item })
            toast.success('Berhasil Membatalkan Pesanan!')
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan!')
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '95vw' : '30vw' } }}
        >
            <DialogContent>
                <Stack direction={"column"} gap={5}>
                    <Stack direction={"row"} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={"row"} alignItems={'center'} gap={1}>
                            <Icon fontSize={'medium'} sx={{ color: Colors.error }}>error</Icon>
                            <Typography variant="body2" fontWeight={'600'}>Hapus Data?</Typography>
                        </Stack>
                        <IconButton onClick={param.onClose}><Icon fontSize={'medium'} sx={{ color: Colors.disable }}>close</Icon></IconButton>
                    </Stack>
                    <Stack direction={"column"} alignItems={'center'} gap={1}>
                        <Typography variant="body1" fontWeight={'600'} textAlign={'center'}>Yakin ingin menbatalkan pesanan ini?</Typography>
                        <Typography variant="body2" fontWeight={'400'} textAlign={'center'} color={'#999'} width={'70%'}>Produk yang sudah dibatalkan tidak dapat di kembalikan</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={'center'} justifyContent={'center'} gap={2}>
                        <Button variant={"outlined"} color={'error'} onClick={param.onClose}>Batal</Button>
                        <Button variant={"contained"} color={'error'} onClick={onCancel} disabled={isLoading}>
                            {isLoading ?
                                <CircularProgress color="secondary" size={20} />
                                :
                                'Yakin'
                            }
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent >
        </Dialog >
    )
}