import { Divider, Icon, Stack } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Images } from "../../assets/images";
import { setProductDetail } from "../../stores/data/product";
import { CENTER, Colors, Truncate } from "../../utils/colors";
import { FormatCurrency } from "../../utils/utils";

const ProductCard = (param: { item: any }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDetail = () => {
    dispatch(setProductDetail({ data: { id: param.item.id } }));
    // navigate(`/product/detail/${param.item.slug}`)
  };

  return (
    <a
      href={`/product/detail/${param.item.slug}`}
      style={{ textDecoration: "none" }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: 5,
          boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.14)",
          width: "100%",
          height: "auto",
          position: "relative",
          overflow: "hidden",
          cursor: "pointer",
        }}
        onClick={onDetail}
      >
        <div style={{ padding: 10, position: "relative" }}>
          <Stack
            direction={"column"}
            sx={{
              width: "100%",
              height: "100%",
              transition: "all .3s",
              "&:hover": {
                transform: "scale(1.05)",
                transition: "all .3s",
              },
            }}
            gap={1}
          >
            <img
              src={
                param.item.galleries.length === 0
                  ? Images.noImageProduct
                  : param.item.galleries[0].image_path
              }
              alt=""
              style={{
                width: "100%",
                aspectRatio: 1,
                objectFit: "cover",
                borderRadius: 5,
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = Images.noImageProduct;
              }}
            />
            <h3 style={{ margin: 0, color: "#000" }}>
              {/* Rp {FormatCurrency(param.item.variant_prices.length === 0 ? param.item.price : param.item.variant_prices[0].price)} */}
              {param.item.display_price}
            </h3>
            <span
              style={{
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 2,
                fontSize: "2vh",
              }}
            >
              {param.item.description_id}
            </span>
            <Stack direction={"row"} gap={isMobile ? 0.5 : 1}>
              <Stack direction={"row"} gap={isMobile ? 0 : 0.5}>
                <Icon fontSize="small" sx={{ color: "#e5e437" }}>
                  star
                </Icon>
                <small style={{ color: "#999" }}>4.8</small>
              </Stack>
              <Divider orientation="vertical" variant="fullWidth" flexItem />
              <small style={{ color: Colors.primary }}>
                {"(1rb+ Terjual)"}
              </small>
            </Stack>
          </Stack>
        </div>
      </div>
    </a>
  );
};

export default ProductCard;
