import { AxiosMethod } from "../utils/interceptors";
const url = "couriers";

export function HTTPGetCouriers(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}/list`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetCouriersCost(param: {
  token: string;
  courier_code: string;
  address_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/get_cost`, {
        token: param.token,
        courier_code: param.courier_code,
        address_id: param.address_id,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
