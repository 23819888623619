import { Box, Button, Dialog, DialogContent, Divider, Grid, Icon, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { HTTPGetCouriers } from '../../apis/couriers';
import { Icons } from '../../assets/icons';
import { CENTER, Colors } from '../../utils/colors';
import { LoaderList } from '../utils/skeletons';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ShippingModal = (param: { isOpen: boolean, onClose: any, result: any }) => {
    const [isLoading, setLoading] = React.useState(true)
    const [CouriersData, setCouriersData] = React.useState([])
    const [SelectedItem, setSelectedItem] = React.useState<any>({})

    const GetCouriers = async () => {
        setLoading(true)
        try {
            const result = await HTTPGetCouriers()
            setCouriersData(result.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan')
            console.log(error)
        }
    }

    React.useEffect(() => {
        async function APIList() {
            try {
                if (param.isOpen === true) {
                    await GetCouriers()
                }
            } catch (error) {
                console.log(error)
            }
        }
        APIList().catch().then()
    }, [param.isOpen])

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '90vw' : '50vw' } }}
        >
            <DialogContent>
                <Stack direction={"column"} gap={3} divider={<Divider />}>
                    <Stack direction={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'flex-start' : 'space-between'} gap={isMobile ? 3 : 0}>
                        <Typography variant={"body1"} fontWeight={'600'} width={isMobile ? '100%' : '30%'}>Pilih Pengiriman:</Typography>
                        {
                            isLoading ?
                                <LoaderList />
                                :
                                <Stack direction={isMobile ? 'column' : 'row'} alignItems={"center"} gap={2} sx={{ color: '#999' }} flexWrap={'wrap'} justifyContent={isMobile ? 'center' : "flex-end"}>
                                    {
                                        CouriersData.map((item: any, index: number) => (
                                            <Button
                                                key={index}
                                                variant='outlined'
                                                color={'inherit'}
                                                sx={{
                                                    width: isMobile ? '100%' : undefined,
                                                    border: SelectedItem.id === item.id ? `1px solid ${Colors.primary}` : undefined,
                                                    boxShadow: SelectedItem.id === item.id ? `0px 0px 10px 3px ${Colors.primary + 50}` : '0px',
                                                    color: SelectedItem.id === item.id ? Colors.primary : undefined
                                                }}
                                                onClick={() => setSelectedItem(item)}
                                            >{item.name}</Button>
                                        ))
                                    }
                                </Stack>
                        }
                    </Stack>
                    <Box sx={{ width: '100%', ...CENTER, color: Colors.primary }}>
                        <Button
                            variant='contained'
                            color={'secondary'}
                            sx={{ backgroundColor: Colors.primary, width: isMobile ? '90%' : '50%' }}
                            onClick={() => {
                                param.result(SelectedItem)
                                param.onClose()
                            }}
                        >
                            <Typography variant={'body1'} color={"#fff"}>Pilih Pengiriman</Typography>
                        </Button>
                    </Box>
                </Stack>
            </DialogContent >
        </Dialog >
    )
}