import { Box, Button, Divider, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import { CENTER, Colors } from '../../utils/colors'

const PrivacyMenus = [
    { id: 1, label: 'Apa itu Angelo Skin?', link: '/privacy/about' },
    { id: 2, label: 'Kebijakan Angelo Skin', link: '/privacy/policy' },
]

const PrivacyMenus2 = [
    { id: 1, label: 'Tentang Kami', link: '/privacy/about' },
    { id: 2, label: 'Kebijakan Privasi', link: '/privacy/policy' },
]

export const PrivacySideBar = (param: { activeMenu: number }) => {
    const navigate = useNavigate()

    const Routing = (link: string) => {
        navigate(link)
    }

    return (
        <div style={{ width: '100%' }}>
            <Stack direction={'column'} gap={2} divider={<Divider />} width={'100%'}>
                {
                    PrivacyMenus.map((item) => (
                        <Box key={item.id} style={{ color: param.activeMenu === item.id ? Colors.primary : '#000', width: '100%' }}>
                            <Button
                                color={"inherit"}
                                variant={'text'}
                                onClick={() => Routing(item.link)}
                                sx={{ width: '100%', textAlign: 'left' }}
                            >
                                <Stack alignItems={'center'} gap={2} direction={'row'} width={'100%'}>
                                    <Icon fontSize={'medium'}
                                        sx={{
                                            color: 'inherit',
                                            display: param.activeMenu === item.id ? 'block' : 'none',
                                            transition: 'all .3s'
                                        }}>arrow_forward</Icon>
                                    <Typography
                                        variant={'h6'}
                                        textAlign={'left'}
                                        color={'inherit'}
                                        width={'100%'}
                                        fontWeight={'600'}
                                        sx={{ transition: 'all .3s' }}
                                    >{item.label}</Typography>
                                </Stack>
                            </Button>
                        </Box>
                    ))
                }
            </Stack>
        </div>
    )
}

export const PrivacyNavbar = (param: { activeMenu: number }) => {
    const navigate = useNavigate()

    const Routing = (link: string) => {
        navigate(link)
    }
    return (
        <div style={{ width: '100%' }}>
            <Stack direction={'row'} alignItems={'center'}>
                {
                    PrivacyMenus2.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                width: '50%',
                                borderBottom: param.activeMenu === item.id ? `2px solid ${Colors.primary}` : '0px',
                                padding: '10px 0',
                                ...CENTER,
                                cursor: 'pointer'
                            }}
                            onClick={() => Routing(item.link)}
                        >
                            <Typography
                                variant="body1"
                                fontWeight={'600'}
                                color={param.activeMenu === item.id ? Colors.primary : Colors.primary + 50}
                            >{item.label}</Typography>
                        </div>
                    ))
                }
            </Stack>
        </div>
    )
}