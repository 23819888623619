import { Button, Icon, Pagination, Stack, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import secureLocalStorage from 'react-secure-storage'
import { HTTPGetWishLists } from '../../apis/wishlist'
import { FavoriteList, FavoriteTable } from '../../components/favorites/favoriteTable'
import Footer from '../../components/footer'
import { Header } from '../../components/header'
import NavigationBar from '../../components/navigationBar'
import { LoaderList } from '../../components/utils/skeletons'
import { CENTER, Colors } from '../../utils/colors'

const FavoriteScreen = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState<any>(true);
    const [FavoriteData, setFavoriteData] = React.useState<any>([]);
    const [init, setInit] = React.useState(false)

    const GetFavorites = async () => {
        try {
            const result = await HTTPGetWishLists({ token })
            console.log(result)
            setFavoriteData(result.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    };

    React.useEffect(() => {
        async function Initial() {
            setLoading(true)
            await GetFavorites()
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <Header title="Favorit" />
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={'column'} width={'90%'} paddingY={5} gap={3} sx={{ color: '#999' }} alignItems={'flex-start'}>
                    <Button color='inherit' variant='outlined'>
                        <Stack direction={'row'} alignItems={'center'} gap={2}>
                            <Icon fontSize='small' sx={{ color: 'inherit' }}>arrow_back</Icon>
                            <Typography variant={"body1"}>Kembali Belanja</Typography>
                        </Stack>
                    </Button>
                    {
                        isLoading ?
                            <LoaderList />
                            :
                            <>
                                {
                                    isMobile ?
                                        <FavoriteList data={FavoriteData} getData={GetFavorites} />
                                        :
                                        <FavoriteTable data={FavoriteData} getData={GetFavorites} />
                                }
                                {
                                    FavoriteData.length === 0 ?
                                        <Stack direction={'column'} gap={2} width={'100%'} alignItems={'center'} marginY={5}>
                                            <Icon sx={{ fontSize: 100, color: '#999' }}>favorite_border</Icon>
                                            <Typography variant="h6" color="#999" textAlign={'center'}>Tidak ada Produk di Wishlist</Typography>
                                        </Stack>
                                        : null
                                }
                            </>
                    }
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} gap={2} width={'100%'}>
                        <Button variant={'outlined'} color={"error"}>
                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                <Icon fontSize='small' sx={{ color: Colors.error }}>delete</Icon>
                                <Typography variant={'button'} color={Colors.error}>HAPUS SEMUA</Typography>
                            </Stack>
                        </Button>
                    </Stack>
                    <div style={{ width: '100%', ...CENTER }}>
                        <Pagination count={5} />
                    </div>
                </Stack>
            </div>
            <Footer />
        </div>
    )
}

export default FavoriteScreen;