import { Box, Icon, Rating, Slider, Stack, Typography } from '@mui/material';
import React from 'react'
import { Colors } from '../../utils/colors';
import { styled, useTheme } from '@mui/material/styles';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, TreeItemProps, treeItemClasses } from '@mui/x-tree-view/TreeItem';

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    bgColorForDarkMode?: string;
    color?: string;
    colorForDarkMode?: string;
    labelInfo?: string;
    labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: '#000',
    [`& .${treeItemClasses.content}`]: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        [`& .${treeItemClasses.label}`]: {
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
    },
})) as unknown as typeof TreeItem;

const StyledTreeItem = React.forwardRef(function StyledTreeItem(
    props: StyledTreeItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    const theme = useTheme();
    const {
        bgColor,
        color,
        labelInfo,
        labelText,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;

    const styleProps: any = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <StyledTreeItemRoot
            label={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingY: 1.5,
                        paddingX: 0.5,
                        pr: 0,
                    }}
                >
                    <Typography
                        variant={other.nodeId === '1' ? 'body1' : "body2"}
                        sx={{ fontWeight: other.nodeId === '1' ? '600' : '400', flexGrow: 1 }}
                    >
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit" sx={{ backgroundColor: '#ddd', borderRadius: 1, padding: '1px 5px' }}>
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={styleProps}
            {...other}
            ref={ref}
        />
    );
});

export const CategoryTree = (param: { ListData: any, getData?: any }) => {
    return (
        <div style={{ width: '100%' }}>
            <TreeView
                defaultExpanded={['1']}
                defaultCollapseIcon={<Icon sx={{ color: '#909090' }} fontSize={'small'}>arrow_drop_down</Icon>}
                defaultExpandIcon={<Icon sx={{ color: '#909090' }} fontSize={'small'}>arrow_right</Icon>}
                sx={{ flexGrow: 1, width: '100%', overflowY: 'auto' }}
            >
                <StyledTreeItem
                    nodeId={param.ListData.node}
                    labelText={param.ListData.label}
                >
                    {
                        param.ListData.child.length !== 0 ?
                            <>
                                {
                                    param.ListData.child.map((item: any, index: number) => (
                                        <StyledTreeItem
                                            key={index}
                                            nodeId={item.node}
                                            labelText={item.label}
                                            labelInfo={item.qty}
                                            onClick={() =>
                                                item.id !== null ||
                                                    item.id !== undefined ?
                                                    param.getData({ category_id: item.id })
                                                    : {}
                                            }
                                        />
                                    ))
                                }
                            </>
                            :
                            null
                    }
                </StyledTreeItem>
            </TreeView>
        </div>
    )
}

export const RatingTree = () => {
    return (
        <div style={{ width: '100%' }}>
            <TreeView
                defaultExpanded={['1']}
                defaultCollapseIcon={<Icon sx={{ color: '#909090' }} fontSize={'small'}>arrow_drop_down</Icon>}
                defaultExpandIcon={<Icon sx={{ color: '#909090' }} fontSize={'small'}>arrow_right</Icon>}
                sx={{ flexGrow: 1, width: '100%', overflowY: 'auto' }}
            >
                <StyledTreeItem
                    nodeId={'1'}
                    labelText={'Penilaian'}
                >
                    <Stack direction={"column"} gap={1} paddingX={4} paddingY={2}>
                        {
                            [1, 2, 3, 4, 5].map((item) => (
                                <Stack direction={'row'} gap={1} key={item}>
                                    <Rating
                                        name="read-only"
                                        value={item}
                                        readOnly
                                        icon={<Icon sx={{ color: Colors.warning }} fontSize={'small'}>star</Icon>}
                                        emptyIcon={<Icon sx={{ color: '#999' }} fontSize={'small'}>star</Icon>}
                                    />
                                    <Typography variant={'subtitle2'}>{item}</Typography>
                                </Stack>
                            )).reverse()
                        }
                    </Stack>
                </StyledTreeItem>
            </TreeView>
        </div>
    )
}

export const PriceRange = () => {
    return (
        <div style={{ width: '100%' }}>
            <TreeView
                defaultExpanded={['1']}
                defaultCollapseIcon={<Icon sx={{ color: '#909090' }} fontSize={'small'}>arrow_drop_down</Icon>}
                defaultExpandIcon={<Icon sx={{ color: '#909090' }} fontSize={'small'}>arrow_right</Icon>}
                sx={{ flexGrow: 1, width: '100%', overflowY: 'auto' }}
            >
                <StyledTreeItem
                    nodeId={'1'}
                    labelText={'Batas Harga'}
                >
                    <Stack direction={"column"} gap={1} paddingX={4} paddingY={2}>
                        <Typography variant={'subtitle2'}>Harga Antara</Typography>
                        <Typography variant={'subtitle2'} color={Colors.primary}>Rp 0 - Rp 1.000.000</Typography>
                        <Slider
                            defaultValue={0}
                            getAriaValueText={(val) => { return `Rp ${val}` }}
                            valueLabelDisplay="auto"
                            min={0}
                            step={100000}
                            max={1000000}
                            color={'secondary'}
                            marks={[
                                { value: 0, label: 'Rp 0' },
                                { value: 1000000, label: 'Rp 1jt' }
                            ]}
                        />
                    </Stack>
                </StyledTreeItem>
            </TreeView>
        </div>
    )
}