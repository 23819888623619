import { Icon } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useSwiper } from "swiper/react";
import { CENTER } from "../../utils/colors";

export const SwiperButtonNext = () => {
    const swiper = useSwiper();
    return (
        <div style={{ padding: isMobile ? 5 : 10, ...CENTER, backgroundColor: 'rgba(0,0,0,0.5)', cursor: 'pointer' }} onClick={() => swiper.slideNext()}>
            <Icon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: '#fff' }}>chevron_right</Icon>
        </div >
    )
};

export const SwiperButtonPrev = () => {
    const swiper = useSwiper();
    return (
        <div style={{ padding: isMobile ? 5 : 10, ...CENTER, backgroundColor: 'rgba(0,0,0,0.5)', cursor: 'pointer' }} onClick={() => swiper.slidePrev()}>
            <Icon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: '#fff' }}>chevron_left</Icon>
        </div >
    )
};