import { Box, Button, Divider, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import secureLocalStorage from 'react-secure-storage'
import { CENTER, Colors } from '../../utils/colors'
import { LogoutModal } from './logout'

const ProfileMenus = [
    { id: 1, label: 'Akun Saya', link: '/profile/account' },
    { id: 2, label: 'Alamat Saya', link: '/profile/address' },
    { id: 3, label: 'Riwayat Pesanan', link: '/profile/order-history' },
    // { id: 4, label: 'Kartu Kredit', link: '/profile/cards' },
    // { id: 5, label: 'Poin Saya', link: '/profile/point' },
    { id: 6, label: 'Ubah Kata Sandi', link: '/profile/update-password' },
    { id: 7, label: 'Keluar', link: 'logout' },
]

export const ProfileSideBar = (param: { activeMenu: number }) => {
    const navigate = useNavigate()
    const [onLogout, setLogout] = React.useState(false)

    const Routing = (link: string, param: string) => {
        if (link !== 'logout') {
            navigate(param)
        } else {
            setLogout(true)
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <Stack direction={'column'} gap={2} divider={<Divider />} width={'100%'}>
                {
                    ProfileMenus.map((item) => (
                        <Box key={item.id} style={{ color: param.activeMenu === item.id ? Colors.primary : '#000', width: '100%' }}>
                            <Button
                                color={"inherit"}
                                variant={'text'}
                                onClick={() => Routing(item.link, item.link)}
                                sx={{ width: '100%', textAlign: 'left' }}
                            >
                                <Stack alignItems={'center'} gap={2} direction={'row'} width={'100%'}>
                                    <Icon fontSize={'medium'}
                                        sx={{
                                            color: 'inherit',
                                            display: param.activeMenu === item.id ? 'block' : 'none',
                                            transition: 'all .3s'
                                        }}>arrow_forward</Icon>
                                    <Typography
                                        variant={'h6'}
                                        textAlign={'left'}
                                        color={'inherit'}
                                        width={'100%'}
                                        fontWeight={'600'}
                                        sx={{ transition: 'all .3s' }}
                                    >{item.label}</Typography>
                                </Stack>
                            </Button>
                        </Box>
                    ))
                }
            </Stack>
            <LogoutModal isOpen={onLogout} onClose={() => setLogout(false)} />
        </div>
    )
}

export const ProfileNavbar = (param: { activeMenu: number }) => {
    const navigate = useNavigate()
    const [onLogout, setLogout] = React.useState(false)

    const Routing = (link: string) => {
        if (link !== 'logout') {
            navigate(link)
        } else {
            setLogout(true)
        }
    }
    return (
        <div style={{ width: '100%', overflow: 'hidden' }}>
            <Stack
                direction={'row'}
                alignItems={'center'}
                flexWrap={"nowrap"}
                sx={{
                    whiteSpace: 'nowrap',
                    overflowY: 'hidden',
                    overflowX: 'scroll',
                    WebkitOverflowScrolling: 'touch',
                    '&::-webkit-scrollbar': { height: '0px' },
                    '&::-webkit-scrollbar-thumb': { backgroundColor: Colors.primary }
                }}
            >
                {
                    ProfileMenus.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                width: '50%',
                                borderBottom: param.activeMenu === item.id ? `2px solid ${Colors.primary}` : '0px',
                                padding: '10px 0',
                                ...CENTER,
                                cursor: 'pointer',
                                marginLeft: 10,
                                marginRight: 10,
                            }}
                            onClick={() => Routing(item.link)}
                        >
                            <Typography
                                variant="body1"
                                fontWeight={'600'}
                                color={param.activeMenu === item.id ? Colors.primary : Colors.primary + 50}
                            >{item.label}</Typography>
                        </div>
                    ))
                }
            </Stack>
            <LogoutModal isOpen={onLogout} onClose={() => setLogout(false)} />
        </div>
    )
}