import { Button, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { ThreeDots } from 'react-loader-spinner'
import { useNavigate } from 'react-router'
import secureLocalStorage from 'react-secure-storage'
import { HTTPGetCarts } from '../../apis/cart'
import { HTTPCalculatePrice } from '../../apis/transaction'
import { Images } from '../../assets/images'
import { CartCheckout } from '../../components/cart/cartCheckout'
import { CartTable } from '../../components/cart/cartTable'
import Footer from '../../components/footer'
import { Header } from '../../components/header'
import NavigationBar from '../../components/navigationBar'
import { CENTER, Colors } from '../../utils/colors'

const CartScreen = () => {
    const navigate = useNavigate()
    const token = secureLocalStorage.getItem('token') as string
    const [init, setInit] = React.useState(false)
    const [CartData, setCartData] = React.useState<any[]>([]);
    const [CurrentPrice, setCurrentPrice] = React.useState<any>({});
    const [isLoading, setLoading] = React.useState(true);

    const GetCarts = async () => {
        try {
            const result = await HTTPGetCarts({ token })
            setCartData(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const GetCurrentPrice = async () => {
        try {
            const result = await HTTPCalculatePrice({ token })
            setCurrentPrice(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const onUpdateQty = async () => {
        await GetCarts()
        await GetCurrentPrice()
    }

    React.useEffect(() => {
        async function Initial() {
            setLoading(true)
            await GetCarts()
            await GetCurrentPrice()
            setLoading(false)
        }
        Initial().then().catch()
    }, [init])

    return (
        <div style={{ backgroundColor: '#fff', width: '100%' }}>
            <NavigationBar />
            <Header title={'Keranjang'} />
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={isMobile ? 'column' : 'row'} sx={{ width: '90%', paddingY: 5 }} justifyContent={isMobile ? 'center' : 'space-between'} gap={isMobile ? 5 : 0}>
                    <Stack direction={'column'} width={isMobile || CartData.length === 0 ? '100%' : '70%'} gap={2} sx={{ color: '#999' }} alignItems={'flex-start'}>
                        <Button color='inherit' variant='outlined' onClick={() => navigate('/')}>
                            <Stack direction={'row'} alignItems={'center'} gap={2}>
                                <Icon fontSize='small' sx={{ color: 'inherit' }}>arrow_back</Icon>
                                <Typography variant={"body1"}>Kembali Belanja</Typography>
                            </Stack>
                        </Button>
                        {
                            isLoading ?
                                <div style={{ width: '100%', overflow: isMobile ? 'auto' : 'none', backgroundColor: '#fff', padding: '10% 0' }}>
                                    <Stack direction={'column'} gap={0} alignItems={'center'} justifyContent={'center'}>
                                        <img src={Images.logo} style={{ width: '15vw', height: 'auto', objectFit: 'contain' }} alt="" />
                                        <ThreeDots
                                            height="80"
                                            width="80"
                                            radius="9"
                                            color={Colors.primary}
                                            ariaLabel="three-dots-loading"
                                            visible={true}
                                        />
                                    </Stack>
                                </div>
                                :
                                <>
                                    <CartTable
                                        data={CartData}
                                        quantity={onUpdateQty}
                                        getData={GetCarts}
                                    />
                                    {
                                        CartData.length === 0 ?
                                            <Stack direction={'column'} gap={2} width={'100%'} alignItems={'center'} marginY={5}>
                                                <Icon sx={{ fontSize: 100, color: '#999' }}>local_mall</Icon>
                                                <Typography variant="h6" color="#999" textAlign={'center'}>Tidak ada Produk di Keranjang</Typography>
                                            </Stack>
                                            : null
                                    }
                                </>
                        }
                        {/* <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} gap={2} width={'100%'}>
                            <Button variant={'outlined'} color={"secondary"}>
                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                    <Icon fontSize='small' sx={{ color: Colors.primary }}>update</Icon>
                                    <Typography variant={'button'} color={Colors.primary}>{isMobile ? 'Perbarui' : 'UPDATE KERANJANG'}</Typography>
                                </Stack>
                            </Button>
                            <Button variant={'outlined'} color={"error"}>
                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                    <Icon fontSize='small' sx={{ color: Colors.error }}>delete</Icon>
                                    <Typography variant={'button'} color={Colors.error}>{isMobile ? 'Hapus' : 'HAPUS SEMUA'}</Typography>
                                </Stack>
                            </Button>
                        </Stack> */}
                    </Stack>
                    {
                        CartData.length === 0 ? null :
                            <div style={{ border: '3px dashed #999', borderRadius: 10, width: isMobile ? '100%' : '28%', backgroundColor: '#f7f7f7' }}>
                                <CartCheckout navigate={navigate} subtotal={CurrentPrice} />
                            </div>
                    }
                </Stack>
            </div>
            <Footer />
        </div>
    )
}

export default CartScreen;