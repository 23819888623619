import * as React from 'react';
import { Grid, Skeleton, Stack } from '@mui/material';
import { isMobile } from 'react-device-detect';

export const LoaderList = () => {
    return (
        <Stack direction={'column'} spacing={2} width={'100%'}>
            {[...Array(10)].map((item, index) => (
                <Skeleton animation={"wave"} key={index} variant="rounded" width={'100%'} height={60} />
            ))}
        </Stack>
    )
}

export const LoaderCard = () => {
    return (
        <Grid container spacing={isMobile ? 1 : 2}>
            {[...Array(9)].map((item, index) => (
                <Grid item md={4} xs={6} key={index}>
                    <Skeleton animation={"wave"} variant="rounded" width={'100%'} height={'100%'} sx={{ aspectRatio: 3 / 4 }} />
                </Grid>
            ))}
        </Grid>
    )
}
