import { Icon, Stack, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import BannerSwiper from '../../components/home/bannerSwiper';
import BestSeller from '../../components/home/bestSeller';
import CategoryList from '../../components/home/categoryList';
import FlashSale from '../../components/home/flashSale';
import Footer from '../../components/footer';
import NavigationBar from '../../components/navigationBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { HTTPGetCategories } from '../../apis/categories';
import { HomeSkeleton } from '../../components/skeleton/home';
import { HTTPGetSlidersHome } from '../../apis/slider';
import { HTTPGetBestSellers, HTTPGetProducts } from '../../apis/products';

const CardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.14)',
    border: '1px solid #999',
    borderRadius: 3,
    color: '#000',
    transition: 'all .2s',
    padding: '20px 0',
}

const HomeScreen = () => {
    const navigate = useNavigate()
    const isLogin = localStorage.getItem('isLogin')
    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(true)
    const [Products, setProducts] = React.useState([])
    const [BestSellers, setBestSellers] = React.useState([])
    const [Categories, setCategories] = React.useState([])
    const [Sliders, setSliders] = React.useState([])

    const GetProducts = async () => {
        try {
            const result = await HTTPGetProducts({ limit: 10 })
            setProducts(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const GetBestSellers = async () => {
        try {
            const result = await HTTPGetBestSellers()
            setBestSellers(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const GetCategories = async () => {
        try {
            const result = await HTTPGetCategories()
            setCategories(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const GetSliders = async () => {
        try {
            const result = await HTTPGetSlidersHome()
            setSliders(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        async function APIList() {
            setLoading(true)
            try {
                await GetProducts()
                await GetBestSellers()
                await GetCategories()
                await GetSliders()
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.log(error)
            }
        }
        APIList().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <Toolbar />
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={'column'} gap={5} style={{ width: '90%' }}>
                    {
                        isLoading ?
                            <HomeSkeleton />
                            :
                            <Stack direction={'column'} gap={5} style={{ width: '100%' }}>
                                <BannerSwiper data={Sliders} />
                                <CategoryList data={Categories} />
                                {
                                    BestSellers.length === 0 ? null :
                                        <BestSeller data={BestSellers} />
                                }
                                <FlashSale data={Products} />
                            </Stack>
                    }
                    <Stack direction={isMobile ? 'column' : 'row'} gap={isMobile ? 3 : 0} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={'column'} gap={1.5} alignItems={'center'} width={isMobile ? '100%' : '28%'} sx={{ ...CardStyle, '&:hover': { backgroundColor: Colors.primary, color: '#fff', transition: 'all .2s' } }}>
                            <Icon fontSize={'large'} sx={{ color: 'inherit' }}>workspace_premium</Icon>
                            <h3 style={{ margin: 0, color: 'inherit', textAlign: 'center' }}>{'Produk Terjamin'}</h3>
                            <small style={{ color: 'inherit', textAlign: 'center', width: '70%' }}>{'Belanja di AngeloSkin asli dan bersetifikat BPOM'}</small>
                        </Stack>
                        <Stack direction={'column'} gap={1.5} alignItems={'center'} width={isMobile ? '100%' : '40%'} sx={{ ...CardStyle, '&:hover': { backgroundColor: Colors.primary, color: '#fff', transition: 'all .2s' } }}>
                            <Icon fontSize={'large'} sx={{ color: 'inherit' }}>history</Icon>
                            <h3 style={{ margin: 0, color: 'inherit', textAlign: 'center' }}>{'Return & Refund'}</h3>
                            <small style={{ color: 'inherit', textAlign: 'center', width: '60%' }}>{'Jaminan & garansi '}<i>return</i>{' produk & '}<i>refund</i> uang 100% kembali</small>
                        </Stack>
                        <Stack direction={'column'} gap={1.5} alignItems={'center'} width={isMobile ? '100%' : '28%'} sx={{ ...CardStyle, '&:hover': { backgroundColor: Colors.primary, color: '#fff', transition: 'all .2s' } }}>
                            <Icon fontSize={'large'} sx={{ color: 'inherit' }}>discount</Icon>
                            <h3 style={{ margin: 0, color: 'inherit', textAlign: 'center' }}>{'Weekly Beauty Discount'}</h3>
                            <small style={{ color: 'inherit', textAlign: 'center', width: '70%' }}>Diskon <i>Beauty</i> mingguan untuk produk-produk terbaik.</small>
                        </Stack>
                    </Stack>
                </Stack>
            </div>
            {
                isLogin === 'true' ? null :
                    <div style={{
                        width: '100%',
                        position: 'relative',
                        backgroundImage: 'url(https://cms.sehatq.com/cdn-cgi/image/format=auto,width=1080,quality=90/public/img/article_img/apakah-kosmetik-kedaluwarsa-masih-bisa-dipakai-1626152163.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}>
                        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', width: '100%', ...CENTER, height: '20vh' }}>
                            <Stack direction={isMobile ? 'column' : 'row'} alignItems={'center'} justifyContent={isMobile ? 'center' : 'space-between'} sx={{ width: '90%' }} gap={isMobile ? 2 : 0}>
                                <Typography variant={isMobile ? 'h5' : 'h4'} fontWeight={'600'} color={'#fff'}>{'Sign Up & Get 50% Off!'}</Typography>
                                <div onClick={() => navigate('/auth/register')} style={{ border: '1px solid #fff', borderRadius: 5, ...CENTER, width: isMobile ? '40%' : '10%', padding: '10px 0', cursor: 'pointer' }}>
                                    <span style={{ color: '#fff', fontWeight: '300' }}>Sign Up</span>
                                </div>
                            </Stack>
                        </div>
                    </div>
            }
            <Footer />
        </div>
    )
}

export default HomeScreen;