import { Button, Divider, Drawer, FormControl, Grid, Icon, InputLabel, MenuItem, Pagination, Select, Stack, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router'
import { HTTPGetCategories } from '../../apis/categories'
import { HTTPGetProducts } from '../../apis/products'
import Footer from '../../components/footer'
import { Header } from '../../components/header'
import NavigationBar from '../../components/navigationBar'
import { CategoryTree, PriceRange, RatingTree } from '../../components/products/categoryTree'
import ProductCard from '../../components/products/productCard'
import { LoaderCard } from '../../components/utils/skeletons'
import { CENTER, Colors } from '../../utils/colors'

const SortList = ['Terkait', 'Terbaru', 'Terlaris']
const CategoryList = {
    node: '1',
    label: 'Kategori',
    child: [
        { node: '2', label: 'Lipstik', qty: '10' },
        { node: '3', label: 'Lip Cream', qty: '12' },
        { node: '4', label: 'Lip Tint', qty: '4' },
        { node: '5', label: 'Serum', qty: '8' },
        { node: '6', label: 'Sunscreen', qty: '9' },
    ]
}

const BrandList = {
    node: '1',
    label: 'Brand',
    child: [
        { node: '2', label: 'Azarine', qty: '23' },
        { node: '3', label: 'The Originote', qty: '22' },
        { node: '4', label: 'Implora', qty: '12' },
        { node: '5', label: 'Madame Gie', qty: '15' },
        { node: '6', label: 'Skintific', qty: '9' },
        { node: '7', label: 'Hanasul', qty: '7' },
    ]
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ProductListScreen = () => {
    const query = useQuery()
    const [init, setInit] = React.useState(false)
    const [sortSelect, setSortSelect] = React.useState('Terkait')
    const [onFilter, setFilter] = React.useState(false)
    const [onPrice, setPrice] = React.useState('')
    const [Products, setProducts] = React.useState([])
    const [Pages, setPages] = React.useState(1)
    const [Categories, setCategories] = React.useState([])
    const [LatestSort, setLatestSort] = React.useState<any>({})
    const [isLoading, setLoading] = React.useState(true)

    const GetProducts = async (params?: any) => {
        console.log(params, 'Params')
        setLoading(true)
        try {
            if (query.get('search') !== null) {
                const result = await HTTPGetProducts({ ...LatestSort, limit: 80, ...params, keywords: query.get('search') as string })
                const pages = result.data.meta.pagination.last_page > 1 ? Math.round(result.data.meta.pagination.total/result.data.meta.pagination.per_page) : 1
                setProducts(result.data.data)
                setLatestSort(result.data.request)
                setPages(pages)
            } else {
                const result = await HTTPGetProducts({ ...LatestSort, limit: 80, ...params })
                const pages = result.data.meta.pagination.last_page > 1 ? Math.round(result.data.meta.pagination.total/result.data.meta.pagination.per_page) : 1
                setProducts(result.data.data)
                setLatestSort(result.data.request)
                setPages(pages)
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const GetCategories = async () => {
        try {
            const result = await HTTPGetCategories()
            const newValue = result.data.data.map((item: any, index: number) => ({
                id: item.id,
                node: (index + 2).toString(),
                label: item.name,
                qty: item.products_total
            }))
            setCategories(newValue)
        } catch (error) {
            console.log(error)
        }
    }

    const ChangeProductPage = async (event: React.ChangeEvent<unknown>, value: number) => {
        console.log(value, 'Value')
        await GetProducts({page: value});
    }

    React.useEffect(() => {
        async function Initial() {
            await GetProducts()
            await GetCategories()
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <Header title="Daftar Produk" />
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={'row'} sx={{ width: '90%', paddingY: 5 }} justifyContent={'space-between'}>
                    {
                        isMobile ? null :
                            <Stack direction={'column'} gap={2} divider={<Divider />} width={'20%'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant={'body1'} color={'#000'} style={{ padding: '5px 0' }}>Filter:</Typography>
                                    <Typography variant={'body1'} color={Colors.primary}>Clear</Typography>
                                </Stack>
                                <CategoryTree
                                    ListData={{
                                        node: '1',
                                        label: 'Kategori',
                                        child: Categories
                                    }}
                                    getData={(id: any) => GetProducts({ category: id.category_id })}
                                ></CategoryTree>
                                {/* <CategoryTree ListData={BrandList} /> */}
                                <RatingTree />
                                <PriceRange />
                                <Button color='secondary' variant={'contained'} sx={{ backgroundColor: Colors.primary }}>Terapkan</Button>
                            </Stack>
                    }
                    <div style={{ width: isMobile ? '100%' : '78%' }}>
                        <Stack direction={'column'} gap={3} divider={<Divider />} width={'100%'}>
                            {
                                isMobile ?
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"}>
                                        <FormControl size="small" sx={{ width: '49%' }}>
                                            <InputLabel id="filter-label">Urutkan</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                value={sortSelect}
                                                label="Urutkan"
                                                MenuProps={{ sx: { height: 300 } }}
                                                onChange={(e) => setSortSelect(e.target.value)}
                                            >
                                                {
                                                    SortList.map((item, index) => (
                                                        <MenuItem value={item} key={index}>{item}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                        <div style={{ backgroundColor: '#fff', ...CENTER, borderRadius: 5, width: '49%' }}>
                                            <Stack onClick={() => setFilter(true)} direction={"row"} sx={{ cursor: 'pointer', padding: '5px 0', width: '100%' }} alignItems={'center'} justifyContent={'flex-end'} gap={1}>
                                                <Typography variant={'body1'} color={Colors.disable}>Filter</Typography>
                                                <Icon fontSize={"small"} sx={{ color: Colors.disable }}>tune</Icon>
                                            </Stack>
                                        </div>
                                    </Stack>
                                    :
                                    <Stack direction={'row'} gap={2} alignItems={'center'}>
                                        <Typography variant={'body1'}>Urutkan:</Typography>
                                        {
                                            SortList.map((item, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => setSortSelect(item)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: item === sortSelect ? '#fff' : '#000',
                                                        backgroundColor: item === sortSelect ? Colors.primary : '#fff',
                                                        ...CENTER,
                                                        padding: '5px 25px',
                                                        borderRadius: 5,
                                                        border: item === sortSelect ? '0px' : '1px solid #999'
                                                    }}
                                                >
                                                    <Typography variant={'body1'} color={'inherit'}>{item}</Typography>
                                                </div>
                                            ))
                                        }
                                        {/* <div style={{ color: '#000', backgroundColor: '#fff', ...CENTER, padding: '5px 25px', borderRadius: 5, border: '1px solid #999' }}>
                                            <Typography variant={'body1'} color={'inherit'}>Harga</Typography>
                                        </div> */}
                                        <FormControl sx={{ minWidth: 120 }} size={'small'}>
                                            <InputLabel id="price-id">Harga</InputLabel>
                                            <Select
                                                labelId="price-id"
                                                value={onPrice}
                                                label="Harga"
                                                onChange={(val) => setPrice(val.target.value)}
                                            >
                                                <MenuItem value={'Termurah'}>Termurah</MenuItem>
                                                <MenuItem value={'Termahal'}>Termahal</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                            }
                            {
                                isLoading ?
                                    <LoaderCard></LoaderCard>
                                    :
                                    <Grid container spacing={isMobile ? 1 : 3}>
                                        {
                                            Products.map((item, index) => (
                                                <Grid item md={3} xs={6} key={index}>
                                                    <ProductCard item={item} />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                            }
                            <div style={{ width: '100%', ...CENTER }}>
                                <Pagination count={Pages} defaultPage={1} onChange={ChangeProductPage} variant="outlined" shape="circular" />
                            </div>
                        </Stack>
                    </div>
                </Stack>
            </div>
            <Drawer
                anchor="right"
                open={onFilter}
                onClose={() => setFilter(false)}
                PaperProps={{ sx: { width: '80vw', padding: 2 } }}
            >
                <Stack direction={'column'} gap={2} divider={<Divider />} width={'100%'} marginTop={'80px'}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant={'body1'} color={'#000'} style={{ padding: '5px 0' }}>Filter:</Typography>
                        <Typography variant={'body1'} color={Colors.primary}>Clear</Typography>
                    </Stack>
                    <CategoryTree ListData={CategoryList} />
                    <CategoryTree ListData={BrandList} />
                    <RatingTree />
                    <PriceRange />
                    <Button onClick={() => setFilter(false)} color='secondary' variant={'contained'} sx={{ backgroundColor: Colors.primary }}>Terapkan</Button>
                </Stack>
                <Toolbar></Toolbar>
            </Drawer>
            <Footer></Footer>
        </div >
    )
}

export default ProductListScreen;