import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const SliderTempSlice = createSlice({
  name: "Slider_Temp",
  initialState: {
    data: { id: 0, type: '' },
  },
  reducers: {
    setSliderTemp(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
    },
  },
});

export const { setSliderTemp } = SliderTempSlice.actions;
export const SliderTempData = SliderTempSlice.reducer;
