import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FreeMode } from 'swiper/modules';
import ProductCard from '../products/productCard';
import { Divider, Stack, Typography } from '@mui/material';
import { Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';

const BestSeller = (param: { data: any[] }) => {
    const navigate = useNavigate()
    return (
        <Stack direction={'column'} gap={2} style={{ width: '100%' }}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <h2 style={{ margin: 0, color: '#000' }}>BESTSELLERS</h2>
                <Typography
                    onClick={() => navigate('/product/list')}
                    variant={"body1"}
                    color={Colors.primary}
                    sx={{
                        fontWeight: 500,
                        cursor: 'pointer',
                        '&:hover': {
                            fontWeight: 600,
                        }
                    }}
                >Lihat Semua</Typography>
            </Stack>
            <Swiper
                slidesPerView={isMobile ? 1.5 : 5.8}
                modules={[FreeMode]}
                freeMode
                spaceBetween={15}
                style={{ width: '100%', padding: '10px 0' }}
            >
                {
                    param.data.map((item, index) => (
                        item.is_best_seller === 1 ?
                            <SwiperSlide
                                key={index}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <ProductCard item={item} />
                            </SwiperSlide>
                            : null
                    ))
                }
            </Swiper>
            <Divider />
        </Stack>
    )
}

export default BestSeller;