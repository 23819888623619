import { AxiosMethod } from "../utils/interceptors";
const url = "products";

export function HTTPGetProducts(param: {
  limit: number;
  brand?: string;
  category?: string;
  keywords?: string;
  page?: number
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}/search`, {
        params: {
          limit: param.limit,
          brand: param.brand,
          page: param.page ?? 1,
          category: param.category,
          keyword: param.keywords,
        },
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetBestSellers(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}/best_seller`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetProductById(param: { id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}/${param.id}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetProductBySlug(param: { slug: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${url}/detail/${param.slug}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
