import { Skeleton, Stack } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'

export const HomeSkeleton = () => {
    return (
        <Stack direction={'column'} gap={5}>
            <Skeleton animation={"wave"} variant="rounded" width={'100%'} height={isMobile ? '25vh' : '50vh'} />
            <Skeleton animation={"wave"} variant="rounded" width={'100%'} height={isMobile ? '25vh' : '50vh'} />
            <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent={"space-between"}>
                {
                    [...Array(6)].map((item, index) => (
                        <Skeleton animation={"wave"} variant="rounded" key={index} width={'16%'} height={isMobile ? '15vh' : '40vh'} />
                    ))
                }
            </Stack>
            <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent={"space-between"}>
                {
                    [...Array(6)].map((item, index) => (
                        <Skeleton animation={"wave"} variant="rounded" key={index} width={'16%'} height={isMobile ? '15vh' : '40vh'} />
                    ))
                }
            </Stack>
        </Stack>
    )
}