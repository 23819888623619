import { AxiosMethod } from "../utils/interceptors";
const url = "wishlists";

export function HTTPGetWishLists(param: { token: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAddWishList(param: {
  token: string;
  product_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/add`, {
        token: param.token,
        product_id: param.product_id,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteWishList(param: {
  token: string;
  id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/remove`, {
        token: param.token,
        id: param.id,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDetailWishList(param: {
  token: string;
  product_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/detail`, {
        token: param.token,
        product_id: param.product_id,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
