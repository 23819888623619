import { AxiosRequestConfig } from "axios";
import { AxiosMethod } from "../utils/interceptors";
const url = "customer/profile";

export function HTTPUpdateProfile(param: {
  token: any;
  username: string;
  email: string;
  name: string;
  phone_number: string;
  dob: string;
  gender: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/update`, {
        token: param.token,
        username: param.username,
        email: param.email,
        name: param.name,
        phone_number: param.phone_number,
        dob: param.dob,
        gender: param.gender,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateProfilePicture(param: {
  token: string;
  profile_picture: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/update_profile_picture`, {
        token: param.token,
        profile_picture: param.profile_picture,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
