import { Button, Icon, Stack, Typography } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { ThreeDots } from "react-loader-spinner";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { HTTPGetArticleDetail, HTTPGetArticles } from "../../apis/article";
import { Icons } from "../../assets/icons";
import { Images } from "../../assets/images";
import { ArticleListSideBar } from "../../components/article/sideBar";
import Footer from "../../components/footer";
import { Header } from "../../components/header";
import NavigationBar from "../../components/navigationBar";
import { CENTER, Colors } from "../../utils/colors";
import Page from "../../routes/routerTitle";

const ArticleDetailScreen = () => {
  const { slug } = useParams();
  const [isLoading, setLoading] = React.useState(true);
  const [isDataLoading, setDataLoading] = React.useState(true);
  const [ArticleData, setArticleData] = React.useState<any>({});
  const [ArticleList, setArticleList] = React.useState<any>([]);
  const [init, setInit] = React.useState(false);

  const GetArticles = async () => {
    setDataLoading(true);
    try {
      const result = await HTTPGetArticles({});
      setArticleList(result.data.data);
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
      toast.error("Terjadi Kesalahan");
      console.error(error);
    }
  };

  const GetArticleDetail = async () => {
    setLoading(true);
    try {
      const result = await HTTPGetArticleDetail({ slug });
      document.title = "Angelo Skin | " + String(result.data.data.title_id);
      setArticleData(result.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Terjadi Kesalahan");
      console.error(error);
    }
  };

  React.useEffect(() => {
    async function Initial() {
      await GetArticleDetail();
      await GetArticles();
    }

    Initial().then().catch();
  }, [init]);

  return (
    <div style={{ width: "100%", backgroundColor: "#fff" }}>
      {isMobile ? null : <NavigationBar />}
      <Header title={String(ArticleData.title_id)} />
      <div style={{ width: "100%", ...CENTER, marginBottom: "5%" }}>
        <Stack
          direction={isMobile ? "column" : "row"}
          sx={{ width: "90%", paddingY: 5 }}
          justifyContent={"space-between"}
          gap={3}
        >
          {isMobile ? null : (
            <div style={{ width: "25%" }}>
              <ArticleListSideBar
                data={ArticleList.filter(
                  (item: any) => item.id !== ArticleData.id
                )}
                isLoading={isDataLoading}
              />
            </div>
          )}
          {isLoading ? (
            <div
              style={{
                width: "100%",
                overflow: isMobile ? "auto" : "none",
                backgroundColor: "#fff",
                padding: "10% 0",
              }}
            >
              <Stack
                direction={"column"}
                gap={0}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img
                  src={Images.logo}
                  style={{
                    width: "15vw",
                    height: "auto",
                    objectFit: "contain",
                  }}
                  alt=""
                />
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color={Colors.primary}
                  ariaLabel="three-dots-loading"
                  visible={true}
                />
              </Stack>
            </div>
          ) : (
            <div
              style={{
                width: isMobile ? "100%" : "73%",
                backgroundColor: "#fff",
              }}
            >
              <Stack direction={"column"} gap={3}>
                <img
                  src={ArticleData.thumbnail}
                  alt=""
                  style={{
                    width: "100%",
                    aspectRatio: 16 / 9,
                    objectFit: "cover",
                    borderRadius: 15,
                  }}
                />
                {/* <Typography variant='subtitle2' color={'#999'}>{item.}</Typography> */}
                <Typography variant="h6" fontWeight={"600"}>
                  {ArticleData.title_id}
                </Typography>
                {/* <Typography variant='body1' whiteSpace={'pre-line'} textAlign={"justify"}>
                                        {`Nostrud ullamco laborum nulla labore. Duis voluptate aute cupidatat enim labore ipsum et labore ea culpa proident proident est. Fugiat pariatur amet aliquip fugiat cupidatat excepteur officia consectetur dolore est cupidatat sint aute. Mollit Lorem incididunt nisi minim pariatur laboris nulla nulla sunt nisi. Adipisicing esse quis proident exercitation eu eiusmod aute. Ipsum Lorem labore esse amet ea nisi id. Incididunt qui ea amet tempor duis quis enim sint. Reprehenderit dolore aliquip cupidatat aliquip excepteur eiusmod ea ullamco dolor officia ut amet officia ad. Aliqua pariatur quis ad consequat minim.\n
                                Non cupidatat velit dolore ad elit laborum nulla. Anim reprehenderit deserunt magna id incididunt dolore nulla nisi aute dolore proident cillum. Ipsum incididunt nulla laboris amet ullamco mollit reprehenderit sint. Eu ea consequat ad mollit et qui aute. Commodo consequat aute fugiat enim aliqua incididunt adipisicing pariatur anim eiusmod irure dolor excepteur. Aliqua commodo voluptate nulla magna anim eiusmod ex sunt est incididunt sit occaecat. Dolor Lorem culpa proident elit dolore non reprehenderit laborum tempor consequat culpa labore duis consectetur. Ad mollit in mollit enim aliqua aliqua cupidatat laboris laborum anim nulla proident. Lorem amet anim consectetur sunt tempor sit occaecat proident non sunt.\n
                                Elit tempor nostrud magna qui proident sit cupidatat magna irure magna. Nulla mollit mollit deserunt cupidatat commodo ea consectetur reprehenderit nisi laborum. Amet et minim culpa Lorem proident sint eu ex. Ullamco dolore adipisicing qui magna voluptate nisi laborum ad aliquip eiusmod voluptate. Nisi adipisicing anim adipisicing ex duis pariatur dolor tempor incididunt ex. Culpa do qui aute anim excepteur cupidatat duis elit ullamco. Eu nostrud eiusmod duis nisi. Laborum et ad voluptate ea sit.
                                `}
                                    </Typography> */}
                <div
                  dangerouslySetInnerHTML={{ __html: ArticleData.content_id }}
                  style={{ textAlign: "justify" }}
                ></div>
                <Stack
                  direction={isMobile ? "column" : "row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                  alignItems={"flex-start"}
                  gap={3}
                >
                  <Stack
                    direction={"column"}
                    flexWrap={"wrap"}
                    width={isMobile ? "100%" : "40%"}
                  >
                    <Typography variant="body1" fontWeight={"600"}>
                      Kategori:
                    </Typography>
                    <Typography variant="subtitle2">
                      {ArticleData.category.name_id}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={isMobile ? "column" : "row"}
                    gap={2}
                    alignItems={isMobile ? "flex-start" : "center"}
                  >
                    <Typography variant="body1" fontWeight={"600"}>
                      Bagikan:
                    </Typography>
                    <Stack direction={"row"} gap={2} alignItems={"center"}>
                      <img
                        src={Icons.facebookDark}
                        style={{ width: 30, height: 30, objectFit: "contain" }}
                        alt=""
                      />
                      <img
                        src={Icons.twitterDark}
                        style={{ width: 30, height: 30, objectFit: "contain" }}
                        alt=""
                      />
                      <img
                        src={Icons.instagramDark}
                        style={{ width: 30, height: 30, objectFit: "contain" }}
                        alt=""
                      />
                      <img
                        src={Icons.whatsappDark}
                        style={{ width: 30, height: 30, objectFit: "contain" }}
                        alt=""
                      />
                    </Stack>
                  </Stack>
                </Stack>
                {/* <Stack direction={"row"} justifyContent={'space-between'} width={'100%'} sx={{ color: '#000' }}>
                                        <Button variant={'outlined'} color={"inherit"}>
                                            <Stack direction={"row"} gap={1} alignItems={'center'} paddingY={1}>
                                                <Icon fontSize={'small'} sx={{ color: '#000' }}>arrow_back</Icon>
                                                {
                                                    isMobile ? null :
                                                        <Typography variant={"body2"}>Artikel Sebelumnya</Typography>
                                                }
                                            </Stack>
                                        </Button>
                                        <Button variant={'outlined'} color={"inherit"}>
                                            <Stack direction={"row"} gap={1} alignItems={'center'} paddingY={1}>
                                                {
                                                    isMobile ? null :
                                                        <Typography variant={"body2"}>Artikel Selanjutnya</Typography>
                                                }
                                                <Icon fontSize={'small'} sx={{ color: '#000' }}>arrow_forward</Icon>
                                            </Stack>
                                        </Button>
                                    </Stack> */}
              </Stack>
            </div>
          )}
          {isMobile ? (
            <div style={{ width: "100%" }}>
              <ArticleListSideBar
                data={ArticleList}
                isLoading={isDataLoading}
              />
            </div>
          ) : null}
        </Stack>
      </div>
      <Footer />
    </div>
  );
};

export default ArticleDetailScreen;
