import { Button, Dialog, DialogContent, Divider, Icon, Slide, Stack, Step, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, Stepper, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { Colors } from '../../utils/colors';
import { styled } from '@mui/material/styles';
import { Icons } from '../../assets/icons';
import { isMobile } from 'react-device-detect';
import { CancelModal, ReturnModal } from './orderSection';
import moment from 'moment';
import { Images } from '../../assets/images';
import { FormatCurrency } from '../../utils/utils';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: isMobile ? 20 : 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: '#949494',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: '#949494',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean, error?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: isMobile ? 40 : 50,
    height: isMobile ? 40 : 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: Colors.success,
    }),
    ...(ownerState.completed && {
        backgroundColor: Colors.success,
    }),
    ...(ownerState.error && {
        backgroundColor: Colors.error,
    }),
}));

function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className, error } = props;
    const icons: { [index: string]: React.ReactElement } = {
        1: <Typography variant={isMobile ? 'body1' : "h6"} fontWeight={'600'} color={'#fff'}>1</Typography>,
        2: <Typography variant={isMobile ? 'body1' : "h6"} fontWeight={'600'} color={'#fff'}>2</Typography>,
        3: <Typography variant={isMobile ? 'body1' : "h6"} fontWeight={'600'} color={'#fff'}>3</Typography>,
        4: <Typography variant={isMobile ? 'body1' : "h6"} fontWeight={'600'} color={'#fff'}>4</Typography>,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active, error }} className={className}>
            {
                completed ?
                    <Icon fontSize='medium' sx={{ color: '#fff' }}>check</Icon>
                    :
                    icons[String(props.icon)]
            }
        </ColorlibStepIconRoot>
    );
}

const status = [
    { status: 'DRAFT', color: '#0068ff', label: 'Menunggu Pembayaran' },
    { status: 'PENDING', color: '#0068ff', label: 'Menunggu Pembayaran' },
    { status: 'WAITING_DELIVERY', color: '#6a6a6a', label: 'Produk Sedang Dikemas' },
    { status: 'DONE', color: '#24bb33', label: 'Produk Telah Diterima' },
    { status: 'DELIVERING', color: '#f49918', label: 'Produk Sedang Dikirim' },
    { status: 'DELIVERED', color: '#cb42d4', label: 'Pemeriksaan Resi' },
    { status: 'EXPIRED', color: '#000000', label: 'Pembayaran Kedaluwarsa' },
    { status: 'CANCELED', color: '#bb2023', label: 'Pembayaran Dibatalkan' },
    { status: 'RETURN_REQUESTED', color: '#8e54a0', label: 'Pengajuan Pengembalian' },
    { status: 'RETURN_REJECTED', color: '#8e54a0', label: 'Pengajuan Pengembalian' },
    { status: 'RETURN_APPROVED', color: '#8e54a0', label: 'Pengajuan Pengembalian' },
]

const steps = ['Pesanan Dibuat', 'Pesanan Dibayarkan', 'Pesanan Dikirim', 'Pesanan Diterima'];
const cancelSteps = ['Pesanan Dibuat', 'Pesanan Dibatalkan'];
export const OrderHistoryModal = (param: { isOpen: boolean, onClose: any, item: any }) => {
    const [Status, setStatus] = React.useState('')
    const [isReturn, setReturn] = React.useState(false)
    const [isCancel, setCancel] = React.useState(false)

    React.useEffect(() => {
        if (param.isOpen === true) {
            setStatus(param.item.status)
        }
    }, [param.isOpen])

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={isMobile}
            scroll='body'
            onClose={param.onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: '60vw' }, }}
        >
            <DialogContent>
                <Stack direction={"column"} gap={3} width={'100%'} alignItems={"flex-start"}>
                    <Stack direction={'row'} alignItems={"center"} justifyContent={"space-between"} width={'100%'}>
                        <Button onClick={param.onClose} variant={"text"} color={"secondary"}>
                            <Stack direction={'row'} alignItems={"center"} gap={1}>
                                <Icon fontSize={'small'} sx={{ color: Colors.disable }}>arrow_back</Icon>
                                <Typography variant={"body1"} fontWeight={'600'} color={Colors.disable}>Kembali</Typography>
                            </Stack>
                        </Button>
                        <Typography variant={isMobile ? 'body1' : "h6"} fontWeight={'600'} color={Colors.primary}>{status.find((val) => val.status === Status)?.label}</Typography>
                    </Stack>
                    <div style={{ height: 1, width: '100%', backgroundColor: '#ddd' }}></div>
                    {Status === 'CANCELED' ?
                        <Stepper
                            sx={{ width: '100%' }}
                            alternativeLabel
                            activeStep={1}
                            connector={<ColorlibConnector />}
                        >
                            {cancelSteps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel error={index === 1} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        :
                        <Stepper
                            sx={{ width: '100%' }}
                            alternativeLabel
                            activeStep={
                                Status === 'PENDING' ? 1 :
                                    Status === 'WAITING_DELIVERY' ? 2 :
                                        Status === 'DELIVERING' ? 3 :
                                            Status === 'DELIVERED' ? 4 :
                                                Status === 'DONE' ? 5 :
                                                    0
                            }
                            connector={<ColorlibConnector />}
                        >
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    }
                    <div style={{ borderRadius: 10, border: `1px solid ${Colors.disable}`, width: '100%' }}>
                        <Stack direction={"column"} gap={1} padding={2}>
                            <Typography variant="h6" fontWeight={'600'}>Alamat Pengiriman</Typography>
                            <Typography variant="body1" fontWeight={'600'}>{param.item.origin.customer.name}</Typography>
                            <Typography variant="body1">
                                {`${param.item.origin.address}, ${param.item.origin.subdistrict.type} ${param.item.origin.subdistrict.name}, ${param.item.origin.city.type} ${param.item.origin.city.name}, ${param.item.origin.province.name} (${param.item.origin.postal_code})`}
                            </Typography>
                            <div>
                                <Typography variant="subtitle2" color={Colors.disable}>No. Telepon</Typography>
                                <Typography variant="body1">+62{param.item.origin.phone}</Typography>
                            </div>
                            <div>
                                <Typography variant="subtitle2" color={Colors.disable}>Email</Typography>
                                <Typography variant="body1">{param.item.origin.customer.email}</Typography>
                            </div>
                        </Stack>
                    </div>
                    <Stack
                        direction={"column"}
                        gap={1}
                        sx={{ borderRadius: 3, border: `1px solid ${Colors.disable}`, width: '100%' }}
                    >
                        <Stack direction={isMobile ? 'column' : 'row'} alignItems={'flex-start'} justifyContent={"space-between"} gap={3} padding={2}>
                            <Stack direction={'column'} gap={2} alignItems={"flex-start"} width={isMobile ? '100%' : '70%'} >
                                {
                                    param.item.details.map((item: any, index: number) => (
                                        <Stack key={index} direction={isMobile ? 'column' : "row"} width={'100%'} gap={3}>
                                            <img
                                                style={{ width: isMobile ? '100%' : 120, aspectRatio: isMobile ? 16 / 9 : 1, objectFit: 'cover', borderRadius: 5 }}
                                                src={item.product.galleries[0].image_path}
                                                alt=""
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = Images.noImageProduct;
                                                }}
                                            />
                                            <Stack direction={"column"} gap={2} width={isMobile ? '100%' : '50%'}>
                                                <Typography variant={'body1'} fontWeight={'600'}>{item.product.name_id}</Typography>
                                                <Typography variant={'body2'} fontWeight={'300'}>{item.quantity} barang x Rp{FormatCurrency(item.price)}</Typography>
                                            </Stack>
                                        </Stack>
                                    ))
                                }
                            </Stack>
                            <Stack direction={"column"} width={isMobile ? '100%' : '30%'} gap={2}>
                                <Stack direction={isMobile ? 'row' : "column"} alignItems={isMobile ? 'center' : "flex-end"} justifyContent={isMobile ? 'space-between' : undefined}>
                                    <Typography variant="subtitle2" color={Colors.disable}>Tanggal Pemesanan</Typography>
                                    <Typography variant={'body1'} fontWeight={'600'}>{moment(param.item.status_log[0].created_at).format('DD/MM/YYYY')}</Typography>
                                </Stack>
                                <Stack direction={isMobile ? 'row' : "column"} alignItems={isMobile ? 'center' : "flex-end"} justifyContent={isMobile ? 'space-between' : undefined}>
                                    <Typography variant="subtitle2" color={Colors.disable}>Nomor Pesanan</Typography>
                                    <Typography variant={'body1'} fontWeight={'600'}>#{param.item.order_number}</Typography>
                                </Stack>
                                <Stack direction={isMobile ? 'row' : "column"} alignItems={isMobile ? 'center' : "flex-end"} justifyContent={isMobile ? 'space-between' : undefined}>
                                    <Typography variant="subtitle2" color={Colors.disable}>Subtotal Produk</Typography>
                                    <Typography variant="body1" fontWeight={'600'}>{param.item.subtotal_text}</Typography>
                                </Stack>
                                <Stack direction={isMobile ? 'row' : "column"} alignItems={isMobile ? 'center' : "flex-end"} justifyContent={isMobile ? 'space-between' : undefined}>
                                    <Typography variant="subtitle2" color={Colors.disable}>Diskon</Typography>
                                    <Typography variant="body1" fontWeight={'600'} color={"error"}>-{param.item.discount_text}</Typography>
                                </Stack>
                                <Stack direction={isMobile ? 'row' : "column"} alignItems={isMobile ? 'center' : "flex-end"} justifyContent={isMobile ? 'space-between' : undefined}>
                                    <Typography variant="subtitle2" color={Colors.disable}>Total Pesanan</Typography>
                                    <Typography variant="body1" fontWeight={'600'}>{param.item.total_text}</Typography>
                                </Stack>
                                <Stack direction={isMobile ? 'row' : "column"} alignItems={isMobile ? 'center' : "flex-end"} justifyContent={isMobile ? 'space-between' : undefined}>
                                    <Typography variant="subtitle2" color={Colors.disable}>Metode Pembayaran</Typography>
                                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                                        <img
                                            src={
                                                param.item.payment_method === 'bca' ? Icons.bca :
                                                    param.item.payment_method === 'bni' ? Icons.bni :
                                                        param.item.payment_method === 'bri' ? Icons.bri :
                                                            param.item.payment_method === 'mandiri' ? Icons.mandiri :
                                                                param.item.payment_method === 'bsi' ? Icons.bsi :
                                                                    param.item.payment_method === 'permata' ? Icons.permata :
                                                                        Icons.visa
                                            }
                                            style={{ width: 30, aspectRatio: 1, objectFit: 'contain' }}
                                            alt=""
                                        />
                                        <Typography variant={'body1'} fontWeight={"600"} sx={{ textTransform: 'uppercase' }}>BANK {param.item.payment_method}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    {
                        Status === 'WAITING_DELIVERY' || Status === 'DRAFT' ?
                            <Button onClick={() => setCancel(true)} color={'error'} variant={"contained"}>Batalkan Transaksi</Button>
                            :
                            Status === 'PENDING' ?
                                <Button onClick={() => window.open(param.item.invoice.invoice_url, '_self')} color={'primary'} variant={"contained"}>Selesaikan Pembayaran</Button>
                                :
                                Status === 'DONE' ?
                                    <Stack direction={'row'} alignItems={'center'} gap={2}>
                                        <Button onClick={() => setReturn(true)} color={'error'} variant={"contained"}>Ajukan Pengembalian Barang</Button>
                                        <Button color={'secondary'} sx={{ backgroundColor: Colors.primary }} variant={"contained"}>Barang Diterima</Button>
                                    </Stack>
                                    :
                                    Status === 'RETURN_REQUESTED' || Status === 'RETURN_REJECTED' || Status === 'RETURN_APPROVED' ?
                                        <div style={{ borderRadius: 10, border: `1px solid ${Colors.disable}`, width: '100%' }}>
                                            <Stack
                                                direction={"column"}
                                                gap={1.5}
                                                sx={{ padding: 2 }}
                                            >
                                                <Stack>
                                                    <Typography variant="h6" fontWeight={'600'}>Pengajuan Pengembalian</Typography>
                                                    <Typography variant="body1" color={'#999'}>Anda telah melakukan pengajuan pengembalian produk, mohon tunggu hingga admin melakukan persetujuan untuk dilakukan proses yang selanjutnya.</Typography>
                                                </Stack>
                                                <Stack>
                                                    <Typography variant="body2">Status Pengajuan</Typography>
                                                    <Typography variant="body1" color={Colors.error}>Pengajuan Ditolak</Typography>
                                                </Stack>
                                                <Stack>
                                                    <Typography variant="body2">Alasan</Typography>
                                                    <Typography variant="body1" color={Colors.error}>Barang tidak terbukti rusak dan masih bisa dipakai.</Typography>
                                                </Stack>
                                            </Stack>
                                        </div>
                                        : null
                    }
                </Stack>
            </DialogContent >
            <ReturnModal
                isOpen={isReturn}
                onClose={() => setReturn(false)}
                item={param.item.id}
                getData={param.onClose}
            />
            <CancelModal
                isOpen={isCancel}
                onClose={() => setCancel(false)}
                item={param.item.id}
                getData={() => setStatus('CANCELED')}
            />
        </Dialog >
    )
}