import { Typography } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { CENTER } from '../utils/colors'

export const Header = (param: { title: string }) => {
    return (
        <div style={{
            width: '100%',
            position: 'relative',
            backgroundImage: 'url(https://cdn.britannica.com/35/222035-050-C68AD682/makeup-cosmetics.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', width: '100%', ...CENTER, height: '30vh' }}>
                <Typography variant={isMobile ? 'h4' : 'h3'} fontWeight={"400"} color={'#fff'}>{param.title}</Typography>
            </div>
        </div>
    )
}