import { Button, Dialog, DialogContent, Divider, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { useNavigate } from 'react-router';
import secureLocalStorage from 'react-secure-storage';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const LogoutModal = (param: { isOpen: boolean, onClose: any }) => {
    const navigate = useNavigate()
    const onLogout = () => {
        localStorage.setItem('isLogin', 'false')
        secureLocalStorage.setItem('token', '')
        navigate('/auth/login')
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <Stack direction={"column"} gap={3} paddingX={3}>
                    <Typography variant={"h6"} fontWeight={'600'} textAlign={'center'}>Perhatian!</Typography>
                    <Typography variant={"body1"} textAlign={'center'}>Anda yakin untuk keluar?</Typography>
                    <Divider />
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={2}
                    >
                        <Button color={"secondary"} variant={"contained"} onClick={onLogout}>Yakin</Button>
                        <Button color={"error"} variant={"text"} onClick={param.onClose}>Tidak</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}