import { Grid, Skeleton, Stack } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'

export const AddressSkeleton = () => {
    return (
        <Stack direction={'column'} gap={5}>
            <Grid container spacing={2}>
                {
                    [...Array(9)].map((item, index) => (
                        <Grid md={4} item key={index}>
                            <Skeleton animation={"wave"} variant="rounded" width={'100%'} height={isMobile ? '15vh' : '40vh'} />
                        </Grid>
                    ))
                }
            </Grid>
        </Stack>
    )
}