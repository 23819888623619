import React from 'react'
import Countdown from 'react-countdown';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FreeMode } from 'swiper/modules';
import ProductCard from '../products/productCard';
import { Divider, Stack, Typography } from '@mui/material';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';

const bgStyle = { backgroundColor: Colors.primary, height: 35, aspectRatio: 1, ...CENTER, borderRadius: 5 }
const RenderTime = ({ days, hours, minutes, seconds }: any) => {
    return (
        <Stack direction={'row'} gap={0.5} alignItems={'center'}>
            <div style={{ ...bgStyle }}>
                <b><span style={{ color: '#fff' }}>{days.toString()}</span></b>
            </div>
            <b><span style={{ color: '#000' }}>:</span></b>
            <div style={{ ...bgStyle }}>
                <b><span style={{ color: '#fff' }}>{hours.toString()}</span></b>
            </div>
            <b><span style={{ color: '#000' }}>:</span></b>
            <div style={{ ...bgStyle }}>
                <b><span style={{ color: '#fff' }}>{minutes.toString()}</span></b>
            </div>
            <b><span style={{ color: '#000' }}>:</span></b>
            <div style={{ ...bgStyle }}>
                <b><span style={{ color: '#fff' }}>{seconds.toString()}</span></b>
            </div>
        </Stack>
    )
};

const FlashSale = (param: { data: any[] }) => {
    const navigate = useNavigate()
    return (
        <Stack direction={'column'} gap={2} style={{ width: '100%' }}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} gap={isMobile ? 0 : 2}>
                    <h2 style={{ margin: 0, color: '#000' }}>PRODUK TERBARU</h2>
                    {/* <Countdown date={'2023-12-01T00:00:00'} renderer={RenderTime} /> */}
                </Stack>
                <Typography
                    onClick={() => navigate('/product/list')}
                    variant={"body1"}
                    color={Colors.primary}
                    sx={{
                        fontWeight: 500,
                        cursor: 'pointer',
                        '&:hover': {
                            fontWeight: 600,
                        }
                    }}
                >Lihat Semua</Typography>
            </Stack>
            <Swiper
                slidesPerView={isMobile ? 1.5 : 5.8}
                modules={[FreeMode]}
                freeMode
                spaceBetween={15}
                style={{ width: '100%', padding: '10px 0' }}
            >
                {
                    param.data.map((item, index) => (
                        <SwiperSlide
                            key={index}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <ProductCard item={item} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <Divider />
        </Stack>
    )
}

export default FlashSale;