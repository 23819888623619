import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import { SwiperButtonNext, SwiperButtonPrev } from './swiperCustom';
import './style.css'
import { Images } from '../../assets/images';

const Certs = [Images.cert1, Images.cert2, Images.cert3, Images.cert4]
const CertificateSwiper = (param: { data: any[] }) => {
    return (
        <div style={{ width: '100%%' }}>
            <Swiper
                pagination={{
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index: number, className: string) {
                        return '<div class="bg-bullet"><span class="' + className + '"></span></div';
                    },
                }}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: true
                }}
                loop={true}
                slidesPerView={1}
                navigation={false}
                modules={[Pagination, Autoplay, Navigation]}
                style={{ width: '100%', height: '100%', borderRadius: 20, position: 'relative' }}
            >
                {
                    param.data.map((item: { id: string, name: string, slug: string, image: string }, index: number) => (
                        <SwiperSlide
                            key={index}
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                cursor: 'pointer',
                                textAlign: 'left',
                            }}>
                            <img
                                src={item.image}
                                style={{
                                    height: '100%',
                                    width: "100%",
                                    objectFit: "contain",
                                    position: 'relative'
                                }}
                                alt={index.toString()}
                            ></img>
                        </SwiperSlide>
                    ))
                }
                <div className="swiper-pagination"></div>
                <div style={{ position: 'absolute', left: '0', top: '50%', zIndex: 2 }}>
                    <SwiperButtonPrev />
                </div>
                <div style={{ position: 'absolute', right: '0', top: '50%', zIndex: 2 }}>
                    <SwiperButtonNext />
                </div>
            </Swiper>
        </div >
    )
}

export default CertificateSwiper;