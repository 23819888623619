import { AxiosMethod } from "../utils/interceptors";

export function HTTPCalculatePrice(param: {
  token: string;
  voucher?: string;
  shipping_info?: any;
  payment_method?: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post("calculate_price", {
        token: param.token,
        voucher: param.voucher,
        shipping_info: param.shipping_info,
        payment_method: param.payment_method,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPCreateOrder(param: {
  token: string;
  voucher: string;
  shipping_info: any;
  payment_method: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post("orders", {
        token: param.token,
        voucher: param.voucher,
        shipping_info: param.shipping_info,
        payment_method: param.payment_method,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
