import React from 'react'
import Footer from '../../components/footer'
import NavigationBar from '../../components/navigationBar'
import { CENTER } from '../../utils/colors'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import { HTTPGetSliderAuthById, HTTPGetSliderHomeById } from '../../apis/slider'
import { useSelector } from 'react-redux'
import { RootState } from '../../stores/rootReducer'

interface IBanner {
    content_en: string;
    content_id: string;
    title_en: string;
    title_id: string;
    thumbnail: string;
    id: number;
    is_highlighted: number;
}

const BannerScreen = () => {
    const SliderRedux = useSelector((state: RootState) => state.slider_data.data)
    const [init, setInit] = React.useState(false)
    const [BannerData, setBannerData] = React.useState<IBanner>()

    const GetBannerDetail = async () => {
        try {
            if (SliderRedux.type === 'home') {
                const result = await HTTPGetSliderHomeById({ id_banner: SliderRedux.id })
                setBannerData(result.data.data)
            } else {
                const result = await HTTPGetSliderAuthById({ id_banner: SliderRedux.id })
                setBannerData(result.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        async function APIList() {
            GetBannerDetail()
        }

        APIList().then().catch()
    }, [init])

    return (
        <div style={{ backgroundColor: '#fff', width: '100%' }}>
            <NavigationBar />
            <img src={BannerData?.thumbnail} alt="" style={{ width: '100%', aspectRatio: 10 / 2, objectFit: 'cover' }} />
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={"column"} gap={3} sx={{ width: '90%', paddingY: 5 }}>
                    <Typography variant="h5" color="#000" fontWeight={'600'}>{BannerData?.title_id}</Typography>
                    <div dangerouslySetInnerHTML={{ __html: BannerData?.content_id as string }}></div>
                </Stack>
            </div>
            <Footer />
        </div>
    )
}

export default BannerScreen;