import { Divider, Icon, Stack, Typography } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Images } from "../../../assets/images";
import { setProductDetail } from "../../../stores/data/product";
import { CENTER, Colors, Truncate } from "../../../utils/colors";
import { FormatCurrency } from "../../../utils/utils";

const DetailProductCard = (param: { item: any }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDetail = () => {
    dispatch(setProductDetail({ data: { id: param.item.id } }));
    // navigate(`/product/detail/${param.item.slug}`)
    // window.location.reload()
    // window.scrollTo(0, 0)
  };

  return (
    <a
      href={`/product/detail/${param.item.slug}`}
      style={{ textDecoration: "none" }}
    >
      <div
        style={{
          width: "100%",
          height: "auto",
          position: "relative",
          overflow: "hidden",
          cursor: "pointer",
        }}
        onClick={onDetail}
      >
        <div style={{ padding: 10 }}>
          <Stack
            direction={"column"}
            sx={{
              width: "100%",
              height: "100%",
              transition: "all .3s",
              "&:hover": {
                transform: "scale(1.05)",
                transition: "all .3s",
              },
            }}
            gap={1}
          >
            <img
              src={
                param.item.galleries.length === 0
                  ? Images.noImageProduct
                  : param.item.galleries[0].image_path
              }
              alt=""
              style={{
                width: "100%",
                aspectRatio: 1,
                objectFit: "cover",
                borderRadius: 5,
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = Images.noImageProduct;
              }}
            />
            <Typography
              variant={"body2"}
              color={"#000"}
              sx={{ ...Truncate, WebkitLineClamp: 2 }}
            >
              {param.item.name_id}
            </Typography>
            <Stack direction={"row"} gap={1}>
              <Stack direction={"row"} gap={0.5}>
                <Icon fontSize="small" sx={{ color: "#e5e437" }}>
                  star
                </Icon>
                <small style={{ color: "#999" }}>4.8</small>
              </Stack>
              <Divider orientation="vertical" variant="fullWidth" flexItem />
              <small style={{ color: Colors.primary }}>
                {"(1.200 Terjual)"}
              </small>
            </Stack>
            {/* <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <div style={{ ...CENTER, padding: 5, backgroundColor: Colors.primary, borderRadius: 5 }}>
                            <small style={{ color: '#fff' }}>50% off</small>
                        </div>
                        <small style={{ color: '#999', textDecoration: 'line-through' }}>Rp50.000</small>
                    </Stack> */}
            <Typography variant={"h6"} color={Colors.primary}>
              {param.item.display_price}
            </Typography>
          </Stack>
        </div>
      </div>
    </a>
  );
};

export default DetailProductCard;
