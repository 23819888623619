import { Accordion, AccordionSummary, Box, Button, CircularProgress, Dialog, DialogContent, Icon, IconButton, Rating, Slide, Stack, TextField, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { CENTER, Colors, Truncate } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { Images } from '../../assets/images';
import { FormatCurrency } from '../../utils/utils';
import { useDropzone } from 'react-dropzone';
import { HTTPUploadFile } from '../../apis/uploadFile';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { HTTPAddReview } from '../../apis/review';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const OrderReviewModal = (param: { isOpen: boolean, onClose: any, item: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [ReviewsValue, setReviewsValue] = React.useState<any[]>([])
    const [isVideoLoading, setVideoLoading] = React.useState(false)
    const [isReviewLoading, setReviewLoading] = React.useState<any>(null)

    const onClose = () => {
        setReviewsValue([])
        param.onClose()
    }

    const DeleteImage = (value: any, id: string) => {
        const newValues = ReviewsValue.map((item: any) => {
            const result = item.attachments.filter((item: any) => item.attachment !== value)
            if (item.product_id === id) {
                return ({
                    ...item,
                    attachments: result
                })
            } else {
                return item
            }
        })
        setReviewsValue(newValues)
    }

    const AddImages = async (file: any, id: string) => {
        if (file.length !== 0) {
            try {
                const Forms: any = new FormData()
                Forms.append('file', file)
                Forms.append('type', 'review_pic')
                const result = await HTTPUploadFile({ form: Forms })
                const newValues = ReviewsValue.map((item: any) => {
                    if (item.product_id === id) {
                        return ({
                            ...item,
                            attachments: [
                                ...item.attachments,
                                {
                                    attachment: result.data.data,
                                    type: 'pic'
                                }
                            ]
                        })
                    } else {
                        return item
                    }
                })
                console.log('img', newValues)
                setReviewsValue(newValues)
            } catch (error) {
                toast.error('Terjadi kesalahan saat mengunggah foto!')
                console.log(error)
            }
        }
    }

    const AddVideo = async (file: any, id: string) => {
        setVideoLoading(true)
        if (file.length !== 0) {
            try {
                const Forms: any = new FormData()
                Forms.append('file', file)
                Forms.append('type', 'review_vid')
                const result = await HTTPUploadFile({ form: Forms })
                const newValues = ReviewsValue.map((item: any) => {
                    if (item.product_id === id) {
                        return ({
                            ...item,
                            attachments: [
                                ...item.attachments,
                                {
                                    attachment: result.data.data,
                                    type: 'vid'
                                }
                            ]
                        })
                    } else {
                        return item
                    }
                })
                console.log(newValues)
                setReviewsValue(newValues)
                setVideoLoading(false)
            } catch (error) {
                setVideoLoading(false)
                toast.error('Terjadi kesalahan saat mengunggah foto! Format yang di terima adalah JPEG / PNG')
                console.log(error)
            }
        }
    }

    const Initial = () => {
        const initData = param.item.details.map((item: any) => {
            return ({
                ...item,
                product_id: item.product.id,
                rating: 0,
                review: '',
                attachments: [],
                isReview: false
            })
        })
        console.log(initData)
        setReviewsValue(initData)
    }

    React.useEffect(() => {
        if (param.isOpen === true) {
            Initial()
        }
    }, [param.isOpen])

    const onInputRatings = (value: any, id: string) => {
        const newValues = ReviewsValue.map((item: any) => {
            if (item.product_id === id) {
                return ({ ...item, rating: value })
            } else {
                return item
            }
        })
        console.log('rating', newValues)
        setReviewsValue(newValues)
    }

    const onInputReview = (value: any, id: string) => {
        const text = value.target.value
        const newValues = ReviewsValue.map((item: any) => {
            if (item.product_id === id) {
                return ({ ...item, review: text })
            } else {
                return item
            }
        })
        console.log('review', newValues)
        setReviewsValue(newValues)
    }

    const onSubmit = async (id: string) => {
        setReviewLoading(id)
        try {
            const CurrentValue: any = ReviewsValue.map((item: any) => {
                if (item.product.id === id) {
                    return {
                        token,
                        order_id: param.item.id,
                        product_id: item.product.id,
                        rating: item.rating,
                        review: item.review,
                        attachments: item.attachments,
                    }
                }
            })
            console.log(CurrentValue[0])
            const result = await HTTPAddReview(CurrentValue[0])
            console.log(result)
            setReviewLoading(null)
            toast.success('Terima Kasih atas masukan yang diberikan!')
        } catch (error) {
            toast.error('Terjadi Kesalahan!')
            setReviewLoading(null)
            console.log(error)
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={isMobile}
            scroll='body'
            onClose={onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: '60vw' }, }}
        >
            <DialogContent>
                <Stack direction={"column"} gap={3} width={'100%'} alignItems={"flex-start"}>
                    <Stack direction={'row'} alignItems={"center"} justifyContent={"space-between"} width={'100%'}>
                        <Button onClick={onClose} variant={"text"} color={"secondary"}>
                            <Stack direction={'row'} alignItems={"center"} gap={1}>
                                <Icon fontSize={'small'} sx={{ color: Colors.disable }}>arrow_back</Icon>
                                <Typography variant={"body1"} fontWeight={'600'} color={Colors.disable}>Kembali</Typography>
                            </Stack>
                        </Button>
                        <Typography variant={isMobile ? 'body1' : "h6"} fontWeight={'600'} color={Colors.primary}>Ulas Produk</Typography>
                    </Stack>
                    <div style={{ height: 1, width: '100%', backgroundColor: '#ddd' }}></div>
                    <Stack direction={"row"} gap={1} alignItems={'center'}>
                        <Typography variant={'body1'}>
                            {moment(param.item.status_log[0].created_at).format('DD/MM/YYYY')} {isMobile ? <span style={{ fontWeight: '600' }}>#{param.item.order_number}</span> : null}
                        </Typography>
                        <Typography variant={'body1'}>No Pesanan: <b>#{param.item.order_number}</b></Typography>
                    </Stack>
                    {
                        ReviewsValue.map((value: any, idx: number) => (
                            <Stack direction={"column"} key={idx} width="100%" gap={2}>
                                <Box width={'100%'} sx={{ border: '1px solid #909090', borderRadius: 3 }}>
                                    <Stack direction={"row"} width={isMobile ? '100%' : '85%'} gap={2} alignItems={'center'} padding={2}>
                                        <img
                                            src={
                                                value.product.galleries.length === 0 ?
                                                    Images.noImageProduct
                                                    : value.product.galleries[0].image_path
                                            }
                                            alt=""
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = Images.noImageProduct;
                                            }}
                                            style={{ width: isMobile ? 70 : 120, aspectRatio: 1, objectFit: 'cover', borderRadius: 10 }}
                                        />
                                        <Stack direction={"column"} gap={1} width={isMobile ? '100%' : '60%'}>
                                            {
                                                isMobile ? null :
                                                    <>
                                                        <Typography variant={'body1'} fontWeight={'600'} sx={{ ...Truncate, WebkitLineClamp: 3 }}>{value.product.name_id}</Typography>
                                                        {
                                                            value.product.variant_prices.length === 0 ? null :
                                                                <Typography variant={'body2'} fontWeight={'300'}>Variasi: {value.product_variant.name_id}</Typography>
                                                        }
                                                        <Typography variant={'body2'} fontWeight={'300'}>{value.quantity} barang x Rp{FormatCurrency(value.price)}</Typography>
                                                    </>
                                            }
                                        </Stack>
                                    </Stack>
                                </Box>
                                <Accordion style={{ borderRadius: 10, border: `1px solid #909090` }}>
                                    <AccordionSummary expandIcon={<Icon sx={{ color: '#000' }} fontSize={'large'}>expand_more</Icon>}>
                                        <Typography variant="h6" color="#000" fontWeight={'600'}>Berikan Ulasan!</Typography>
                                    </AccordionSummary>
                                    <Stack direction={'column'} gap={3} padding={2} alignItems={"flex-start"}>
                                        <Rating size="large" onChange={(e, val) => onInputRatings(val, value.product.id)}></Rating>
                                        <Stack direction={'row'} gap={2} width={'100%'}>
                                            <Typography width={'20%'} variant="body1" color={'#000'}>Foto Produk:</Typography>
                                            <Stack direction={'row'} gap={2} width={'80%'} flexWrap={'wrap'} alignItems={'center'}>
                                                {
                                                    value.attachments.map((item: any, index: number) => (
                                                        item.type === 'pic' ?
                                                            <div style={{ position: 'relative', width: '15%' }} key={index}>
                                                                <img
                                                                    src={item.attachment}
                                                                    style={{
                                                                        aspectRatio: 1,
                                                                        width: '100%',
                                                                        objectFit: 'cover',
                                                                        borderRadius: 10,
                                                                        border: `1px solid ${Colors.primary}`,
                                                                        position: 'relative'
                                                                    }}
                                                                    alt={`review ${index + 1}.png`}
                                                                />
                                                                <IconButton onClick={() => DeleteImage(item.attachment, value.product.id)} sx={{ backgroundColor: '#00000050', position: 'absolute', top: 3, right: 3 }}>
                                                                    <Icon sx={{ color: '#fff' }} fontSize={'small'}>close</Icon>
                                                                </IconButton>
                                                            </div>
                                                            : null
                                                    ))
                                                }
                                                {
                                                    value.attachments.length >= 10 ? null :
                                                        <Box
                                                            component={'label'}
                                                            style={{
                                                                ...CENTER,
                                                                border: `1px solid ${Colors.primary}`,
                                                                width: '15%',
                                                                borderRadius: 10,
                                                                cursor: "pointer",
                                                                position: 'relative',
                                                                aspectRatio: 1
                                                            }}
                                                        >
                                                            <Stack alignItems={'center'} gap={1}>
                                                                <Icon fontSize={'large'} sx={{ color: Colors.primary }}>add_photo_alternate</Icon>
                                                                <Typography variant="body2" color={Colors.primary} whiteSpace={'pre-line'} textAlign={'center'}>{`Tambahkan\nFoto (${value.attachments.length}/9)`}</Typography>
                                                            </Stack>
                                                            <input
                                                                type="file"
                                                                hidden
                                                                accept='image/*'
                                                                onChange={({ target }: any) => AddImages(target.files[0], value.product.id)}
                                                            />
                                                        </Box>
                                                }
                                            </Stack>
                                        </Stack>
                                        <Stack direction={'row'} gap={2} width={'100%'}>
                                            <Typography width={'20%'} variant="body1" color={'#000'}>Video Produk:</Typography>
                                            {
                                                value.attachments.find((item: any) => item.type === 'vid') ?
                                                    <div style={{ width: '80%' }}>
                                                        <video
                                                            src={value.attachments.find((item: any) => item.type === 'vid')?.attachment}
                                                            controls
                                                            style={{ width: '40%', aspectRatio: 4 / 3, objectFit: 'contain' }}
                                                        ></video>
                                                    </div>
                                                    :
                                                    <Stack direction={'row'} gap={2} width={'80%'} alignItems={'center'}>
                                                        <Box
                                                            component={'label'}
                                                            style={{
                                                                ...CENTER,
                                                                border: `1px solid ${Colors.primary}`,
                                                                borderRadius: 10,
                                                                cursor: "pointer",
                                                                position: 'relative',
                                                                aspectRatio: 1,
                                                                width: '15%'
                                                            }}
                                                        >
                                                            <Stack alignItems={'center'} gap={1}>
                                                                {
                                                                    isVideoLoading ?
                                                                        <CircularProgress color="secondary" size={40} />
                                                                        :
                                                                        <Icon fontSize={'large'} sx={{ color: Colors.primary }}>video_call</Icon>
                                                                }
                                                                <Typography variant="body2" color={Colors.primary} whiteSpace={'pre-line'} textAlign={'center'}>{`Tambahkan Video`}</Typography>
                                                            </Stack>
                                                            <input
                                                                type="file"
                                                                hidden
                                                                accept='video/*'
                                                                onChange={({ target }: any) => AddVideo(target.files[0], value.product.id)}
                                                            />
                                                        </Box>
                                                        <ul>
                                                            <li><Typography variant="body2" color={'#000'}>Durasi : 10 - 60 detik</Typography></li>
                                                        </ul>
                                                    </Stack>
                                            }
                                        </Stack>
                                        <Stack direction="column" gap={1} width={'100%'}>
                                            <Typography variant="body1" color={'#000'}>Alasan:</Typography>
                                            <TextField
                                                type="text"
                                                placeholder="Sertakan Alasan Anda"
                                                size="small"
                                                sx={{ bgcolor: "white", width: isMobile ? '100%' : "70%" }}
                                                InputProps={{ sx: { borderRadius: 1 } }}
                                                multiline
                                                rows={5}
                                                name="address"
                                                value={value.review}
                                                onChange={(text) => onInputReview(text, value.product.id)}
                                            />
                                        </Stack>
                                        <Button
                                            color="secondary"
                                            sx={{ backgroundColor: Colors.primary }}
                                            variant={"contained"}
                                            onClick={() => onSubmit(value.product.id)}
                                            disabled={isReviewLoading === value.product.id}
                                        >
                                            {
                                                isReviewLoading === value.product.id ?
                                                    <CircularProgress color={"secondary"} size={20}></CircularProgress>
                                                    :
                                                    'Selesai'
                                            }
                                        </Button>
                                    </Stack>
                                </Accordion>
                            </Stack>
                        ))
                    }
                </Stack>
            </DialogContent>
        </Dialog >
    )
}