import { Button, Divider, Icon, MenuItem, Pagination, Stack, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import Footer from '../../components/footer'
import { Header } from '../../components/header'
import NavigationBar from '../../components/navigationBar'
import { CENTER, Colors, Truncate } from '../../utils/colors'

const NotificationScreen = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <Header title="Notifikasi" />
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={'column'} width={'90%'} paddingY={5} gap={3} sx={{ color: '#999' }} alignItems={'flex-start'}>
                    <Button color='inherit' variant='outlined'>
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                            <Icon fontSize='small' sx={{ color: 'inherit' }}>arrow_back</Icon>
                            <Typography variant={"body1"}>Kembali Ke Beranda</Typography>
                        </Stack>
                    </Button>
                    <Stack direction={'column'} divider={<Divider />} width={'100%'}>
                        {
                            [...Array(5)].map((item, index) => (
                                <MenuItem key={index} sx={{ whiteSpace: 'normal', width: '100%', backgroundColor: '#fff' }}>
                                    <Stack direction={'row'} alignItems={'center'} gap={2} width={'100%'}>
                                        <img
                                            src="https://img.freepik.com/premium-vector/gift-voucher-template-isolated-gray-background-discount-coupon-50-off-promotion-sale_97458-853.jpg"
                                            alt=""
                                            style={{ width: isMobile ? '20%' : '5%', aspectRatio: 1, objectFit: 'cover' }}
                                        />
                                        <Stack direction={"column"} gap={0} width={'70%'}>
                                            <Typography variant="body1" color={Colors.primary} sx={{ ...Truncate, WebkitLineClamp: 2, width: '100%', fontWeight: '600' }}>11-11: Super Skincare Day!</Typography>
                                            <Typography variant="subtitle2" color={Colors.disable} sx={{ ...Truncate, WebkitLineClamp: 3, width: '100%', }}>Klaim Voucher Super Skincare Day Kamu, hanya untuk hari ini, klaim sekarang juga!</Typography>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            ))
                        }
                    </Stack>
                    <div style={{ width: '100%', ...CENTER }}>
                        <Pagination count={5} />
                    </div>
                </Stack>
            </div>
            <Footer />
        </div>
    )
}

export default NotificationScreen;