import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Auth = () => {
    const auth = localStorage.getItem('isLogin')
    return auth;
};

const ProtectedRoutes = () => {
    const isAuth = Auth();
    return isAuth === null || isAuth === 'false' ? <Navigate to="/error" /> : <Outlet />;
};

export default ProtectedRoutes;
