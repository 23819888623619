import { Button, Dialog, DialogContent, Divider, Icon, IconButton, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { CENTER, Colors } from '../../utils/colors';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DeleteModal = (param: { isOpen: boolean, onClose: any, label: any, onDelete: any }) => {
    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '95vw' : '30vw' } }}
        >
            <DialogContent>
                <Stack direction={"column"} gap={5}>
                    <Stack direction={"row"} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={"row"} alignItems={'center'} gap={1}>
                            <Icon fontSize={'medium'} sx={{ color: Colors.error }}>error</Icon>
                            <Typography variant="body2" fontWeight={'600'}>Hapus Data?</Typography>
                        </Stack>
                        <IconButton onClick={param.onClose}><Icon fontSize={'medium'} sx={{ color: Colors.disable }}>close</Icon></IconButton>
                    </Stack>
                    <Stack direction={"column"} alignItems={'center'} gap={1}>
                        <Typography variant="body1" fontWeight={'600'} textAlign={'center'}>Yakin ingin menghapus data {param.label}?</Typography>
                        <Typography variant="body2" fontWeight={'400'} textAlign={'center'} color={'#999'} width={'70%'}>Data yang sudah terhapus tidak dapat di kembalikan</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={'center'} justifyContent={'center'} gap={2}>
                        <Button variant={"outlined"} color={'error'} onClick={param.onClose}>Batal</Button>
                        <Button variant={"contained"} color={'error'} onClick={param.onDelete}>HAPUS</Button>
                    </Stack>
                </Stack>
            </DialogContent >
        </Dialog >
    )
}