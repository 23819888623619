import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const ProductDetailSlice = createSlice({
  name: "Product_Detail",
  initialState: {
    data: { id: "" },
  },
  reducers: {
    setProductDetail(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
    },
  },
});

export const { setProductDetail } = ProductDetailSlice.actions;
export const ProductDetailData = ProductDetailSlice.reducer;
