import { AxiosMethod } from "../utils/interceptors";

export function HTTPGetAbouts(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get('abouts');
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetPrivacyPolicies(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get('privacy_policies');
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}