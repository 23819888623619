import { Stack, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { toast } from 'react-toastify'
import { HTTPGetAbouts } from '../../apis/about'
import Footer from '../../components/footer'
import { Header } from '../../components/header'
import NavigationBar from '../../components/navigationBar'
import { PrivacyNavbar, PrivacySideBar } from '../../components/privacy/sidebar'
import { LoaderList } from '../../components/utils/skeletons'
import { CENTER } from '../../utils/colors'

const PrivacyAboutScreen = () => {
    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(true)
    const [AboutData, setAboutData] = React.useState<any>({})

    const GetAbouts = async () => {
        setLoading(true)
        try {
            const result = await HTTPGetAbouts()
            setAboutData(result.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan Server!')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            await GetAbouts()
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <Header title="Tentang Angelo Skin" />
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={isMobile ? 'column' : 'row'} sx={{ width: '90%', paddingY: 5 }} justifyContent={'space-between'} gap={3}>
                    {
                        isMobile ?
                            <div style={{ width: '100%' }}>
                                <PrivacyNavbar activeMenu={1} />
                            </div>
                            :
                            <div style={{ width: '20%' }}>
                                <PrivacySideBar activeMenu={1} />
                            </div>
                    }
                    <div style={{ width: isMobile ? '100%' : '78%' }}>
                        {
                            isLoading ?
                                <LoaderList />
                                :
                                <Stack direction={"column"} gap={2}>
                                    <Typography variant={'h6'} fontWeight={'600'}>{AboutData.title_id}</Typography>
                                    <div dangerouslySetInnerHTML={{ __html: AboutData.description_id }}></div>
                                </Stack>
                        }
                    </div>
                </Stack>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyAboutScreen;