import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import { Images } from '../../assets/images';
import { isMobile } from 'react-device-detect';
import './style.css'
import { useNavigate } from 'react-router';
import { FormatSlug } from '../../utils/utils';
import { useDispatch } from 'react-redux';
import { setSliderTemp } from '../../stores/data/slider';

const BannerSwiper = (param: { data: any }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onNavigate = (value: any) => {
        dispatch(setSliderTemp({ data: { id: value.id, type: 'home' } }))
        navigate(`/slider/${FormatSlug(value.title_id)}`)
    }

    return (
        <div style={{ width: '100%' }}>
            <Swiper
                pagination={{
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index: number, className: string) {
                        return '<div class="bg-bullet"><span class="' + className + '"></span></div';
                    },
                }}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: true
                }}
                loop={true}
                slidesPerView={1}
                modules={[Pagination, Autoplay]}
                style={{ width: '100%', height: isMobile ? '25vh' : '50vh', borderRadius: 20 }}
            >
                {
                    param.data.map((item: any, index: number) => (
                        <SwiperSlide
                            key={index}
                            onClick={() => onNavigate(item)}
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                cursor: 'pointer',
                                textAlign: 'left'
                            }}>
                            <img
                                src={item.thumbnail}
                                style={{
                                    height: '100%',
                                    width: "100%",
                                    objectFit: "cover",
                                    position: 'relative'
                                }}
                                alt={index.toString()}
                            ></img>
                        </SwiperSlide>
                    ))
                }
                <div className="swiper-pagination"></div>
            </Swiper>
        </div >
    )
}

export default BannerSwiper;