import { AxiosMethod } from "../utils/interceptors";
const url = "products/reviews";

export function HTTPGetReviews(param: { product_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/get`, {
        product_id: param.product_id,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetReviewsByID(param: { review_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(
        `${url}/show/${param.review_id}`
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAddReview(param: {
  token: string;
  product_id: string;
  order_id: string;
  rating: number;
  review: string;
  attachments: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}`, {
        token: param.token,
        product_id: param.product_id,
        order_id: param.order_id,
        rating: param.rating,
        review: param.review,
        attachments: param.attachments,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
