import { Button, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import secureLocalStorage from 'react-secure-storage'
import SwipeableViews from 'react-swipeable-views'
import { toast } from 'react-toastify'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { HTTPAddWishList, HTTPDeleteWishList, HTTPDetailWishList } from '../../../apis/wishlist'
import { Images } from '../../../assets/images'
import { Colors } from '../../../utils/colors'

export const ProductSwiper = (param: { data: any[], product_id: string, wishlistId: any, getData: any }) => {
    const navigate = useNavigate()
    const token = secureLocalStorage.getItem('token') as string
    const [activeStep, setActiveStep] = React.useState(0);

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const onWishList = async () => {
        const isLogin = localStorage.getItem('isLogin')
        if (isLogin === null || isLogin === undefined || isLogin === 'false') {
            navigate('/auth/login')
        } else {
            if (param.wishlistId) {
                try {
                    await HTTPDeleteWishList({ id: param.wishlistId, token })
                    param.getData()
                } catch (error) {
                    console.log(error)
                    toast.error('Terjadi Kesalahan!')
                }
            } else {
                try {
                    await HTTPAddWishList({ token, product_id: param.product_id })
                    param.getData()
                } catch (error) {
                    toast.error('Terjadi Kesalahan ketika menambahkan Wishlist!')
                    console.log(error)
                }
            }
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <SwipeableViews
                enableMouseEvents
                index={activeStep}
                onChangeIndex={handleStepChange}
            >
                {
                    param.data.map((item, index) => (
                        <div key={index}>
                            {
                                item.type === 'vid' ?
                                    <video
                                        src={item.image_path}
                                        style={{ width: '100%', aspectRatio: 1, objectFit: 'cover' }}
                                        controls
                                        loop
                                        autoPlay
                                        muted
                                    ></video>
                                    :
                                    <img
                                        alt=""
                                        src={item.image_path}
                                        style={{ width: '100%', aspectRatio: 1, objectFit: 'cover', userSelect: 'none' }}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = Images.noImageProduct;
                                        }}
                                    />
                            }
                        </div>
                    ))
                }
            </SwipeableViews>
            <Swiper
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode]}
                className={"mySwiper"}
            >
                {
                    param.data.map((item, index) => (
                        <SwiperSlide key={index} onClick={() => setActiveStep(index)} style={{ opacity: activeStep === index ? 1 : 0.4 }}>
                            {
                                item.type === 'vid' ?
                                    <video
                                        src={item.image_path}
                                        style={{ width: '100%', aspectRatio: 1, objectFit: 'cover' }}
                                        muted
                                    ></video>
                                    :
                                    <img
                                        alt=""
                                        src={item.image_path}
                                        style={{ aspectRatio: 1, width: '100%', userSelect: 'none' }}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = Images.noImageProduct;
                                        }}
                                    />
                            }
                        </SwiperSlide>
                    ))

                }
            </Swiper>
            <Stack direction={'row'} alignItems={'center'} gap={2} sx={{ marginTop: 2, color: Colors.primary, width: '100%' }} justifyContent={'space-between'}>
                <Button color={'secondary'} variant={param.wishlistId !== null ? 'contained' : 'text'} onClick={onWishList} sx={{ backgroundColor: param.wishlistId !== null ? Colors.primary : '#fff' }}>
                    <Stack direction={"row"} gap={1} padding={1}>
                        <Icon fontSize='small' sx={{ color: 'inherit' }}>{param.wishlistId !== null ? 'favorite' : 'favorite_border'}</Icon>
                        <Typography variant="caption" color={"inherit"}>Favoritkan</Typography>
                    </Stack>
                </Button>
                <Button color={'inherit'} variant={'outlined'}>
                    <Stack direction={"row"} gap={1} padding={1}>
                        <Icon fontSize='small' sx={{ color: 'inherit' }}>share</Icon>
                        <Typography variant="caption" color={"inherit"}>Bagikan Produk</Typography>
                    </Stack>
                </Button>
            </Stack>
        </div>
    )
}