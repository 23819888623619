import { Stack } from '@mui/material';
import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { isMobile } from 'react-device-detect';
import { FreeMode } from 'swiper/modules';

const CategoryList = (param: { data: any }) => {
    return (
        <div style={{ border: '2px solid #90909050', borderRadius: 10 }}>
            <Stack direction={"column"} gap={3} sx={{ padding: 3 }}>
                <h3 style={{ margin: 0, color: '#000', width: '100%', textAlign: 'center' }}>Kategori Pilihan</h3>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={isMobile ? 3.4 : 6.5}
                    modules={[FreeMode]}
                    freeMode
                    style={{ width: '100%', padding: '10px 0' }}
                >
                    {
                        param.data.map((item: any, index: number) => (
                            <SwiperSlide
                                key={index}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <Stack
                                    direction={'column'}
                                    key={item}
                                    gap={1}
                                    alignItems={'center'}
                                    sx={{
                                        transform: 'scale(1)',
                                        transition: 'all .2s',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                            transition: 'all .2s',
                                        }
                                    }}
                                >
                                    <img
                                        src={item.image}
                                        alt=""
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            aspectRatio: 1,
                                            objectFit: 'contain'
                                        }}
                                    />
                                    <span style={{ color: '#000' }}>{item.name}</span>
                                </Stack>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </Stack>
        </div>
    )
}

export default CategoryList;