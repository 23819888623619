import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const CartSlice = createSlice({
  name: "Cart_Data",
  initialState: {
    data: [] as any,
  },
  reducers: {
    setCartData(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
    },
  },
});

export const { setCartData } = CartSlice.actions;
export const CartData = CartSlice.reducer;
