import { Box, Grid, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Colors } from '../../../utils/colors'
import DetailProductCard from './productCard'

export const RelevantProducts = (param: { data: any[] }) => {
    return (
        <Stack direction={'column'} gap={2} padding={2}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"}>
                <Typography variant="h5" fontWeight={'600'}>Produk Terkait</Typography>
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Typography variant="body1" color={Colors.primary}>Lihat Semua</Typography>
                    <Icon fontSize={'medium'} sx={{ color: Colors.primary }}>chevron_right</Icon>
                </Stack>
            </Stack>
            {
                isMobile ?
                    <Swiper
                        slidesPerView={isMobile ? 2.2 : 5.8}
                        modules={[FreeMode]}
                        freeMode
                        spaceBetween={5}
                        style={{ width: '100%', padding: '10px 0' }}
                    >
                        {
                            param.data.map((item: any, index: number) => (
                                <SwiperSlide
                                    key={index}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <DetailProductCard item={item} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                    :
                    <Grid container spacing={2} sx={{ width: '100%' }}>
                        {
                            param.data.map((item: any, index: number) => (
                                <Grid item md={3} key={index}>
                                    <Box sx={{ border: '1px solid #999', borderRadius: 1 }}>
                                        <DetailProductCard item={item} />
                                    </Box>
                                </Grid>
                            ))
                        }
                    </Grid>
            }
        </Stack>
    )
}