import * as React from 'react';
import './styles.css'
import { CENTER, Colors } from '../../utils/colors';
import { Button, Icon, Stack, Typography } from '@mui/material'
import moment from 'moment';
import LoginSwiper from '../../components/login/loginSwiper';
import { ForgotPasswordSection, LoginSection, RegisterSection } from '../../components/login/loginSection';
import { Images } from '../../assets/images';
import { useNavigate, useParams } from 'react-router';
import { isMobile } from 'react-device-detect';
import { HTTPGetSlidersAuth } from '../../apis/slider';

const LoginScreen = () => {
    const navigate = useNavigate()
    const { type } = useParams()
    const [SectionActive, setSectionActive] = React.useState(type)
    const [isActive, setActive] = React.useState(type === 'register' ? 1 : 0)
    const [Sliders, setSliders] = React.useState([])
    const [init, setInit] = React.useState(false)

    const GetSliders = async () => {
        try {
            const result = await HTTPGetSlidersAuth()
            setSliders(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        async function APIList() {
            try {
                await GetSliders()
            } catch (error) {
                console.log(error)
            }
        }
        APIList().then().catch()
    }, [init])

    return (
        <div style={{ ...CENTER, height: '100vh', width: '100%', overflow: isMobile ? 'auto' : 'none', backgroundColor: '#fff' }}>
            <Stack
                direction={'column'}
                height={"100%"}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
                gap={isMobile ? 3 : 0}
            >
                <div style={{ ...CENTER, width: '100%' }}>
                    {
                        isMobile ?
                            <img src={Images.logo} style={{ height: '8vh', width: 'auto', objectFit: 'contain', paddingTop: '1%' }} alt="" />
                            :
                            <Stack direction={"row"} alignItems={"flex-end"} justifyContent={"space-between"} width={'90%'}>
                                <Button onClick={() => navigate('/')} variant={"outlined"} color={"secondary"} sx={{ borderColor: Colors.disable }}>
                                    <Stack direction={'row'} alignItems={"center"} gap={1}>
                                        <Icon fontSize={'small'} sx={{ color: Colors.disable }}>arrow_back</Icon>
                                        <Typography variant={"body1"} fontWeight={'600'} color={Colors.disable}>Kembali</Typography>
                                    </Stack>
                                </Button>
                                <img src={Images.logo} style={{ height: '8vh', width: 'auto', objectFit: 'contain', paddingTop: '1%' }} alt="" />
                                <div style={{ width: 80 }}></div>
                            </Stack>
                    }
                </div>
                <Stack direction={isMobile ? 'column-reverse' : 'row'} width={isMobile ? '90%' : '80%'} alignItems={'center'} justifyContent={'space-between'} gap={isMobile ? 3 : 0}>
                    <div style={{ width: isMobile ? '100%' : '58%' }}>
                        <LoginSwiper data={Sliders} />
                    </div>
                    <div
                        style={{
                            width: isMobile ? '100%' : '35%',
                            borderRadius: 20,
                            backgroundColor: '#fff',
                            boxShadow: '0px 0px 20px 8px rgba(0,0,0,0.1)'
                        }}
                    >
                        {
                            SectionActive === 'login' ?
                                <div className={`${isActive === 0 ? 'fadeIn' : 'fadeOut'}`}>
                                    <LoginSection setSection={(v: string) => {
                                        setActive(v === 'register' ? 1 : 2)
                                        setTimeout(() => {
                                            setSectionActive(v)
                                        }, 200)
                                    }}></LoginSection>
                                </div>
                                : SectionActive === 'register' ?
                                    <div className={`${isActive === 1 ? 'fadeIn' : 'fadeOut'}`}>
                                        <RegisterSection setSection={(v: string) => {
                                            setActive(0)
                                            setTimeout(() => {
                                                setSectionActive(v)
                                            }, 200)
                                        }}></RegisterSection>
                                    </div>
                                    :
                                    <div className={`${isActive === 2 ? 'fadeIn' : 'fadeOut'}`}>
                                        <ForgotPasswordSection setSection={(v: string) => {
                                            setActive(0)
                                            setTimeout(() => {
                                                setSectionActive(v)
                                            }, 200)
                                        }}></ForgotPasswordSection>
                                    </div>
                        }
                    </div>
                </Stack>
                <span style={{ color: '#000', paddingBottom: '1%' }}>{'©️ ' + moment().format('YYYY') + ' Angelo Skin.'}</span>
            </Stack>
        </div>
    )
}

export default LoginScreen;