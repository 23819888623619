import { AxiosMethod } from "../utils/interceptors";
const urlAuth = "sliders/auth";
const urlHome = "sliders/home";

export function HTTPGetSlidersAuth(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${urlAuth}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetSliderAuthById(param: { id_banner: number }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${urlAuth}/detail`, {
        params: {
            id: param.id_banner
        }
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetSlidersHome(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${urlHome}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetSliderHomeById(param: { id_banner: number }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(`${urlHome}/detail`, {
        params: {
            id: param.id_banner
        }
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
