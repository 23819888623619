import { AxiosMethod } from "../utils/interceptors";
const url = "orders";

export function HTTPGetOrders(param: { token: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/history`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetOrderDetail(param: {
  token: string;
  order_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/detail`, {
        token: param.token,
        order_id: param.order_id,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPCancelOrder(param: {
  token: string;
  order_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/cancel`, {
        token: param.token,
        order_id: param.order_id,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPReturnOrder(param: {
  token: string;
  order_id: string;
  return_reason: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post(`${url}/return`, {
        token: param.token,
        order_id: param.order_id,
        return_reason: param.return_reason,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
