import { Button, Stack, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router';
import { Icons } from '../../assets/icons';
import { Images } from '../../assets/images';
import Footer from '../../components/footer';
import NavigationBar from '../../components/navigationBar';
import { CENTER, Colors } from '../../utils/colors';

const Page404 = () => {
    const navigate = useNavigate()
    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <div style={{ width: '100%', height: '80vh', ...CENTER }}>
                <Stack direction="column" gap={3} sx={{ color: Colors.disable }} alignItems={'center'}>
                    <img src={Images.logo} style={{ height: 80, width: 'auto', objectFit: 'contain' }} alt="" />
                    <Typography variant={'h5'} textAlign={"center"} fontWeight={'600'} color={'#000'}>404 | Not Found</Typography>
                    <Typography textAlign={'center'} variant={'body1'} width={"70%"} fontWeight={'400'} color={Colors.disable}>Maaf, halaman yang kamu tuju tidak tersedia atau sudah dipindahkan</Typography>
                    <Button
                        color={"inherit"}
                        sx={{ marginTop: 3 }}
                        variant={"outlined"}
                        onClick={() => navigate('/')}
                    >Kembali ke Beranda</Button>
                </Stack>
            </div>
            <Footer />
        </div>
    )
}

export default Page404;