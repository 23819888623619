import {
  Button,
  Divider,
  Drawer,
  Grid,
  Icon,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { HTTPGetArticleCategories, HTTPGetArticles } from "../../apis/article";
import { ArticleSideBar } from "../../components/article/sideBar";
import Footer from "../../components/footer";
import { Header } from "../../components/header";
import NavigationBar from "../../components/navigationBar";
import { LoaderCard } from "../../components/utils/skeletons";
import { CENTER, Colors, Truncate } from "../../utils/colors";

const ArticleListScreen = () => {
  const navigate = useNavigate();
  const [onFilter, setFilter] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [ArticleData, setArticleData] = React.useState([]);
  const [CategoryData, setCategoryData] = React.useState([]);
  const [init, setInit] = React.useState(false);
  const [LatestSort, setLatestSort] = React.useState<any>({});

  const GetArticles = async (param: {
    category_id?: string;
    keywords?: string;
    sort_by?: string;
  }) => {
    setLoading(true);
    try {
      const result = await HTTPGetArticles({ ...LatestSort, ...param });
      setArticleData(result.data.data);
      setLatestSort(result.data.request);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Terjadi Kesalahan");
      console.error(error);
    }
  };

  const SearchArticles = async (param: { keywords?: any }) => {
    if (param.keywords.length > 0) {
      try {
        const result = await HTTPGetArticles({ ...LatestSort, ...param });
        setArticleData(result.data.data);
        setLatestSort(result.data.request);
      } catch (error) {
        toast.error("Terjadi Kesalahan");
        console.error(error);
      }
    }
  };

  const GetArticleCategories = async () => {
    setLoading(true);
    try {
      const result = await HTTPGetArticleCategories();
      const newValue = result.data.data.map((item: any, index: number) => ({
        id: item.id,
        node: (index + 2).toString(),
        label: item.name_id,
        qty: item.articles,
      }));
      setCategoryData(newValue);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Terjadi Kesalahan");
      console.error(error);
    }
  };

  React.useEffect(() => {
    async function Initial() {
      await GetArticles({});
      await GetArticleCategories();
    }

    Initial().then().catch();
  }, [init]);

  return (
    <div style={{ width: "100%", backgroundColor: "#fff" }}>
      {isMobile ? null : <NavigationBar />}
      <Header title="Daftar Artikel" />
      <div style={{ width: "100%", ...CENTER, marginBottom: "5%" }}>
        <Stack
          direction={isMobile ? "column" : "row"}
          sx={{ width: "90%", paddingY: 5 }}
          justifyContent={"space-between"}
          gap={3}
        >
          {isMobile ? (
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <TextField
                type="text"
                placeholder="Cari Article"
                size="small"
                sx={{ bgcolor: "white", width: "60%" }}
                InputProps={{
                  sx: { borderRadius: 3 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon fontSize="small" sx={{ color: "#999" }}>
                        search
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
              <div
                style={{
                  backgroundColor: "#fff",
                  ...CENTER,
                  borderRadius: 5,
                  width: "40%",
                }}
              >
                <Stack
                  onClick={() => setFilter(true)}
                  direction={"row"}
                  sx={{ cursor: "pointer", padding: "5px 0", width: "100%" }}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  gap={1}
                >
                  <Typography variant={"body1"} color={Colors.disable}>
                    Urutkan
                  </Typography>
                  <Icon fontSize={"small"} sx={{ color: Colors.disable }}>
                    filter_list
                  </Icon>
                </Stack>
              </div>
            </Stack>
          ) : (
            <div style={{ width: "20%" }}>
              <ArticleSideBar
                data={CategoryData}
                getData={(action: any) => GetArticles(action)}
                searchData={(text: any) => SearchArticles(text)}
              />
            </div>
          )}
          <div
            style={{
              width: isMobile ? "100%" : "78%",
              backgroundColor: "#fff",
            }}
          >
            <Stack direction={"column"} gap={3} paddingX={isMobile ? 0 : 3}>
              {isLoading ? (
                <LoaderCard />
              ) : (
                <>
                  {ArticleData.length === 0 ? (
                    <Stack
                      direction={"column"}
                      gap={1}
                      alignItems={"center"}
                      justifyContent={"center"}
                      width={"100%"}
                      height={"100%"}
                    >
                      <Typography
                        variant={"h6"}
                        fontWeight={"600"}
                        color={Colors.disable}
                        textAlign={"center"}
                      >
                        ARTIKEL TIDAK TERSEDIA
                      </Typography>
                    </Stack>
                  ) : (
                    <Grid container spacing={isMobile ? 1 : 2}>
                      {ArticleData.map((item: any, index: number) => (
                        <Grid item md={4} xs={6} key={index}>
                          <a
                            href={`/article/detail/${item.slug}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Stack
                              // onClick={() => navigate(`/article/detail/${item.slug}`)}
                              direction={"column"}
                              gap={1}
                              sx={{
                                borderRadius: 1,
                                padding: 2,
                                border: "1px solid #999",
                                cursor: "pointer",
                                transition: "all .3s",
                                "&:hover": {
                                  border: `1px solid ${Colors.primary}`,
                                  transition: "all .3s",
                                  boxShadow: `0px 0px 10px 3px ${
                                    Colors.primary + 50
                                  }`,
                                },
                              }}
                            >
                              <img
                                src={item.thumbnail}
                                alt=""
                                style={{
                                  width: "100%",
                                  aspectRatio: 1,
                                  objectFit: "cover",
                                }}
                              />
                              <Typography variant="caption" color={"#999"}>
                                {item.last_updated}
                              </Typography>
                              <Typography
                                variant="body1"
                                fontWeight={"600"}
                                sx={{
                                  WebkitLineClamp: 3,
                                  width: "100%",
                                  ...Truncate,
                                }}
                              >
                                {item.title_id}
                              </Typography>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.content_id,
                                }}
                                style={{
                                  WebkitLineClamp: 3,
                                  width: "100%",
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              ></div>
                              <Button
                                onClick={() =>
                                  navigate(`/article/detail/${item.slug}`)
                                }
                                color={"secondary"}
                                variant={"outlined"}
                                sx={{
                                  borderColor: Colors.primary,
                                  color: Colors.primary,
                                  marginTop: 2,
                                }}
                              >
                                {isMobile
                                  ? "Selengkapnya"
                                  : "Baca Selengkapnya"}
                              </Button>
                            </Stack>
                          </a>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </>
              )}
            </Stack>
          </div>
        </Stack>
      </div>
      <Footer />
      <Drawer
        anchor="right"
        open={onFilter}
        onClose={() => setFilter(false)}
        PaperProps={{ sx: { width: "80vw", padding: 2 } }}
      >
        <Stack
          direction={"column"}
          gap={2}
          divider={<Divider />}
          width={"100%"}
        >
          <ArticleSideBar
            data={CategoryData}
            getData={(action: any) => GetArticles(action)}
            searchData={(text: any) => SearchArticles(text)}
          />
          <Button
            onClick={() => setFilter(false)}
            color="secondary"
            variant={"contained"}
            sx={{ backgroundColor: Colors.primary }}
          >
            Terapkan
          </Button>
        </Stack>
        <Toolbar></Toolbar>
      </Drawer>
    </div>
  );
};

export default ArticleListScreen;
