import React, { useState } from "react";
import Helmet from "react-helmet";

const Page = (props: any) => {
  const [pageTitle, setPageTitle] = useState("");
  React.useEffect(() => {
    setPageTitle(props.title || "");
  }, [props.title]);

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Website Resmi Angeloskin - Dapatkan berbagai jenis produk kecantikan dan skincare di sini!"
        />
      </Helmet>
      {props.children}
    </div>
  );
  // return props.children;
};

export default Page;
