import { Button, Divider, Stack, Tab, Tabs, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { isMobile } from 'react-device-detect'
import secureLocalStorage from 'react-secure-storage'
import { HTTPGetOrders } from '../../apis/orders'
import { Images } from '../../assets/images'
import Footer from '../../components/footer'
import { Header } from '../../components/header'
import NavigationBar from '../../components/navigationBar'
import { OrderHistoryModal } from '../../components/profile/historySection'
import { OrderReviewModal } from '../../components/profile/reviewSection'
import { ProfileNavbar, ProfileSideBar } from '../../components/profile/sidebar'
import { LoaderList } from '../../components/utils/skeletons'
import { CENTER, Colors, Truncate } from '../../utils/colors'
import { FormatCurrency } from '../../utils/utils'

const status = [
    { status: 'DRAFT', color: '#0068ff', label: 'Menunggu Pembayaran' },
    { status: 'PENDING', color: '#0068ff', label: 'Menunggu Pembayaran' },
    { status: 'WAITING_DELIVERY', color: '#6a6a6a', label: 'Produk Sedang Dikemas' },
    { status: 'DONE', color: '#24bb33', label: 'Produk Telah Diterima' },
    { status: 'DELIVERING', color: '#f49918', label: 'Produk Sedang Dikirim' },
    { status: 'DELIVERED', color: '#cb42d4', label: 'Pemeriksaan Resi' },
    { status: 'EXPIRED', color: '#000000', label: 'Pembayaran Kedaluwarsa' },
    { status: 'CANCELED', color: '#bb2023', label: 'Pembayaran Dibatalkan' },
    { status: 'RETURN_REQUESTED', color: '#8e54a0', label: 'Pengajuan Pengembalian' },
    { status: 'RETURN_REJECTED', color: '#8e54a0', label: 'Pengajuan Pengembalian' },
    { status: 'RETURN_APPROVED', color: '#8e54a0', label: 'Pengajuan Pengembalian' },
]

const ProfileOrderHistoryScreen = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [isDetail, setDetail] = React.useState(false)
    const [isReview, setReview] = React.useState(false)
    const [init, setInit] = React.useState(false)
    const [menuActive, setMenuActive] = React.useState(0)
    const [SelectedItem, setSelectedItem] = React.useState<any>({})
    const [OrderData, setOrderData] = React.useState([])
    const [isLoading, setLoading] = React.useState(true)

    const GetOrderHistory = async () => {
        setLoading(true)
        try {
            const result = await HTTPGetOrders({ token })
            setOrderData(result.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    React.useEffect(() => {
        async function Initial() {
            await GetOrderHistory()
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar />
            <Header title="Profil Saya" />
            {
                isMobile ?
                    <div style={{ width: '100%' }}>
                        <ProfileNavbar activeMenu={3} />
                    </div>
                    : null
            }
            <div style={{ width: '100%', ...CENTER, marginBottom: '5%' }}>
                <Stack direction={'row'} sx={{ width: '90%', paddingY: 5 }} justifyContent={'space-between'}>
                    {
                        isMobile ? null :
                            <div style={{ width: '20%' }}>
                                <ProfileSideBar activeMenu={3} />
                            </div>
                    }
                    <div
                        style={{
                            width: isMobile ? '100%' : '78%',
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            border: isMobile ? undefined : '1px solid #999',
                            boxShadow: isMobile ? undefined : '0px 0px 10px 8px rgba(0,0,0,0.1)'
                        }}
                    >
                        <Stack direction={'column'} gap={3} padding={isMobile ? 0 : 3}>
                            <Typography variant="h5" fontWeight={'600'}>Riwayat Pesanan</Typography>
                            <Divider />
                            <Tabs
                                textColor={'secondary'}
                                indicatorColor={'secondary'}
                                value={menuActive}
                                variant={'scrollable'}
                                onChange={(e, value) => setMenuActive(value)}
                            >
                                <Tab label="Semua" />
                                <Tab label="Belum Bayar" />
                                <Tab label="Sedang Dikemas" />
                                <Tab label="Dikirim" />
                                <Tab label="Selesai" />
                                <Tab label="Dibatalkan" />
                                <Tab label="Refund" />
                            </Tabs>
                            {isLoading ?
                                <LoaderList></LoaderList>
                                :
                                <>
                                    {
                                        OrderData.length === 0 ?
                                            <Stack direction={'column'} gap={1} alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100%'}>
                                                <Typography variant={'h6'} fontWeight={'600'} color={Colors.disable} textAlign={'center'}>TIDAK ADA DATA</Typography>
                                                <Typography variant={'body1'} color={Colors.disable} textAlign={'center'} whiteSpace={'pre-line'}>Silahkan Buat Pesanan {'\n'} terlebih dahulu.</Typography>
                                            </Stack>
                                            :
                                            <>
                                                {
                                                    OrderData.map((item: any, index: number) => (
                                                        <Stack
                                                            key={index}
                                                            gap={2}
                                                            direction={'column'}
                                                            alignItems={'flex-start'}
                                                            sx={{
                                                                borderRadius: 1,
                                                                padding: 2,
                                                                border: '1px solid #999',
                                                                transition: 'all .3s',
                                                                '&:hover': {
                                                                    border: `1px solid ${Colors.primary}`,
                                                                    transition: 'all .3s',
                                                                    boxShadow: `0px 0px 10px 3px ${Colors.primary + 50}`
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                item.details.length === 1 ? null :
                                                                    <Stack direction={"row"} gap={1} alignItems={'center'}>
                                                                        <Typography variant={'body1'}>
                                                                            {moment(item.status_log[0].created_at).format('DD/MM/YYYY')} {isMobile ? <span style={{ fontWeight: '600' }}>#{item.order_number}</span> : null}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant={'body2'}
                                                                            sx={{
                                                                                padding: 0.5,
                                                                                backgroundColor: status.find((val) => val.status === item.status)?.color + '50',
                                                                                color: status.find((val) => val.status === item.status)?.color,
                                                                                borderRadius: 1,
                                                                                textTransform: 'capitalize'
                                                                            }}
                                                                        >
                                                                            {status.find((val) => val.status === item.status)?.label}
                                                                        </Typography>
                                                                        <Typography variant={'body1'}>#{item.order_number}</Typography>
                                                                    </Stack>
                                                            }
                                                            {
                                                                item.details.map((value: any, idx: number) => (
                                                                    <Stack key={idx} direction={"row"} alignItems="center" justifyContent={'space-between'} width={'100%'}>
                                                                        <Stack direction={"row"} width={isMobile ? '100%' : '85%'} gap={2} alignItems={'center'}>
                                                                            <img
                                                                                src={
                                                                                    value.product.galleries.length === 0 ?
                                                                                        Images.noImageProduct
                                                                                        : value.product.galleries[0].image_path
                                                                                }
                                                                                alt=""
                                                                                onError={({ currentTarget }) => {
                                                                                    currentTarget.onerror = null;
                                                                                    currentTarget.src = Images.noImageProduct;
                                                                                }}
                                                                                style={{ width: isMobile ? 70 : 120, aspectRatio: 1, objectFit: 'cover', borderRadius: 5 }}
                                                                            />
                                                                            <Stack direction={"column"} gap={1} width={isMobile ? '100%' : '60%'}>
                                                                                {
                                                                                    item.details.length === 1 ?
                                                                                        <Stack direction={isMobile ? 'column' : "row"} gap={1} alignItems={isMobile ? 'flex-start' : 'center'}>
                                                                                            <Typography variant={'body2'}>
                                                                                                {moment(item.status_log[0].created_at).format('DD/MM/YYYY')} {isMobile ? <span style={{ fontWeight: '600' }}>#{item.order_number}</span> : null}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                variant={'body2'}
                                                                                                sx={{
                                                                                                    padding: 0.5,
                                                                                                    backgroundColor: status.find((val) => val.status === item.status)?.color + '50',
                                                                                                    color: status.find((val) => val.status === item.status)?.color,
                                                                                                    borderRadius: 1,
                                                                                                    textTransform: 'capitalize'
                                                                                                }}
                                                                                            >
                                                                                                {status.find((val) => val.status === item.status)?.label}
                                                                                            </Typography>
                                                                                            {
                                                                                                isMobile ? null :
                                                                                                    <Typography variant={'body2'}>#{item.order_number}</Typography>
                                                                                            }
                                                                                        </Stack>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    isMobile ? null :
                                                                                        <>
                                                                                            <Typography variant={'body1'} fontWeight={'600'} sx={{ ...Truncate, WebkitLineClamp: 3 }}>{value.product.name_id}</Typography>
                                                                                            {
                                                                                                value.product.variant_prices.length === 0 ? null :
                                                                                                    <Typography variant={'body2'} fontWeight={'300'}>Variasi: {value.product_variant.name_id}</Typography>
                                                                                            }
                                                                                            <Typography variant={'body2'} fontWeight={'300'}>{value.quantity} barang x Rp{FormatCurrency(value.price)}</Typography>
                                                                                        </>
                                                                                }
                                                                            </Stack>
                                                                            {
                                                                                isMobile ? null :
                                                                                    <Stack direction={"column"} gap={1} width={"30%"}>
                                                                                        <Typography variant={'body1'} fontWeight={'300'}>Total Harga</Typography>
                                                                                        <Typography variant={'body1'} fontWeight={'600'}>Rp{FormatCurrency(value.total_price)}</Typography>
                                                                                    </Stack>
                                                                            }
                                                                        </Stack>
                                                                        {
                                                                            isMobile ?
                                                                                <Stack direction={"column"} gap={2}>
                                                                                    <Typography variant={'body2'} fontWeight={'600'} sx={{ ...Truncate, WebkitLineClamp: 3 }}>{value.product.name_id}</Typography>
                                                                                    <Typography variant={'body2'} fontWeight={'300'}>{value.quantity} barang x Rp{FormatCurrency(value.price)}</Typography>
                                                                                </Stack>
                                                                                : null
                                                                        }
                                                                        <Button
                                                                            variant={isMobile ? 'contained' : 'text'}
                                                                            color={'secondary'}
                                                                            sx={{
                                                                                width: isMobile ? '100%' : '10%',
                                                                                backgroundColor: isMobile ? Colors.primary : undefined
                                                                            }}
                                                                        >Beli Lagi</Button>
                                                                    </Stack>
                                                                ))
                                                            }
                                                            <Stack direction={'column'} gap={2} alignItems={'flex-end'} justifyContent={'flex-end'} width={'100%'}>
                                                                <Stack direction={'row'} gap={3} alignItems={'center'}>
                                                                    <Typography variant="h6" color={Colors.primary}><b>Total</b></Typography>
                                                                    <Typography variant="h6" color={Colors.primary}><b>{item.total_text}</b></Typography>
                                                                </Stack>
                                                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                                                    <Button variant={'outlined'} size={'medium'} onClick={() => { setSelectedItem(item); setDetail(true) }} color={'secondary'}>Lihat Detail</Button>
                                                                    <Button variant={'outlined'} size={'medium'} color={'success'} onClick={() => { setSelectedItem(item); setReview(true) }}>Ulas Produk</Button>
                                                                    {/* {
                                                                        item.status === 'DONE' ?
                                                                            <Button variant={'outlined'} size={'medium'} color={'success'} onClick={() => { setSelectedItem(item); setReview(true) }}>Ulas Produk</Button>
                                                                            :
                                                                            null
                                                                    } */}
                                                                </Stack>
                                                            </Stack>
                                                        </Stack>
                                                    ))
                                                }
                                            </>
                                    }
                                </>
                            }
                        </Stack>
                    </div>
                </Stack>
            </div>
            <Footer />
            {
                SelectedItem.id === null || SelectedItem.id === undefined ? null :
                    <OrderHistoryModal
                        isOpen={isDetail}
                        onClose={async () => {
                            setDetail(false);
                            await GetOrderHistory()
                        }}
                        item={SelectedItem}
                    />
            }
            {
                SelectedItem.id === null || SelectedItem.id === undefined ? null :
                    <OrderReviewModal
                        isOpen={isReview}
                        onClose={async () => {
                            setReview(false);
                            await GetOrderHistory()
                        }}
                        item={SelectedItem}
                    />
            }
        </div>
    )
}

export default ProfileOrderHistoryScreen;